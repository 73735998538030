import React, { useEffect, useState, useContext } from 'react';
import './Neutral.css';
import { appContext } from '../../App'



const NeutralParams = (props) => {

    //Context
    const [ 
      appContactList, setAppContactList, actualUser, setActualUser, actualName, setActualName, updaterId, setUpdaterId, eredmenyData, setEredmenyData, submitted, setSubmitted, maltoBuza, setMaltoBuza,
      kemenyitoSzarazanyag, setKemenyitoSzarazanyag, kemenyitoHo, setKemenyitoHo, hokezelesHo, setHokezelesHo, hokezelesIdo, setHokezelesIdo,
      folyositasHo, setFolyositasHo, folyositasIdo, setFolyositasIdo, dextHo, setDextHo, dextIdo, setDextIdo,
      kukKemenyitoSzarazanyag, setKukKemenyitoSzarazanyag, kukKemenyitoHo, setKukKemenyitoHo, kukHokezelesHo, setKukHokezelesHo, kukHokezelesIdo, setKukHokezelesIdo,
      kukFolyositasHo, setKukFolyositasHo, kukFolyositasIdo, setKukFolyositasIdo, kukDextHo, setKukDextHo, kukDextIdo, setKukDextIdo,
      neutralKemenyitoSzarazanyag, setNeutralKemenyitoSzarazanyag, neutralKemenyitoHo, setNeutralKemenyitoHo, neutralHokezelesHo, setNeutralHokezelesHo,
      neutralHokezelesIdo, setNeutralHokezelesIdo, neutralFolyositasIdo, setNeutralFolyositasIdo, neutralDextHo, setNeutralDextHo,
      neutralCukrositasHo, setNeutralCukrositasHo,
      results, setResults, params, setParams
     ] = useContext(appContext)
   

    const handleSubmit = (e) => {
      e.preventDefault()
      setSubmitted(true)
      setEredmenyData([
       
        {name: 'neutralKemenyitoSzarazanyag', subScription: 'Csirizesítés szárazanyagtartalom [ % ]', title: 'Keményítő szuszpenzió készítése - Csirizesítés Szárazanyag tartalom érték [ % ]', data: (parseFloat(neutralKemenyitoSzarazanyag))},
        {name: 'neutralKemenyitoHo', subScription: 'Csirizesítés Hőmérséklet [ ˚C ]', title: 'Keményítő szuszpenzió készítése - Csirizesítés Hőmérséklet [ ˚C ] ',  data: (parseFloat(neutralKemenyitoHo))},
        {name: 'neutralHokezelesHo', subScription: 'Hőkezelés Hőmérséklet [ ˚C ]', title: 'Hőkezelés / hőfeltárás gőzzel Hőmérséklet [ ˚C ]',  data: (parseFloat(neutralHokezelesHo))},
        {name: 'neutralHokezelesIdo', subScription: 'Hőkezelés Időtartam [ m ]', title: 'Hőkezelés / hőfeltárás gőzzel Időtartam [ m ]',  data: (parseFloat(neutralHokezelesIdo))},
        {name: 'neutralFolyositasIdo', subScription: 'Folyósítás Hőmérséklet [ ˚C ]', title: 'Feltárás, folyósítás (magas hőmérsékletű hőkezelés) Hőmérséklet [ ˚C ]',  data: (parseFloat(neutralFolyositasIdo))},
        {name: 'neutralDextHo', subScription: 'Dextrinizálás Hőmérséklet [ ˚C ]', title: 'Dextrinizálás / Hidrolizálás Hőmérséklet [ ˚C ] ',  data: (parseFloat(neutralDextHo))},
        {name: 'neutralCukrositasHo', subScription: 'Cukrosítás Hőmérséklet [ ˚C ]', title: 'Cukrosítás/szacharifikáció (szakaszos) Hőmérséklet [ ˚C ]',  data: (parseFloat(neutralCukrositasHo))},
       ]);
       doBase()
    }

    const doBase = () => {
            setParams([
            {data: (parseFloat(process.env.REACT_APP_KEY_NEUTRAL_KEMENYITOSZARAZANYAG_SULY))},
            {data: (parseFloat(process.env.REACT_APP_KEY_NEUTRAL_KEMENYITOSZARAZANYAG))},
            {data: (parseFloat(process.env.REACT_APP_KEY_NEUTRAL_KEMENYITOSZARAZANYAG_NEG))},
            {data: (parseFloat(process.env.REACT_APP_KEY_NEUTRAL_KEMENYITOSZARAZANYAG_POS))},
            {data: (parseFloat(process.env.REACT_APP_KEY_NEUTRAL_KEMENYITOHO_SULY))},
            {data: (parseFloat(process.env.REACT_APP_KEY_NEUTRAL_KEMENYITOHO))},
            {data: (parseFloat(process.env.REACT_APP_KEY_NEUTRAL_KEMENYITOHO_NEG))},
            {data: (parseFloat(process.env.REACT_APP_KEY_NEUTRAL_KEMENYITOHO_POS))},
            {data: (parseFloat(process.env.REACT_APP_KEY_NEUTRAL_HOKEZELESHO_SULY))},
            {data: (parseFloat(process.env.REACT_APP_KEY_NEUTRAL_HOKEZELESHO))},
            {data: (parseFloat(process.env.REACT_APP_KEY_NEUTRAL_HOKEZELESHO_NEG))},
            {data: (parseFloat(process.env.REACT_APP_KEY_NEUTRAL_HOKEZELESHO_POS))},
            {data: (parseFloat(process.env.REACT_APP_KEY_NEUTRAL_HOKEZELESIDO_SULY))},
            {data: (parseFloat(process.env.REACT_APP_KEY_NEUTRAL_HOKEZELESIDO))},
            {data: (parseFloat(process.env.REACT_APP_KEY_NEUTRAL_HOKEZELESIDO_NEG))},
            {data: (parseFloat(process.env.REACT_APP_KEY_NEUTRAL_HOKEZELESIDO_POS))},
            {data: (parseFloat(process.env.REACT_APP_KEY_NEUTRAL_FOLYOSITASIDO_SULY))},
            {data: (parseFloat(process.env.REACT_APP_KEY_NEUTRAL_FOLYOSITASIDO))},
            {data: (parseFloat(process.env.REACT_APP_KEY_NEUTRAL_FOLYOSITASIDO_NEG))},
            {data: (parseFloat(process.env.REACT_APP_KEY_NEUTRAL_FOLYOSITASIDO_POS))},
            {data: (parseFloat(process.env.REACT_APP_KEY_NEUTRAL_DEXTHO_SULY))},
            {data: (parseFloat(process.env.REACT_APP_KEY_NEUTRAL_DEXTHO))},
            {data: (parseFloat(process.env.REACT_APP_KEY_NEUTRAL_DEXTHO_NEG))},
            {data: (parseFloat(process.env.REACT_APP_KEY_NEUTRAL_DEXTHO_POS))},
            {data: (parseFloat(process.env.REACT_APP_KEY_NEUTRAL_CUKROSITASHO_SULY))},
            {data: (parseFloat(process.env.REACT_APP_KEY_NEUTRAL_CUKROSITASHO))},
            {data: (parseFloat(process.env.REACT_APP_KEY_NEUTRAL_CUKROSITASHO_NEG))},
            {data: (parseFloat(process.env.REACT_APP_KEY_NEUTRAL_CUKROSITASHO_POS))},
          ])
    }

    const handleReset = (e) => {
      e.preventDefault()
      setNeutralKemenyitoSzarazanyag(parseFloat(process.env.REACT_APP_KEY_NEUTRAL_KEMENYITOSZARAZANYAG))
      setNeutralKemenyitoHo(parseFloat(process.env.REACT_APP_KEY_NEUTRAL_KEMENYITOHO))
      setNeutralHokezelesHo(parseFloat(process.env.REACT_APP_KEY_NEUTRAL_HOKEZELESHO))
      setNeutralHokezelesIdo(parseFloat(process.env.REACT_APP_KEY_NEUTRAL_HOKEZELESIDO))
      setNeutralFolyositasIdo(parseFloat(process.env.REACT_APP_KEY_NEUTRAL_FOLYOSITASIDO))
      setNeutralDextHo(parseFloat(process.env.REACT_APP_KEY_NEUTRAL_DEXTHO))
      setNeutralCukrositasHo(parseFloat(process.env.REACT_APP_KEY_NEUTRAL_CUKROSITASHO))
      setSubmitted(false)
      setParams([])
      
    }

    return (
     <div className = 'params'>
      <div className = 'title'>
        <h1>A neutrális alkohol gyártási technológiájának paraméterei</h1>
      </div>
      <div className = 'oneParam'>
        <h1>Keményítő szuszpenzió készítése - Csirizesítés</h1>
        <form onSubmit={handleSubmit} >
          <div className="form-cont">
            <div className="form-element">
              <div className = 'form-element-title'>
                <div className = 'form-element-title1'>
                  <h2>Szárazanyag tartalom érték [ % ] </h2>
                </div>
                <div className = 'form-element-title2'>
                  <h3>paraméter tartománya: 30 - 35% </h3>
                </div>
              </div>
              <div className = 'form-param'>
                <div>
                  <label htmlFor="neutralKemenyitoSzarazanyag">
                    Szárazanyag tartalom:  
                  </label>
                  <input type="number" min="30" max="35" step="0.01" value = {neutralKemenyitoSzarazanyag} name="neutralKemenyitoSzarazanyag" id="email" required onChange={(e) => setNeutralKemenyitoSzarazanyag(e.target.value)}></input>
                    %
                </div>
                <div className = 'form-range'>
                  30%  
                  <input type="range" min="30" max="35" step="0.01" value = {neutralKemenyitoSzarazanyag} className="custom-range" id="customRange1" onChange={(e) => setNeutralKemenyitoSzarazanyag(e.target.value)}/>
                    35%
                </div>
              </div>
            </div>
            <div className="form-element">
              <div className = 'form-element-title'>
                <div className = 'form-element-title1'>
                  <h2>Hőmérséklet [ ˚C ] </h2>
                </div>
                <div className = 'form-element-title2'>
                  <h3>paraméter tartománya: 16 - 22 ˚C </h3>
                </div>
              </div>
              <div className = 'form-param'>
                <div>
                  <label htmlFor="kemenyitoHo">
                    Hőmérséklet:  
                  </label>
                  <input type="number" min="16" max="22" step="0.01" value = {neutralKemenyitoHo} name="kemenyitoHo" id="kemenyitoHo" required onChange={(e) => setNeutralKemenyitoHo(e.target.value)}></input>
                    ˚C
                </div>
                <div className = 'form-range'>
                    16˚C  
                  <input type="range" min="16" max="22" step="0.01" value = {neutralKemenyitoHo} className="custom-range" id="customRange1" onChange={(e) => setNeutralKemenyitoHo(e.target.value)}/>
                    22˚C
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
        <div className = 'oneParam'>
          <h1>Hőkezelés / hőfeltárás gőzzel</h1>
          <form onSubmit={handleSubmit} >
            <div className="form-cont">
              <div className="form-element">
                <div className = 'form-element-title'>
                  <div className = 'form-element-title1'>
                    <h2>Hőmérséklet [ ˚C ] </h2>
                  </div>
                  <div className = 'form-element-title2'>
                    <h3>paraméter tartománya: 100 - 140 ˚C </h3>
                  </div>
                </div>
                <div className = 'form-param'>
                  <div>
                    <label htmlFor="hokezelesHo">
                      Hőmérséklet:  
                    </label>
                    <input type="number" min="100" max="140" step="0.01" value = {neutralHokezelesHo} name="hokezelesHo" id="hokezelesHo" required onChange={(e) => setNeutralHokezelesHo(e.target.value)}></input>
                      ˚C
                  </div>
                  <div className = 'form-range'>
                    100˚C  
                    <input type="range" min="100" max="140" step="0.01" value = {neutralHokezelesHo} className="custom-range" id="customRange1" onChange={(e) => setNeutralHokezelesHo(e.target.value)}/>
                    140˚C
                  </div>
                </div>
              </div>
              <div className="form-element">
                <div className = 'form-element-title'>
                  <div className = 'form-element-title1'>
                    <h2>Időtartam [ m ] </h2>
                  </div>
                  <div className = 'form-element-title2'>
                    <h3>paraméter tartománya: 10 - 30 m </h3>
                  </div>
                </div>
                <div className = 'form-param'>
                  <div>
                    <label htmlFor="hokezelesIdo">
                      Időtartam:  
                    </label>
                    <input type="number" min="10" max="30" step="0.01" value = {neutralHokezelesIdo} name="hokezelesIdo" id="email" required onChange={(e) => setNeutralHokezelesIdo(e.target.value)}></input>
                      m
                  </div>
                  <div className = 'form-range'>
                    10m 
                    <input type="range" min="10" max="30" step="0.01" value = {neutralHokezelesIdo} className="custom-range" id="customRange1" onChange={(e) => setNeutralHokezelesIdo(e.target.value)}/>
                    30m
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className = 'oneParam'>
          <h1>Feltárás, folyósítás (magas hőmérsékletű hőkezelés) </h1>
          <form onSubmit={handleSubmit} >
            <div className="form-cont">

              <div className="form-element">
                <div className = 'form-element-title'>
                  <div className = 'form-element-title1'>
                    <h2>Időtartam [ m ] </h2>
                  </div>
                  <div className = 'form-element-title2'>
                    <h3>paraméter tartománya: 20 - 30 min </h3>
                  </div>
                </div>
                <div className = 'form-param'>
                  <div>
                    <label htmlFor="folyositasIdo">
                      Időtartam:  
                    </label>
                    <input type="number" min="20" max="20" step="0.01" value = {neutralFolyositasIdo} name="folyositasIdo" id="email" required onChange={(e) => setNeutralFolyositasIdo(e.target.value)}></input>
                      s
                  </div>
                  <div className = 'form-range'>
                    20m  
                    <input type="range" min="20" max="30" step="0.01" value = {neutralFolyositasIdo} className="custom-range" id="customRange1" onChange={(e) => setNeutralFolyositasIdo(e.target.value)}/>
                    30m
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className = 'oneParam'>
          <h1>Dextrinizálás / Hidrolizálás</h1>
          <form onSubmit={handleSubmit} >
            <div className="form-cont">
              <div className="form-element">
                <div className = 'form-element-title'>
                  <div className = 'form-element-title1'>
                    <h2>Hőmérséklet [ ˚C ]  </h2>
                  </div>
                  <div className = 'form-element-title2'>
                    <h3>paraméter tartománya: 80 - 90 ˚C </h3>
                  </div>
                </div>
                <div className = 'form-param'>
                  <div>
                    <label htmlFor="dextHo">
                      Hőmérséklet:  
                    </label>
                    <input type="number" min="80" max="90" step="0.01" value = {neutralDextHo} name="dextHo" id="dextHo" required onChange={(e) => setNeutralDextHo(e.target.value)}></input>
                      ˚C
                  </div>
                  <div className = 'form-range'>
                    80˚C  
                    <input type="range" min="80" max="90" step="0.01" value = {neutralDextHo} className="custom-range" id="customRange1" onChange={(e) => setNeutralDextHo(e.target.value)}/>
                    90˚C
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className = 'oneParam'>
          <h1>Cukrosítás/szacharifikáció (szakaszos)</h1>
          <form onSubmit={handleSubmit} >
            <div className="form-cont">
              <div className="form-element">
                <div className = 'form-element-title'>
                  <div className = 'form-element-title1'>
                    <h2>Hőmérséklet [ ˚C ]  </h2>
                  </div>
                  <div className = 'form-element-title2'>
                    <h3>paraméter tartománya: 45 - 55 ˚C </h3>
                  </div>
                </div>
                <div className = 'form-param'>
                  <div>
                    <label htmlFor="dextHo">
                      Hőmérséklet:  
                    </label>
                    <input type="number" min="45" max="55" step="0.01" value = {neutralCukrositasHo} name="dextHo" id="dextHo" required onChange={(e) => setNeutralCukrositasHo(e.target.value)}></input>
                      ˚C
                  </div>
                  <div className = 'form-range'>
                    45˚C  
                    <input type="range" min="45" max="55" step="0.01" value = {neutralCukrositasHo} className="custom-range" id="customRange1" onChange={(e) => setNeutralCukrositasHo(e.target.value)}/>
                    55˚C
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      <div className = 'buttons'>
        {submitted ? <button className = 'btn btn-warning btn-lg' onClick={(e) => handleReset(e)}>Új szimuláció</button> : <button className="btn btn-success btn-lg" onClick={(e) => handleSubmit(e)}>Szimuláció indítása</button>}
      </div>
    </div>
    );
      
  }

    
    export default NeutralParams;
    