import React, { useEffect, useState, useContext, Fragment  } from 'react';
import '../App.css';
import { appContext } from '../App'
import ReactS3 from 'react-s3'
import { jsonSave, jsonRead, jsonSaveNeutral, jsonReadNeutral } from '../database/DatabaseOp'
require("dotenv").config()

function UploaderJson(props){


  
  //appContext
  const [ 
    appContactList, setAppContactList, actualUser, setActualUser, actualName, setActualName, updaterId, setUpdaterId, eredmenyData, setEredmenyData, submitted, setSubmitted, maltoBuza, setMaltoBuza,
    kemenyitoSzarazanyag, setKemenyitoSzarazanyag, kemenyitoHo, setKemenyitoHo, hokezelesHo, setHokezelesHo, hokezelesIdo, setHokezelesIdo,
    folyositasHo, setFolyositasHo, folyositasIdo, setFolyositasIdo, dextHo, setDextHo, dextIdo, setDextIdo,
    kukKemenyitoSzarazanyag, setKukKemenyitoSzarazanyag, kukKemenyitoHo, setKukKemenyitoHo, kukHokezelesHo, setKukHokezelesHo, kukHokezelesIdo, setKukHokezelesIdo,
    kukFolyositasHo, setKukFolyositasHo, kukFolyositasIdo, setKukFolyositasIdo, kukDextHo, setKukDextHo, kukDextIdo, setKukDextIdo,
    neutralKemenyitoSzarazanyag, setNeutralKemenyitoSzarazanyag, neutralKemenyitoHo, setNeutralKemenyitoHo, neutralHokezelesHo, setNeutralHokezelesHo,
    neutralHokezelesIdo, setNeutralHokezelesIdo, neutralFolyositasIdo, setNeutralFolyositasIdo, neutralDextHo, setNeutralDextHo,
    neutralCukrositasHo, setNeutralCukrositasHo,
    results, setResults, params, setParams, 
    sulySzazalek, setSulySzazalek, szakadas, setSzakadas, szazalekEredmeny, setSzazalekEredmeny, energiaFelhasznalas, setEnergiaFelhasznalas, energiaHatekonysag, setEnergiaHatekonysag,
    jsonList, setJsonList, pdfList, setPdfList, foundPdfList, setFoundPdfList, pdfListNeutral, setPdfListNeutral, jsonListNeutral, setJsonListNeutral, jsonTextListNeutral, setJsonTextListNeutral,
    popup, setPopup, popupVisibility, setPopupVisibility, popupMessage, setPopupMessage, jsonTextList, setJsonTextList
  ] = useContext(appContext)

  const [ file, setFile ] = useState(null)
  const [ fileName, setFileName ] = useState(null)

  let theBucket = props.jsonBucketName

  const s3Config = {
    bucketName: theBucket,
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESSKEYID,
    secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY,
  }

  useEffect(() => {

  }, [])

    const handleChangeUpload = (e) => { 
      if(e.target.files[0] === undefined){
        setPopupMessage('Hiba történt, kérem próbálja újra!')
        setPopup('popupShow')
        setPopupVisibility(true)
      }else{
        setFile(e.target.files[0])
        setFileName(e.target.files[0].name) 
      }
  }
  
    const handleUpload = (e) => {
      e.preventDefault()
      console.log('handleupload ', file)
      ReactS3.uploadFile(file, s3Config)
      .then((data) =>{
        console.log('handleupload then', data)
        setPopupMessage(`A ${data.key} nevű file feltöltve!`)
        setPopup('popupShow')
        setPopupVisibility(true)
        saveJson(data.key, data.location)
      })
      .catch((err) => {
        console.log('handleupload catch', err)
      })
    }

      //feltöltött file adatai az adatbázisba
      const saveJson = (name, url) => {
        if(theBucket == 'tridevjson'){
          jsonSave(name, url)
          .then(data => 
            console.log('upload resolved '),
            jsonRead()
              .then((value) => {
                setJsonList(value)
              }).catch((error) => {
                console.log('app error', error)
              })
          ).catch(err => console.log('upload rejected ', err))
        }else{
          jsonSaveNeutral(name, url)
          .then(data => 
            console.log('upload resolved '),
            jsonReadNeutral()
              .then((value) => {
                setJsonListNeutral(value)
              }).catch((error) => {
                console.log('app error', error)
              })
          ).catch(err => console.log('upload rejected ', err))
        }
        
      }

    const handleShow = (e) => { 
      props.history.push('showPdf');
    }
  
    return(
          <div className = 'pdfUpload'>
            <h3>JSON feltöltés</h3>
                  <Fragment>
              <div className="input-group mb-3">
                <div className="custom-file">
                  <input type="file" className="custom-file-input" id="inputGroupFile02" onChange = {handleChangeUpload} ></input>
                    <label className="custom-file-label" htmlFor="inputGroupFile02">{fileName}</label>
                </div>
                <div className="input-group-append">
                  <span onClick={(e) => handleUpload(e)} className="input-group-text" id="">Upload</span>
                </div>
              </div>
              </Fragment>
         
          </div>

    )
}

export default UploaderJson