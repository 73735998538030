import React, { useEffect, useState, useContext  } from 'react';
import adminPic from '../assets/admin.jpg'
import '../App.css';
import Header from '../components/Header'
import { appContext } from '../App'
import fire, { storage } from '../database/Firebase'
import { updateContact } from '../database/DatabaseOp'

import SimpleFileUpload from 'react-simple-file-upload'


let buttonStyle
let refWithId
let emailObject = {}

function AdminScreen(props){

    //appContext
    const [ 
      appContactList, setAppContactList, actualUser, setActualUser, actualName, setActualName, updaterId, setUpdaterId, eredmenyData, setEredmenyData, submitted, setSubmitted, maltoBuza, setMaltoBuza,
        kemenyitoSzarazanyag, setKemenyitoSzarazanyag, kemenyitoHo, setKemenyitoHo, hokezelesHo, setHokezelesHo, hokezelesIdo, setHokezelesIdo,
        folyositasHo, setFolyositasHo, folyositasIdo, setFolyositasIdo, dextHo, setDextHo, dextIdo, setDextIdo,
        kukKemenyitoSzarazanyag, setKukKemenyitoSzarazanyag, kukKemenyitoHo, setKukKemenyitoHo, kukHokezelesHo, setKukHokezelesHo, kukHokezelesIdo, setKukHokezelesIdo,
        kukFolyositasHo, setKukFolyositasHo, kukFolyositasIdo, setKukFolyositasIdo, kukDextHo, setKukDextHo, kukDextIdo, setKukDextIdo,
        neutralKemenyitoSzarazanyag, setNeutralKemenyitoSzarazanyag, neutralKemenyitoHo, setNeutralKemenyitoHo, neutralHokezelesHo, setNeutralHokezelesHo,
        neutralHokezelesIdo, setNeutralHokezelesIdo, neutralFolyositasIdo, setNeutralFolyositasIdo, neutralDextHo, setNeutralDextHo,
        neutralCukrositasHo, setNeutralCukrositasHo,
        results, setResults, params, setParams, 
        sulySzazalek, setSulySzazalek, szakadas, setSzakadas, szazalekEredmeny, setSzazalekEredmeny, energiaFelhasznalas, setEnergiaFelhasznalas, energiaHatekonysag, setEnergiaHatekonysag,
        textList, setTextList, pdfList, setPdfList
     ] = useContext(appContext)

     
    const sendRegMail = fire.functions().httpsCallable('sendRegEmail')

    useEffect(() => {
  
   
    }, [])



    const handleLogout = (value) => {
        fire.auth().signOut().then(function() {
          }, function(error) {
            console.error('Sign Out Error', error);
          });
        const { history } = props;
        history.push('home');
      }

    const handleApply = (id, email) => {
      console.log('admin apply email ', email)
      setUpdaterId(id)
      let userUpdate = {}
      userUpdate.user_id = id
      userUpdate.role = 'Jóváhagyott'
      refWithId = fire.database().ref(`contacts/${id}`)
      updateContact(refWithId, userUpdate)
      //jóváhagyó email usernek
      emailObject.email = email
      emailObject.title = 'Jóváhagyott regisztráció a tridevinnovacio.hu oldalon'
      emailObject.text = 'Az adminisztrátor jóváhagyta a regisztrációját a tridevinnovacio.hu oldalon. Bejelentkezés után minden menüpontot el fog érni.'
      handleEmail(emailObject)
    }

    const handleEmail = (emailObject) => {
      console.log('register handleemail ', emailObject)
      sendRegMail({email: emailObject.email, title: emailObject.title, text: emailObject.text})
      .then(result => console.log(result.data))
      .catch((error) => { console.log(error)});
    }

    const handleHome = (value) => { const { history } = props; history.push('choose'); }

    return(
        <div >
         {actualName == undefined ? '' : <Header handleLogout = {handleLogout} handleHome = {handleHome} userName = {actualName}></Header>}
          <div className = 'admin'>
            <div className = 'admiTable'>
              <div className = 'adminRow'> 
                <tr>
                  <th id = 'adminUser'>Felhasználó</th>
                  <th id = 'adminRole'>Szerep</th>
                </tr>
              </div>
              {Object.keys(appContactList).map(id =>{
                {appContactList[id].role == 'regisztrált' ? buttonStyle = 'buttonVisible' : buttonStyle = 'buttonInvisible'}
                return <div key = {id} className = 'adminRow'>
                  <tr key = {id}>
                    <td id = 'adminUser'>{appContactList[id].email}</td>
                    <td id = 'adminRole'>{appContactList[id].role}</td>
                    <td className = {buttonStyle}>{<button className = 'btn btn-warning btn-lg' onClick={(e) => handleApply(id, appContactList[id].email)}>jóváhagyás</button>}</td>
                  </tr>
                </div>
              })}
            </div>

         
          
             
          </div>
                  
          <img className = 'appContainer'  src={adminPic} alt="choose"></img>
           
        </div>
    )
}

export default AdminScreen