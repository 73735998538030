import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter, Route } from 'react-router-dom';
import HomeScreen from './screens/HomeScreen';
import LoginScreen from './screens/LoginScreen';
import RegisterScreen from './screens/RegisterScreen';
import ChooseScreen from './screens/ChooseScreen';
import PdfScreen from './screens/PdfScreen';
import AdminScreen from './screens/AdminScreen';
import ShowPdf from './screens/ShowPdf';
import MaltoBuzaContainer from './components/maltoBuza/MaltoBuzaContainer'
import NeutralContainer from './components/neutral/NeutralContainer'
import fire from './database/Firebase'
import { loadContacts, pdfRead, jsonRead, pdfReadNeutral, jsonReadNeutral } from './database/DatabaseOp'
import MaltoTudasbazis from './screens/MaltoTudasbazis'
import NeutralTudasbazis from './screens/NeutralTudasbazis'
import { motion, AnimatePresence } from 'framer-motion'
import Popup from './util/Popup'
import { parser } from './util/JsonParser'

export const appContext = React.createContext([{}, () => {}])

let ref = fire.database().ref('contacts')
let fillChecker = false
let fillCheckerNeutral = false

const App = (props) => {

  const [ appContactList, setAppContactList ] = useState([]);
  const [ actualUser, setActualUser ] = useState({});
  const [ actualName, setActualName ] = useState({});
  const [ updaterId, setUpdaterId ] = useState(['']);
  const [ eredmenyData, setEredmenyData ] = useState([]);
  const [ submitted, setSubmitted ] = useState(false);
  const [ maltoBuza, setMaltoBuza ] = useState(false);

  const [ kemenyitoSzarazanyag, setKemenyitoSzarazanyag ] = useState(parseFloat(process.env.REACT_APP_KEY_MALTO_KEMENYITOSZARAZANYAG));
  const [ kemenyitoHo, setKemenyitoHo ] = useState(parseFloat(process.env.REACT_APP_KEY_MALTO_KEMENYITOHO));
  const [ hokezelesHo, setHokezelesHo ] = useState(parseFloat(process.env.REACT_APP_KEY_MALTO_HOKEZELESHO));
  const [ hokezelesIdo, setHokezelesIdo ] = useState(parseFloat(process.env.REACT_APP_KEY_MALTO_HOKEZELESIDO));
  const [ folyositasHo, setFolyositasHo ] = useState(parseFloat(process.env.REACT_APP_KEY_MALTO_FOLYOSITASHO));
  const [ folyositasIdo, setFolyositasIdo ] = useState(parseFloat(process.env.REACT_APP_KEY_MALTO_FOLYOSITASIDO));
  const [ dextHo, setDextHo ] = useState(parseFloat(process.env.REACT_APP_KEY_MALTO_DEXTHO));
  const [ dextIdo, setDextIdo ] = useState(parseFloat(process.env.REACT_APP_KEY_MALTO_DEXTIDO));

  const [ kukKemenyitoSzarazanyag, setKukKemenyitoSzarazanyag ] = useState(parseFloat(process.env.REACT_APP_KEY_MALTO_KEMENYITOSZARAZANYAG));
  const [ kukKemenyitoHo, setKukKemenyitoHo ] = useState(parseFloat(process.env.REACT_APP_KEY_MALTO_KEMENYITOHO));
  const [ kukHokezelesHo, setKukHokezelesHo ] = useState(parseFloat(process.env.REACT_APP_KEY_MALTO_HOKEZELESHO));
  const [ kukHokezelesIdo, setKukHokezelesIdo ] = useState(parseFloat(process.env.REACT_APP_KEY_MALTO_HOKEZELESIDO));
  const [ kukFolyositasHo, setKukFolyositasHo ] = useState(parseFloat(process.env.REACT_APP_KEY_MALTO_FOLYOSITASHO));
  const [ kukFolyositasIdo, setKukFolyositasIdo ] = useState(parseFloat(process.env.REACT_APP_KEY_MALTO_FOLYOSITASIDO));
  const [ kukDextHo, setKukDextHo ] = useState(parseFloat(process.env.REACT_APP_KEY_MALTO_DEXTHO));
  const [ kukDextIdo, setKukDextIdo ] = useState(parseFloat(process.env.REACT_APP_KEY_MALTO_DEXTIDO));

  const [ neutralKemenyitoSzarazanyag, setNeutralKemenyitoSzarazanyag ] = useState(parseFloat(process.env.REACT_APP_KEY_NEUTRAL_KEMENYITOSZARAZANYAG));
  const [ neutralKemenyitoHo, setNeutralKemenyitoHo ] = useState(parseFloat(process.env.REACT_APP_KEY_NEUTRAL_KEMENYITOHO));
  const [ neutralHokezelesHo, setNeutralHokezelesHo ] = useState(parseFloat(process.env.REACT_APP_KEY_NEUTRAL_HOKEZELESHO));
  const [ neutralHokezelesIdo, setNeutralHokezelesIdo ] = useState(parseFloat(process.env.REACT_APP_KEY_NEUTRAL_HOKEZELESIDO));
  const [ neutralFolyositasIdo, setNeutralFolyositasIdo ] = useState(parseFloat(process.env.REACT_APP_KEY_NEUTRAL_FOLYOSITASIDO));
  const [ neutralDextHo, setNeutralDextHo ] = useState(parseFloat(process.env.REACT_APP_KEY_NEUTRAL_DEXTHO));
  const [ neutralCukrositasHo, setNeutralCukrositasHo ] = useState(parseFloat(process.env.REACT_APP_KEY_NEUTRAL_CUKROSITASHO));


  const [ results, setResults ] = useState([]);
  const [ params, setParams ] = useState([]);
  const [ sulySzazalek, setSulySzazalek ] = useState([0]);
  const [ szakadas, setSzakadas ] = useState([]);
  const [ szazalekEredmeny, setSzazalekEredmeny ] = useState([]);
  const [ energiaFelhasznalas, setEnergiaFelhasznalas ] = useState([]);
  const [ energiaHatekonysag, setEnergiaHatekonysag ] = useState([]);

  const [ jsonList, setJsonList ] = useState([]);
  const [ pdfList, setPdfList ] = useState([]);
  const [ jsonListNeutral, setJsonListNeutral ] = useState([]);
  const [ pdfListNeutral, setPdfListNeutral ] = useState([]);
  const [ jsonTextList, setJsonTextList ] = useState([]);
  const [ jsonTextListNeutral, setJsonTextListNeutral ] = useState([]);
  const [ foundPdfList, setFoundPdfList ] = useState([]);

  const [ popup, setPopup ] = useState('popupHide');
  const [ popupVisibility, setPopupVisibility ] = useState(true);
  const [ popupMessage, setPopupMessage ] = useState('');


  useEffect(() => {
    loadContacts(ref)
    .then((value) => {
      setAppContactList([])
      setAppContactList(value)
    })
    .catch((error) => {
      console.log('app error', error)
    });
    
    pdfRead()
    .then((value) => { setPdfList(value) })
    .catch((error) => { console.log('app error', error) });

    jsonRead()
    .then((value) => {
      //az adatbázis jsonlist táblájának beolvasása 
      setJsonList(value)
      //a jsonTextList feltöltése a jsonList alapján
      if(!fillChecker){
        fillJsonList(value)
        fillChecker = true
      }
    })
    .catch((error) => { console.log('app error', error) });

    pdfReadNeutral()
    .then((value) => { setPdfListNeutral(value) })
    .catch((error) => { console.log('app error', error) });

    jsonReadNeutral()
    .then((value) => {
      //az adatbázis jsonlist táblájának beolvasása 
      setJsonListNeutral(value)
      //a jsonTextList feltöltése a jsonList alapján
      if(!fillCheckerNeutral){
        fillJsonListNeutral(value)
        fillCheckerNeutral = true
      }
    })
    .catch((error) => { console.log('app error', error) });

  }, [actualUser, updaterId, params])

  const fillJsonList = async (value) => { 
    {Object.keys(value).map(id =>{
      let theLink = value[id].link
      let parsed = callParser(value[id].name, value[id].link)
      .then((value) => {
        let textObject = {}
        var sliced = value.name.slice(0, (value.name.length - 5))
        textObject.name = sliced
        textObject.text = value.text
        textObject.link = theLink
        setJsonTextList(jsonTextList => [...jsonTextList, textObject])
      })
      .catch((error) => {
        console.log('app error', error)
      });
    })}
  }

  const fillJsonListNeutral = async (value) => { 
    {Object.keys(value).map(id =>{
      let theLink = value[id].link
      let parsed = callParser(value[id].name, value[id].link)
      .then((value) => {
        let textObject = {}
        var sliced = value.name.slice(0, (value.name.length - 5))
        textObject.name = sliced
        textObject.text = value.text
        textObject.link = theLink
        setJsonTextListNeutral(jsonTextListNeutral => [...jsonTextListNeutral, textObject])
      })
      .catch((error) => {
        console.log('app error', error)
      });
    })}
  }

  const callParser = async (name, link) => { 
    let res = await parser(name, link)
    return res
  }

  const anim1 = { hidden: { scale: 1, x: '-80rem', y: '-100rem' }, visible: { x: '-80rem', y: '8.5rem', scale: 1,  transition: {type: "spring", stiffness: 70,  duration: 1 } }, hover: { scale: 0.75} }

  const popupHide = () => {
   setPopupVisibility(false)
   setTimeout(() => {
     setPopup('popupHide')
   }, 1000)
  }

  const popupRedirect = () => {
    const { history } = props;
    history.push('home');
   }

  return (
    <BrowserRouter>
      <appContext.Provider value = {[
        appContactList, setAppContactList, actualUser, setActualUser, actualName, setActualName, updaterId, setUpdaterId, eredmenyData, setEredmenyData, submitted, setSubmitted, maltoBuza, setMaltoBuza,
        kemenyitoSzarazanyag, setKemenyitoSzarazanyag, kemenyitoHo, setKemenyitoHo, hokezelesHo, setHokezelesHo, hokezelesIdo, setHokezelesIdo,
        folyositasHo, setFolyositasHo, folyositasIdo, setFolyositasIdo, dextHo, setDextHo, dextIdo, setDextIdo,
        kukKemenyitoSzarazanyag, setKukKemenyitoSzarazanyag, kukKemenyitoHo, setKukKemenyitoHo, kukHokezelesHo, setKukHokezelesHo, kukHokezelesIdo, setKukHokezelesIdo,
        kukFolyositasHo, setKukFolyositasHo, kukFolyositasIdo, setKukFolyositasIdo, kukDextHo, setKukDextHo, kukDextIdo, setKukDextIdo,
        neutralKemenyitoSzarazanyag, setNeutralKemenyitoSzarazanyag, neutralKemenyitoHo, setNeutralKemenyitoHo, neutralHokezelesHo, setNeutralHokezelesHo,
        neutralHokezelesIdo, setNeutralHokezelesIdo, neutralFolyositasIdo, setNeutralFolyositasIdo, neutralDextHo, setNeutralDextHo,
        neutralCukrositasHo, setNeutralCukrositasHo,
        results, setResults, params, setParams, 
        sulySzazalek, setSulySzazalek, szakadas, setSzakadas, szazalekEredmeny, setSzazalekEredmeny, energiaFelhasznalas, setEnergiaFelhasznalas, energiaHatekonysag, setEnergiaHatekonysag,
        jsonList, setJsonList, pdfList, setPdfList, foundPdfList, setFoundPdfList, pdfListNeutral, setPdfListNeutral, jsonListNeutral, setJsonListNeutral, jsonTextListNeutral, setJsonTextListNeutral,
        popup, setPopup, popupVisibility, setPopupVisibility, popupMessage, setPopupMessage, jsonTextList, setJsonTextList
        ]}>
        <div className='appContainer'>
          {popup === 'popupShow' ? 
          
              (<div className='popupContainer'>
                <AnimatePresence>
                  {popupVisibility && (
                    
                    <motion.div exit = { {y: 900} }>
                      <motion.div variants = {anim1} initial = 'hidden' animate = 'visible'>
                        <Popup popupHide = {popupHide} popupRedirect = {popupRedirect}></Popup> 
                        
                      </motion.div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
              )
              : ''
            }
          <Route path = "/" exact = {true} component = {HomeScreen}></Route>
          <Route path = "/home" component = {HomeScreen}></Route>
          <Route path = "/choose" component = {ChooseScreen}></Route>
          <Route path = "/login"  component = {LoginScreen}></Route>
          <Route path = "/register" component = {RegisterScreen}></Route>
          <Route path = "/maltoBuza" component = {MaltoBuzaContainer}></Route>
          <Route path = "/neutral" component = {NeutralContainer}></Route>
          <Route path = "/pdf" component = {PdfScreen}></Route>
          <Route path = "/admin" component = {AdminScreen}></Route>
          <Route path = "/showpdf" component = {ShowPdf}></Route>
          <Route path = "/maltoTudasbazis" component = {MaltoTudasbazis}></Route>
          <Route path = "/neutralTudasbazis" component = {NeutralTudasbazis}></Route>
              
        </div>
      </appContext.Provider>
    </BrowserRouter>
  );
  
}

export default App;
