import React, { useEffect, useState, useContext } from 'react';
import './MaltoBuza.css';
import { appContext } from '../../App'
import { maltoBuzaContext } from './MaltoBuzaContainer'

let title

const MaltoBuzaParams = (props) => {

    //Context
    const [ 
      appContactList, setAppContactList, actualUser, setActualUser, actualName, setActualName, updaterId, setUpdaterId, eredmenyData, setEredmenyData, submitted, setSubmitted, maltoBuza, setMaltoBuza,
      kemenyitoSzarazanyag, setKemenyitoSzarazanyag, kemenyitoHo, setKemenyitoHo, hokezelesHo, setHokezelesHo, hokezelesIdo, setHokezelesIdo,
      folyositasHo, setFolyositasHo, folyositasIdo, setFolyositasIdo, dextHo, setDextHo, dextIdo, setDextIdo,
      kukKemenyitoSzarazanyag, setKukKemenyitoSzarazanyag, kukKemenyitoHo, setKukKemenyitoHo, kukHokezelesHo, setKukHokezelesHo, kukHokezelesIdo, setKukHokezelesIdo,
      kukFolyositasHo, setKukFolyositasHo, kukFolyositasIdo, setKukFolyositasIdo, kukDextHo, setKukDextHo, kukDextIdo, setKukDextIdo,
      neutralKemenyitoSzarazanyag, setNeutralKemenyitoSzarazanyag, neutralKemenyitoHo, setNeutralKemenyitoHo, neutralHokezelesHo, setNeutralHokezelesHo,
      neutralHokezelesIdo, setNeutralHokezelesIdo, neutralFolyositasIdo, setNeutralFolyositasIdo, neutralDextHo, setNeutralDextHo,
      neutralCukrositasHo, setNeutralCukrositasHo,
      results, setResults, params, setParams
     ] = useContext(appContext)
   

    const handleSubmit = (e) => {
      console.log('handlesubmit')
      e.preventDefault()
      setSubmitted(true)
      if(maltoBuza){
        setEredmenyData([
          {name: 'kemenyitoSzarazanyag', subScription: 'Csirizesítés szárazanyagtartalom [ % ]', title: 'Keményítő szuszpenzió készítése - Csirizesítés Szárazanyag tartalom érték [ % ]', data: kemenyitoSzarazanyag},
          {name: 'kemenyitoHo', subScription: 'Csirizesítés Hőmérséklet [ ˚C ]', title: 'Keményítő szuszpenzió készítése - Csirizesítés Hőmérséklet [ ˚C ] ', data: kemenyitoHo},
          {name: 'hokezelesHo', subScription: 'Hőkezelés Hőmérséklet [ ˚C ]', title: 'Hőkezelés / hőfeltárás gőzzel Hőmérséklet [ ˚C ]', data: hokezelesHo},
          {name: 'hokezelesIdo', subScription: 'Hőkezelés Időtartam [ s ]', title: 'Hőkezelés / hőfeltárás gőzzel Időtartam [ s ]', data: hokezelesIdo},
          {name: 'folyositasHo', subScription: 'Folyósítás Hőmérséklet [ ˚C ]', title: 'Folyósítás (magas hőmérsékletű hőkezelés) folytonos technológia Hőmérséklet [ ˚C ]', data: folyositasHo},
          {name: 'folyositasIdo', subScription: 'Folyósítás Időtartam [ m ]', title: 'Folyósítás (magas hőmérsékletű hőkezelés) folytonos technológia Időtartam [ m ] ', data: folyositasIdo},
          {name: 'dextHo', subScription: 'Dextrinizálás Hőmérséklet [ ˚C ]', title: 'Dextrinizálás / Hidrolizálás Hőmérséklet [ ˚C ]', data: dextHo},
          {name: 'dextIdo', subScription: 'Dextrinizálás Időtartam [ m ]', title: 'Dextrinizálás / Hidrolizálás Időtartam [ m ]', data: dextIdo},
         ]);
      }else{
        setEredmenyData([
          {name: 'kukKemenyitoSzarazanyag', subScription: 'Csirizesítés szárazanyagtartalom [ % ]',  title: 'Keményítő szuszpenzió készítése - Csirizesítés Szárazanyag tartalom érték [ % ]', data: kukKemenyitoSzarazanyag},
          {name: 'kukKemenyitoHo', subScription: 'Csirizesítés Hőmérséklet [ ˚C ]', title: 'Keményítő szuszpenzió készítése - Csirizesítés Hőmérséklet [ ˚C ] ', data: kukKemenyitoHo},
          {name: 'kukHokezelesHo', subScription: 'Hőkezelés Hőmérséklet [ ˚C ]', title: 'Hőkezelés / hőfeltárás gőzzel Hőmérséklet [ ˚C ]', data: kukHokezelesHo},
          {name: 'kukHokezelesIdo', subScription: 'Hőkezelés Időtartam [ s ]', title: 'Hőkezelés / hőfeltárás gőzzel Időtartam [ s ]', data: kukHokezelesIdo},
          {name: 'kukFolyositasHo', subScription: 'Folyósítás Hőmérséklet [ ˚C ]', title: 'Folyósítás (magas hőmérsékletű hőkezelés) folytonos technológia Hőmérséklet [ ˚C ]', data: kukFolyositasHo},
          {name: 'kukFolyositasIdo', subScription: 'Folyósítás Időtartam [ m ]', title: 'Folyósítás (magas hőmérsékletű hőkezelés) folytonos technológia Időtartam [ m ] ', data: kukFolyositasIdo},
          {name: 'kukDextHo', subScription: 'Dextrinizálás Hőmérséklet [ ˚C ]', title: 'Dextrinizálás / Hidrolizálás Hőmérséklet [ ˚C ]', data: kukDextHo},
          {name: 'kukDextIdo', subScription: 'Dextrinizálás Időtartam [ m ]', title: 'Dextrinizálás / Hidrolizálás Időtartam [ m ]', data: kukDextIdo},
         ]);
      }
      doBase()
    }

    const doBase = () => {
      setParams([
      {data: (parseFloat(process.env.REACT_APP_KEY_MALTO_KEMENYITOSZARAZANYAG_SULY))},
      {data: (parseFloat(process.env.REACT_APP_KEY_MALTO_KEMENYITOSZARAZANYAG))},
      {data: (parseFloat(process.env.REACT_APP_KEY_MALTO_KEMENYITOSZARAZANYAG_NEG))},
      {data: (parseFloat(process.env.REACT_APP_KEY_MALTO_KEMENYITOSZARAZANYAG_POS))},
      {data: (parseFloat(process.env.REACT_APP_KEY_MALTO_KEMENYITOHO_SULY))},
      {data: (parseFloat(process.env.REACT_APP_KEY_MALTO_KEMENYITOHO))},
      {data: (parseFloat(process.env.REACT_APP_KEY_MALTO_KEMENYITOHO_NEG))},
      {data: (parseFloat(process.env.REACT_APP_KEY_MALTO_KEMENYITOHO_POS))},
      {data: (parseFloat(process.env.REACT_APP_KEY_MALTO_HOKEZELESHO_SULY))},
      {data: (parseFloat(process.env.REACT_APP_KEY_MALTO_HOKEZELESHO))},
      {data: (parseFloat(process.env.REACT_APP_KEY_MALTO_HOKEZELESHO_NEG))},
      {data: (parseFloat(process.env.REACT_APP_KEY_MALTO_HOKEZELESHO_POS))},
      {data: (parseFloat(process.env.REACT_APP_KEY_MALTO_HOKEZELESIDO_SULY))},
      {data: (parseFloat(process.env.REACT_APP_KEY_MALTO_HOKEZELESIDO))},
      {data: (parseFloat(process.env.REACT_APP_KEY_MALTO_HOKEZELESIDO_NEG))},
      {data: (parseFloat(process.env.REACT_APP_KEY_MALTO_HOKEZELESIDO_POS))},
      {data: (parseFloat(process.env.REACT_APP_KEY_MALTO_FOLYOSITASHO_SULY))},
      {data: (parseFloat(process.env.REACT_APP_KEY_MALTO_FOLYOSITASHO))},
      {data: (parseFloat(process.env.REACT_APP_KEY_MALTO_FOLYOSITASHO_NEG))},
      {data: (parseFloat(process.env.REACT_APP_KEY_MALTO_FOLYOSITASHO_POS))},
      {data: (parseFloat(process.env.REACT_APP_KEY_MALTO_FOLYOSITASIDO_SULY))},
      {data: (parseFloat(process.env.REACT_APP_KEY_MALTO_FOLYOSITASIDO))},
      {data: (parseFloat(process.env.REACT_APP_KEY_MALTO_FOLYOSITASIDO_NEG))},
      {data: (parseFloat(process.env.REACT_APP_KEY_MALTO_FOLYOSITASIDO_POS))},
      {data: (parseFloat(process.env.REACT_APP_KEY_MALTO_DEXTHO_SULY))},
      {data: (parseFloat(process.env.REACT_APP_KEY_MALTO_DEXTHO))},
      {data: (parseFloat(process.env.REACT_APP_KEY_MALTO_DEXTHO_NEG))},
      {data: (parseFloat(process.env.REACT_APP_KEY_MALTO_DEXTHO_POS))},
      {data: (parseFloat(process.env.REACT_APP_KEY_MALTO_DEXTIDO_SULY))},
      {data: (parseFloat(process.env.REACT_APP_KEY_MALTO_DEXTIDO))},
      {data: (parseFloat(process.env.REACT_APP_KEY_MALTO_DEXTIDO_NEG))},
      {data: (parseFloat(process.env.REACT_APP_KEY_MALTO_DEXTIDO_POS))},
    ])
}

    const handleReset = (e) => {
      e.preventDefault()
      if(maltoBuza){
      setKemenyitoSzarazanyag(parseFloat(process.env.REACT_APP_KEY_MALTO_KEMENYITOSZARAZANYAG))
      setKemenyitoHo(parseFloat(process.env.REACT_APP_KEY_MALTO_KEMENYITOHO))
      setHokezelesHo(parseFloat(process.env.REACT_APP_KEY_MALTO_HOKEZELESHO))
      setHokezelesIdo(parseFloat(process.env.REACT_APP_KEY_MALTO_HOKEZELESIDO))
      setFolyositasHo(parseFloat(process.env.REACT_APP_KEY_MALTO_FOLYOSITASHO))
      setFolyositasIdo(parseFloat(process.env.REACT_APP_KEY_MALTO_FOLYOSITASIDO))
      setDextHo(parseFloat(process.env.REACT_APP_KEY_MALTO_DEXTHO))
      setDextIdo(parseFloat(process.env.REACT_APP_KEY_MALTO_DEXTIDO))
      props.resetBase()
     }else{
        setKukKemenyitoSzarazanyag(parseFloat(process.env.REACT_APP_KEY_MALTO_KEMENYITOSZARAZANYAG))
        setKukKemenyitoHo(parseFloat(process.env.REACT_APP_KEY_MALTO_KEMENYITOHO))
        setKukHokezelesHo(parseFloat(process.env.REACT_APP_KEY_MALTO_HOKEZELESHO))
        setKukHokezelesIdo(parseFloat(process.env.REACT_APP_KEY_MALTO_HOKEZELESIDO))
        setKukFolyositasHo(parseFloat(process.env.REACT_APP_KEY_MALTO_FOLYOSITASHO))
        setKukFolyositasIdo(parseFloat(process.env.REACT_APP_KEY_MALTO_FOLYOSITASIDO))
        setKukDextHo(parseFloat(process.env.REACT_APP_KEY_MALTO_DEXTHO))
        setKukDextIdo(parseFloat(process.env.REACT_APP_KEY_MALTO_DEXTIDO))
        props.resetBase()
      }
      setParams([])
      setSubmitted(false)
    }

    const createTitle = () => {
        
      if(maltoBuza){
        title = 'A búzából előállított maltodextrin enzimes gyártási technológiájának paraméterei'
      }else{
        title = 'A kukoricából előállított maltodextrin enzimes gyártási technológiájának paraméterei'
      }

    }

    return (
     <div className = 'params'>
       {createTitle()}
      <div className = 'title'>
        <h1>{title}</h1>
      </div>
      <div className = 'oneParam'>
        <h1>Keményítő szuszpenzió készítése - Csirizesítés</h1>
        <form onSubmit={handleSubmit} >
          <div className="form-cont">
            <div className="form-element">
              <div className = 'form-element-title'>
                <div className = 'form-element-title1'>
                  <h2>Szárazanyag tartalom érték [ % ] </h2>
                </div>
                <div className = 'form-element-title2'>
                  <h3>paraméter tartománya: 60 - 65% </h3>
                </div>
              </div>
              <div className = 'form-param'>
                <div>
                  <label htmlFor="kemenyitoSzarazanyag">
                    Szárazanyag tartalom:  
                  </label>
                  <input type="number" min="60" max="65" step="0.01" value = {maltoBuza ? kemenyitoSzarazanyag : kukKemenyitoSzarazanyag} name="kemenyitoSzarazanyag" id="email"
                   required onChange={(e) => {maltoBuza ? setKemenyitoSzarazanyag(e.target.value) : setKukKemenyitoSzarazanyag(e.target.value)}}></input>
                    %
                </div>
                <div className = 'form-range'>
                  60%  
                  <input type="range" min="60" max="65" step="0.01" value = {maltoBuza ? kemenyitoSzarazanyag : kukKemenyitoSzarazanyag} className="custom-range" id="customRange1" 
                  onChange={(e) => {maltoBuza ? setKemenyitoSzarazanyag(e.target.value) : setKukKemenyitoSzarazanyag(e.target.value)}}/>
                    65%
                </div>
              </div>
            </div>
            <div className="form-element">
              <div className = 'form-element-title'>
                <div className = 'form-element-title1'>
                  <h2>Hőmérséklet [ ˚C ] </h2>
                </div>
                <div className = 'form-element-title2'>
                  <h3>paraméter tartománya: 20 - 60 ˚C </h3>
                </div>
              </div>
              <div className = 'form-param'>
                <div>
                  <label htmlFor="kemenyitoHo">
                    Hőmérséklet:  
                  </label>
                  <input type="number" min="20" max="60" step="0.01" value = {maltoBuza ? kemenyitoHo : kukKemenyitoHo} name="kemenyitoHo" id="kemenyitoHo" 
                  required onChange={(e) => {maltoBuza ? setKemenyitoHo(e.target.value) : setKukKemenyitoHo(e.target.value)}}></input>
                    ˚C
                </div>
                <div className = 'form-range'>
                    20˚C  
                  <input type="range" min="20" max="60" step="0.01" value = {maltoBuza ? kemenyitoHo : kukKemenyitoHo} className="custom-range" id="customRange1" 
                  onChange={(e) => {maltoBuza ? setKemenyitoHo(e.target.value) : setKukKemenyitoHo(e.target.value)}}/>
                    60˚C
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
        <div className = 'oneParam'>
          <h1>Hőkezelés / hőfeltárás gőzzel</h1>
          <form onSubmit={handleSubmit} >
            <div className="form-cont">
              <div className="form-element">
                <div className = 'form-element-title'>
                  <div className = 'form-element-title1'>
                    <h2>Hőmérséklet [ ˚C ] </h2>
                  </div>
                  <div className = 'form-element-title2'>
                    <h3>paraméter tartománya: 135 - 145 ˚C </h3>
                  </div>
                </div>
                <div className = 'form-param'>
                  <div>
                    <label htmlFor="hokezelesHo">
                      Hőmérséklet:  
                    </label>
                    <input type="number" min="135" max="145" step="0.01" value = {maltoBuza ? hokezelesHo : kukHokezelesHo} name="hokezelesHo" id="hokezelesHo"
                     required onChange={(e) => {maltoBuza ? setHokezelesHo(e.target.value) : setKukHokezelesHo(e.target.value)}}></input>
                      ˚C
                  </div>
                  <div className = 'form-range'>
                    135˚C  
                    <input type="range" min="135" max="145" step="0.01" value = {maltoBuza ? hokezelesHo : kukHokezelesHo} className="custom-range" id="customRange1" 
                    onChange={(e) => {maltoBuza ? setHokezelesHo(e.target.value) : setKukHokezelesHo(e.target.value)}}/>
                    145˚C
                  </div>
                </div>
              </div>
              <div className="form-element">
                <div className = 'form-element-title'>
                  <div className = 'form-element-title1'>
                    <h2>Időtartam [ s ] </h2>
                  </div>
                  <div className = 'form-element-title2'>
                    <h3>paraméter tartománya: 12 - 18 s </h3>
                  </div>
                </div>
                <div className = 'form-param'>
                  <div>
                    <label htmlFor="hokezelesIdo">
                      Időtartam:  
                    </label>
                    <input type="number" min="12" max="18" step="0.01" value = {maltoBuza ? hokezelesIdo : kukHokezelesIdo} name="hokezelesIdo" id="email" 
                    required onChange={(e) => {maltoBuza ? setHokezelesIdo(e.target.value) : setKukHokezelesIdo(e.target.value)}}></input>
                      s
                  </div>
                  <div className = 'form-range'>
                    12s  
                    <input type="range" min="12" max="18" step="0.01" value = {maltoBuza ? hokezelesIdo : kukHokezelesIdo} className="custom-range" id="customRange1" 
                    onChange={(e) => {maltoBuza ? setHokezelesIdo(e.target.value) : setKukHokezelesIdo(e.target.value)}}/>
                    18s
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className = 'oneParam'>
          <h1>Folyósítás (magas hőmérsékletű hőkezelés) folytonos technológia</h1>
          <form onSubmit={handleSubmit} >
            <div className="form-cont">
              <div className="form-element">
                <div className = 'form-element-title'>
                  <div className = 'form-element-title1'>
                    <h2>Hőmérséklet [ ˚C ] </h2>
                  </div>
                  <div className = 'form-element-title2'>
                    <h3>paraméter tartománya: 100 - 120 ˚C </h3>
                  </div>
                </div>
                <div className = 'form-param'>
                  <div>
                    <label htmlFor="folyositasHo">
                      Hőmérséklet:  
                    </label>
                    <input type="number" min="100" max="120" step="0.01" value = {maltoBuza ? folyositasHo : kukFolyositasHo} name="folyositasHo" id="folyositasHo" 
                    required onChange={(e) => {maltoBuza ? setFolyositasHo(e.target.value) : setKukFolyositasHo(e.target.value)}}></input>
                      ˚C
                  </div>
                  <div className = 'form-range'>
                    100˚C  
                    <input type="range" min="100" max="120" step="0.01" value = {maltoBuza ? folyositasHo : kukFolyositasHo} className="custom-range" id="customRange1" 
                    onChange={(e) => {maltoBuza ? setFolyositasHo(e.target.value) : setKukFolyositasHo(e.target.value)}}/>
                    120˚C
                  </div>
                </div>
              </div>
              <div className="form-element">
                <div className = 'form-element-title'>
                  <div className = 'form-element-title1'>
                    <h2>Időtartam [ m ] </h2>
                  </div>
                  <div className = 'form-element-title2'>
                    <h3>paraméter tartománya: 20 - 30 min </h3>
                  </div>
                </div>
                <div className = 'form-param'>
                  <div>
                    <label htmlFor="folyositasIdo">
                      Időtartam:  
                    </label>
                    <input type="number" min="20" max="20" step="0.01" value = {maltoBuza ? folyositasIdo : kukFolyositasIdo} name="folyositasIdo" id="email" 
                    required onChange={(e) => {maltoBuza ? setFolyositasIdo(e.target.value) : setKukFolyositasIdo(e.target.value)}}></input>
                      s
                  </div>
                  <div className = 'form-range'>
                    20m  
                    <input type="range" min="20" max="30" step="0.01" value = {maltoBuza ? folyositasIdo : kukFolyositasIdo} className="custom-range" id="customRange1" 
                    onChange={(e) => {maltoBuza ? setFolyositasIdo(e.target.value) : setKukFolyositasIdo(e.target.value)}}/>
                    30m
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className = 'oneParam'>
          <h1>Dextrinizálás / Hidrolizálás</h1>
          <form onSubmit={handleSubmit} >
            <div className="form-cont">
              <div className="form-element">
                <div className = 'form-element-title'>
                  <div className = 'form-element-title1'>
                    <h2>Hőmérséklet [ ˚C ]  </h2>
                  </div>
                  <div className = 'form-element-title2'>
                    <h3>paraméter tartománya: 80 - 95 ˚C </h3>
                  </div>
                </div>
                <div className = 'form-param'>
                  <div>
                    <label htmlFor="dextHo">
                      Hőmérséklet:  
                    </label>
                    <input type="number" min="80" max="95" step="0.01" value = {maltoBuza ? dextHo : kukDextHo} name="dextHo" id="dextHo" 
                    required onChange={(e) => {maltoBuza ? setDextHo(e.target.value) : setKukDextHo(e.target.value)}}></input>
                      ˚C
                  </div>
                  <div className = 'form-range'>
                    80˚C  
                    <input type="range" min="80" max="95" step="0.01" value = {maltoBuza ? dextHo : kukDextHo} className="custom-range" id="customRange1" 
                    onChange={(e) =>  {maltoBuza ? setDextHo(e.target.value) : setKukDextHo(e.target.value)}}/>
                    95˚C
                  </div>
                </div>
              </div>
              <div className="form-element">
                <div className = 'form-element-title'>
                  <div className = 'form-element-title1'>
                    <h2>Időtartam [ m ]  </h2>
                  </div>
                  <div className = 'form-element-title2'>
                    <h3>paraméter tartománya: 120 - 150 min </h3>
                  </div>
                </div>
                <div className = 'form-param'>
                  <div>
                    <label htmlFor="dextIdo">
                      Időtartam:  
                    </label>
                    <input type="number" min="120" max="150" step="0.01" value = {maltoBuza ? dextIdo : kukDextIdo} name="dextIdo" id="email" 
                    required onChange={(e) =>  {maltoBuza ? setDextIdo(e.target.value) : setKukDextIdo(e.target.value)}}></input>
                      m
                  </div>
                  <div className = 'form-range'>
                    120m  
                    <input type="range" min="120" max="150" step="0.01" value = {maltoBuza ? dextIdo : kukDextIdo} className="custom-range" id="customRange1" 
                    onChange={(e) => {maltoBuza ? setDextIdo(e.target.value) : setKukDextIdo(e.target.value)}}/>
                    150m
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      <div className = 'buttons'>
        {submitted ? <button className = 'btn btn-warning btn-lg' onClick={(e) => handleReset(e)}>Új szimuláció</button> : <button className="btn btn-success btn-lg" onClick={(e) => handleSubmit(e)}>Szimuláció indítása</button>}
      </div>
    </div>
    );
      
  }

    
    export default MaltoBuzaParams;
    