import React, { useEffect, useState, useContext } from 'react';
import { appContext } from '../App'


let theAbsolute
let theRelative



const ResultGenerator = (props) => {

    //Context
    const [ 
      appContactList, setAppContactList, actualUser, setActualUser, actualName, setActualName, updaterId, setUpdaterId, eredmenyData, setEredmenyData, submitted, setSubmitted, maltoBuza, setMaltoBuza,
      kemenyitoSzarazanyag, setKemenyitoSzarazanyag, kemenyitoHo, setKemenyitoHo, hokezelesHo, setHokezelesHo, hokezelesIdo, setHokezelesIdo,
      folyositasHo, setFolyositasHo, folyositasIdo, setFolyositasIdo, dextHo, setDextHo, dextIdo, setDextIdo,
      kukKemenyitoSzarazanyag, setKukKemenyitoSzarazanyag, kukKemenyitoHo, setKukKemenyitoHo, kukHokezelesHo, setKukHokezelesHo, kukHokezelesIdo, setKukHokezelesIdo,
      kukFolyositasHo, setKukFolyositasHo, kukFolyositasIdo, setKukFolyositasIdo, kukDextHo, setKukDextHo, kukDextIdo, setKukDextIdo,
      neutralKemenyitoSzarazanyag, setNeutralKemenyitoSzarazanyag, neutralKemenyitoHo, setNeutralKemenyitoHo, neutralHokezelesHo, setNeutralHokezelesHo,
      neutralHokezelesIdo, setNeutralHokezelesIdo, neutralFolyositasIdo, setNeutralFolyositasIdo, neutralDextHo, setNeutralDextHo,
      neutralCukrositasHo, setNeutralCukrositasHo,
      results, setResults, params, setParams, 
      sulySzazalek, setSulySzazalek, szakadas, setSzakadas, szazalekEredmeny, setSzazalekEredmeny, energiaFelhasznalas, setEnergiaFelhasznalas, energiaHatekonysag, setEnergiaHatekonysag
     ] = useContext(appContext)

    useEffect(() => {
      createResult()
    }, [eredmenyData])
   
    const createResult = () => {
        let tempResults = []

        //maltoresults feltöltése a base és a paraméterek eltérései alapján
        for(let i = 0; i < props.eredmenyData.length; i++){
          //A resultbase-ban ki kell hagyni értékeket
          let ITERATOR
          (i == 0 ? ITERATOR = 1 : ITERATOR = ((i*4)+1))
          //A negatív és pozitív RELATÍV eltér, vizsgálni kell
          let POSNEG
          ((props.eredmenyData[i].data - (props.params[(ITERATOR)]).data) < 0 ? POSNEG = 1 : POSNEG = 2)
          doMath(props.eredmenyData[i].data, (props.params[(ITERATOR)].data), (props.params[(ITERATOR+POSNEG)]).data)
          let resultObject = {}
          resultObject.subScription = props.eredmenyData[i].subScription
          resultObject.param = props.eredmenyData[i].data
          resultObject.absolute = theAbsolute
          resultObject.relative = theRelative
          resultObject.suly = props.params[(ITERATOR-1)].data
          tempResults.push(resultObject)
        }
        setResults(tempResults)
        doSulySzazalek(tempResults)
      }

 
      const doMath = (eredmeny, base, relative) => {
        theAbsolute = (eredmeny - base)
        theRelative = theAbsolute / relative
      }

      const doSulySzazalek = (tempResults) => {
        
        let percents = 0
        for(let i = 0; i < tempResults.length; i++){
          percents = percents + (tempResults[i].relative * tempResults[i].suly)
        }
        let result = percents/50
        props.setSulySzazalek(result)
        props.validateSulySzazalek(result)
        props.validateEnergia(tempResults)
      }





  return (
    <div>

    </div>
  );
  
}

export default ResultGenerator;
