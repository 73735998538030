import React from 'react';
import axios from 'axios'



export function loadContacts(ref) {
  return new Promise((resolve, reject) => {
    const onError = error => reject(error);
    const onData = snap => resolve(snap.val());

    ref.on("value", onData, onError);
  });
};

export function saveContact(ref, user) {
  return new Promise((resolve, reject) => {
    const onError = error => reject('databaseop ',error);
    const onData = snap => resolve(user);

    ref.push(user);
  });
};

export function updateContact(ref, data) {
  return new Promise((resolve, reject) => {
    const onError = error => reject(error);
    const onData = snap => resolve(data);
    ref.update(data);
  });
};


export function findContact(ref, user) {
  return new Promise((resolve, reject) => {
    const onError = error => reject(error);
    const onData = snap => resolve(snap.val());

    var query = ref.orderByChild('email').equalTo(user.email); // the var email is the email you're looking for
    query.on('value', onData, onError)
  });
};

export function findContactByEmail(ref, email) {
  return new Promise((resolve, reject) => {
    const onError = error => reject(error);
    const onData = snap => resolve(snap.val());

    var query = ref.orderByChild('email').equalTo(email); // the var email is the email you're looking for
    query.on('value', onData, onError)
  });
};

export const pdfRead = async (url) => {
  const response = await fetch('https://homaly.herokuapp.com/tridevpdf/findAll')
  if(response.status !== 200){
    throw new Error('Fetch error')
  }
  const data = await response.json()
  return data
};

export const jsonRead = async (url) => {
  const response = await fetch('https://homaly.herokuapp.com/tridevjson/findAll')
  if(response.status !== 200){
    throw new Error('Fetch error')
  }
  const data = await response.json()
  return data
};

export const pdfSave = async (name, url) => {
  axios.post('https://homaly.herokuapp.com/tridevpdf/insert', {
    name : name,
    link : url,
  })
  .then(response => {
    console.log('PDF to HEROKU')
    return response
  })
  .catch( error => {
    console.log(error);
    return error
  });
};

export const jsonSave = async (name, url) => {
  axios.post('https://homaly.herokuapp.com/tridevjson/insert', {
    name : name,
    link : url,
  })
  .then(response => {
    console.log('JSON to HEROKU')
    return response
  })
  .catch( error => {
    console.log(error);
    return error
  });
};

export const pdfReadNeutral = async (url) => {
  const response = await fetch('https://homaly.herokuapp.com/tridevpdfneutral/findAll')
  if(response.status !== 200){
    throw new Error('Fetch error')
  }
  const data = await response.json()
  return data
};

export const jsonReadNeutral = async (url) => {
  const response = await fetch('https://homaly.herokuapp.com/tridevjsonneutral/findAll')
  if(response.status !== 200){
    throw new Error('Fetch error')
  }
  const data = await response.json()
  return data
};

export const pdfSaveNeutral = async (name, url) => {
  axios.post('https://homaly.herokuapp.com/tridevpdfneutral/insert', {
    name : name,
    link : url,
  })
  .then(response => {
    console.log('PDF to HEROKU')
    return response
  })
  .catch( error => {
    console.log(error);
    return error
  });
};

export const jsonSaveNeutral = async (name, url) => {
  axios.post('https://homaly.herokuapp.com/tridevjsonneutral/insert', {
    name : name,
    link : url,
  })
  .then(response => {
    console.log('JSON to HEROKU')
    return response
  })
  .catch( error => {
    console.log(error);
    return error
  });
};






function DatabaseOp(props) {

  
 
  return (
    <div >

    </div>
    );

  }


export default DatabaseOp;
