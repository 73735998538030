import React, { useEffect, useState, useContext, Fragment  } from 'react';
import '../App.css';
import { appContext } from '../App'
import ReactS3 from 'react-s3'
import { pdfSave, pdfRead, pdfSaveNeutral, pdfReadNeutral } from '../database/DatabaseOp'
require("dotenv").config()

function Uploader(props){


  
  //appContext
  const [ 
    appContactList, setAppContactList, actualUser, setActualUser, actualName, setActualName, updaterId, setUpdaterId, eredmenyData, setEredmenyData, submitted, setSubmitted, maltoBuza, setMaltoBuza,
    kemenyitoSzarazanyag, setKemenyitoSzarazanyag, kemenyitoHo, setKemenyitoHo, hokezelesHo, setHokezelesHo, hokezelesIdo, setHokezelesIdo,
    folyositasHo, setFolyositasHo, folyositasIdo, setFolyositasIdo, dextHo, setDextHo, dextIdo, setDextIdo,
    kukKemenyitoSzarazanyag, setKukKemenyitoSzarazanyag, kukKemenyitoHo, setKukKemenyitoHo, kukHokezelesHo, setKukHokezelesHo, kukHokezelesIdo, setKukHokezelesIdo,
    kukFolyositasHo, setKukFolyositasHo, kukFolyositasIdo, setKukFolyositasIdo, kukDextHo, setKukDextHo, kukDextIdo, setKukDextIdo,
    neutralKemenyitoSzarazanyag, setNeutralKemenyitoSzarazanyag, neutralKemenyitoHo, setNeutralKemenyitoHo, neutralHokezelesHo, setNeutralHokezelesHo,
    neutralHokezelesIdo, setNeutralHokezelesIdo, neutralFolyositasIdo, setNeutralFolyositasIdo, neutralDextHo, setNeutralDextHo,
    neutralCukrositasHo, setNeutralCukrositasHo,
    results, setResults, params, setParams, 
    sulySzazalek, setSulySzazalek, szakadas, setSzakadas, szazalekEredmeny, setSzazalekEredmeny, energiaFelhasznalas, setEnergiaFelhasznalas, energiaHatekonysag, setEnergiaHatekonysag,
    jsonList, setJsonList, pdfList, setPdfList, foundPdfList, setFoundPdfList, pdfListNeutral, setPdfListNeutral, jsonListNeutral, setJsonListNeutral, jsonTextListNeutral, setJsonTextListNeutral,
    popup, setPopup, popupVisibility, setPopupVisibility, popupMessage, setPopupMessage, jsonTextList, setJsonTextList
  ] = useContext(appContext)

  const [ file, setFile ] = useState(null)
  const [ fileName, setFileName ] = useState(null)

  let theBucket = props.pdfBucketName

  const s3Config = {
    bucketName: theBucket,
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESSKEYID,
    secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY,
  }

  useEffect(() => {

  }, [])

  const handleChangeUpload = (e) => { 
    if(e.target.files[0] === undefined){
      setPopupMessage('Hiba történt, kérem próbálja újra!')
      setPopup('popupShow')
      setPopupVisibility(true)
    }else{
      setFile(e.target.files[0])
      setFileName(e.target.files[0].name) 
    }
}
  
    const handleUpload = (e) => {
      e.preventDefault()
       ReactS3.uploadFile(file, s3Config)
      .then((data) =>{
        console.log('uploader data ', data)
        setPopupMessage('A(z) ' + data.key + ' nevű PDF feltöltve!')
        setPopup('popupShow')
        setPopupVisibility(true)
        savePdf(data.key, data.location)
      })
      .catch((err) => {
        console.log('handleupload catch', err)
      })
    }

      //feltöltött file adatai az adatbázisba
      const savePdf = (name, url) => {
        if(theBucket.toString() == 'tridevpdf'){
          console.log('uploader theBucket ', theBucket)
          pdfSave(name, url)
          .then(data => 
            console.log('upload resolved '),
            pdfRead()
              .then((value) => {
                setPdfList(value)
              }).catch((error) => {
                console.log('app error', error)
              })
          ).catch(err => console.log('upload rejected ', err))
        }else{
          console.log('uploaderNEUTRAL theBucket ', theBucket)
          pdfSaveNeutral(name, url)
          .then(data => 
            console.log('upload resolved '),
            pdfReadNeutral()
              .then((value) => {
                setPdfListNeutral(value)
              }).catch((error) => {
                console.log('app error', error)
              })
          ).catch(err => console.log('upload rejected ', err))
        }
        
      }

    const handleShow = (e) => { 
      props.history.push('showPdf');
    }
  
    return(
          <div className = 'pdfUpload'>
            <h3>PDF feltöltés</h3>
              <Fragment>
              <div className="input-group mb-3">
                <div className="custom-file">
                  <input type="file" className="custom-file-input" id="inputGroupFile02" onChange = {handleChangeUpload} ></input>
                    <label className="custom-file-label" htmlFor="inputGroupFile02">{fileName}</label>
                </div>
                <div className="input-group-append">
                  <span onClick={(e) => handleUpload(e)} className="input-group-text" id="">Upload</span>
                </div>
              </div>
              
              </Fragment>
      
          </div>
    
  
    )
}

export default Uploader