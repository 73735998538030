import React, { useEffect, useState, useContext  } from 'react';
import { Link } from 'react-router-dom';
import { appContext } from '../App'
import '../App.css';
import fire from '../database/Firebase'
import { saveContact } from '../database/DatabaseOp'
import register from '../assets/register.jpg'


let ref = fire.database().ref('contacts')
let emailObject = {}

const RegisterScreen = (props) => {

  //appContext
    const [ 
      appContactList, setAppContactList, actualUser, setActualUser, actualName, setActualName, updaterId, setUpdaterId, eredmenyData, setEredmenyData, submitted, setSubmitted, maltoBuza, setMaltoBuza,
      kemenyitoSzarazanyag, setKemenyitoSzarazanyag, kemenyitoHo, setKemenyitoHo, hokezelesHo, setHokezelesHo, hokezelesIdo, setHokezelesIdo,
      folyositasHo, setFolyositasHo, folyositasIdo, setFolyositasIdo, dextHo, setDextHo, dextIdo, setDextIdo,
      kukKemenyitoSzarazanyag, setKukKemenyitoSzarazanyag, kukKemenyitoHo, setKukKemenyitoHo, kukHokezelesHo, setKukHokezelesHo, kukHokezelesIdo, setKukHokezelesIdo,
      kukFolyositasHo, setKukFolyositasHo, kukFolyositasIdo, setKukFolyositasIdo, kukDextHo, setKukDextHo, kukDextIdo, setKukDextIdo,
      neutralKemenyitoSzarazanyag, setNeutralKemenyitoSzarazanyag, neutralKemenyitoHo, setNeutralKemenyitoHo, neutralHokezelesHo, setNeutralHokezelesHo,
      neutralHokezelesIdo, setNeutralHokezelesIdo, neutralFolyositasIdo, setNeutralFolyositasIdo, neutralDextHo, setNeutralDextHo,
      neutralCukrositasHo, setNeutralCukrositasHo,
      results, setResults, params, setParams, 
      sulySzazalek, setSulySzazalek, szakadas, setSzakadas, szazalekEredmeny, setSzazalekEredmeny, energiaFelhasznalas, setEnergiaFelhasznalas, energiaHatekonysag, setEnergiaHatekonysag,
      jsonList, setJsonList, pdfList, setPdfList, foundPdfList, setFoundPdfList, pdfListNeutral, setPdfListNeutral, jsonListNeutral, setJsonListNeutral, jsonTextListNeutral, setJsonTextListNeutral,
      popup, setPopup, popupVisibility, setPopupVisibility, popupMessage, setPopupMessage, jsonTextList, setJsonTextList
     ] = useContext(appContext)
    
    const [name, setName] = useState('');
    const [repeat, setRepeat] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const sendRegMail = fire.functions().httpsCallable('sendRegEmail')


    useEffect(() => {
      clearErrors()
      clearInputs()
    }, [repeat])

    const clearErrors = () => {
      setNameError('')
      setEmailError('')
      setPasswordError('')
    }

    const clearInputs = () => {
      setName('')
      setEmail('')
      setPassword('')
    }

    const handleEmail = (emailObject) => {
      console.log('register handleemail ', emailObject)
      sendRegMail({email: emailObject.email, title: emailObject.title, text: emailObject.text})
      .then(result => console.log(result.data))
      .catch((error) => { console.log(error)});
    }


    const saveUser = (value) => {     
        let regUser = {}
        regUser.name = name
        regUser.email = email
        regUser.password = password
        regUser.role = 'regisztrált'
        saveContact(ref, regUser)
        .then((value) => { console.log('register savecontact', value) })
        .catch((error) => { console.log('app error', error) });
        //regisztrációs email usernek
        emailObject.email = regUser.email
        emailObject.title = 'Sikeres regisztráció a tridevinnovacio.hu oldalon'
        emailObject.text = 'Ön regisztrált a tridevinnovacio.hu oldalon. Regisztrációjának adminisztrátori jóváhagyásáról e-mailben fogjuk értesíteni.'
        handleEmail(emailObject)
        //regisztrációs email adminnak
        emailObject.email = 'szg@onlineprojects.hu'
        emailObject.title = 'Tridevinnovacio.hu - új felhasználó'
        emailObject.text = 'Új regisztráció a tridevinnovacio.hu oldalon! Adminisztrátori jóváhagyás szükséges!'
        handleEmail(emailObject)
    }

    const handleRegister = (e) => {
      if(e){
        e.preventDefault()
      }
      
      clearErrors()
      clearInputs()
      fire
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then((value) => { 
          {console.log('handleRegister then value ', value)}
          saveUser(value)
          callPopup()
          const { history } = props;
          history.push('login'); })
        .catch((err) => {
          {console.log('handleRegister errorcode ', err.code)}
          {console.log('handleRegister errormessage ', err.message)}
          switch(err.code){
            case 'auth/email-already-in-use' :
              setEmailError('Ez az e-mail már regisztált a rendszerünkbe, kérem adjon meg egy másikat!')
              setRepeat('auth/email-already-in-use')
              break
            case 'auth/invalid-email' :
              setEmailError('Nem megfelelő formátumú e-mail')
              setRepeat('Nem megfelelő formátumú e-mail')
              break
            case 'auth/weak-password' :
              setPasswordError('A jelszónak legalább 6 karakternek kell lennie!')
              setRepeat('auth/weak-password')
              break
          }
        }) 
    }

    const callPopup = () => {
      setPopupMessage('Köszönjük, hogy regisztrált a tridevinnovacio.hu oldalon. Miután az adminisztrátor jóváhagyta a regisztrációját minden menüpontot el fog tudni érni. A jóváhagyásról e-mailt fog kapni.')
      setPopup('popupShow')
      setPopupVisibility(true)
    }

    const hidePopup = (e) => {
      e.preventDefault()
      console.log('hidePopup ')
      setPopup(false)
    }


    return(
      <div>
        <div className ='loginForm'>
        <form >
        <ul className="form-container">
          <li>
            <h2>Regisztráljon!</h2>
          </li>
          <li>
            <label htmlFor="name">
              Név
            </label>
            <input type="text" name="name" id="name" required onChange={(e) => setName(e.target.value)}>
            </input>
            <p className = 'errorMsg'>{nameError}</p>
          </li>
          <li>
            <label htmlFor="email">
              Email
            </label>
            <input type="email" name="email" id="email" required onChange={(e) => setEmail(e.target.value)}>
            </input>
            <p className = 'errorMsg'>{emailError}</p>
          </li>
          <li>
            <label htmlFor="password">Password</label>
            <input type="password" id="password" name="password" required onChange={(e) => setPassword(e.target.value)}>
            </input>
            <p className = 'errorMsg'>{passwordError}</p>
          </li>
          <li>
            <button type="button" onClick={(e) => handleRegister(e.target.value)} className="button primary">Regisztráció</button>
          </li>
          <li>
            Már van fiókja?
          </li>
          <li>
            <Link to= "login"  className="button secondary text-center" >Jelentkezzen be!</Link>
          </li>
        </ul>
        </form>
        </div>
        <img className = 'appContainer'  src={register} alt="home1"></img>
      </div>
    )
}

export default RegisterScreen