import React, { useState, useEffect, useContext } from 'react'
import '../../App.css';
import buttonBelepes from '../../assets/buttonBelep.png'




function ButtonBelepes(props) {

 
  return (
    <div >
      <img className = 'buttonBelepes' id = 'buttonBe' src = {buttonBelepes} alt = 'Belepes' onClick={(e) => props.loginClick()} ></img>
    </div>
    );

  }


export default ButtonBelepes;
