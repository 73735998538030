import React, { useEffect, useState, useContext  } from 'react';
import choose from '../assets/choose.jpg'
import '../App.css';
import Header from '../components/Header'
import { appContext } from '../App'
import fire from '../database/Firebase'
import ButtonMaltoBuza from '../components/buttons/ButtonMaltoBuza'
import ButtonMaltoKuk from '../components/buttons/ButtonMaltoKuk'
import ButtonNeutral from '../components/buttons/ButtonNeutral'
import pop from '../assets/popup.png'
import { motion, AnimatePresence } from 'framer-motion'

let message = ''

function ChooseScreen(props){

    //appContext
    const [ 
      appContactList, setAppContactList, actualUser, setActualUser, actualName, setActualName, updaterId, setUpdaterId, eredmenyData, setEredmenyData, submitted, setSubmitted, maltoBuza, setMaltoBuza,
        kemenyitoSzarazanyag, setKemenyitoSzarazanyag, kemenyitoHo, setKemenyitoHo, hokezelesHo, setHokezelesHo, hokezelesIdo, setHokezelesIdo,
        folyositasHo, setFolyositasHo, folyositasIdo, setFolyositasIdo, dextHo, setDextHo, dextIdo, setDextIdo,
        kukKemenyitoSzarazanyag, setKukKemenyitoSzarazanyag, kukKemenyitoHo, setKukKemenyitoHo, kukHokezelesHo, setKukHokezelesHo, kukHokezelesIdo, setKukHokezelesIdo,
        kukFolyositasHo, setKukFolyositasHo, kukFolyositasIdo, setKukFolyositasIdo, kukDextHo, setKukDextHo, kukDextIdo, setKukDextIdo,
        neutralKemenyitoSzarazanyag, setNeutralKemenyitoSzarazanyag, neutralKemenyitoHo, setNeutralKemenyitoHo, neutralHokezelesHo, setNeutralHokezelesHo,
        neutralHokezelesIdo, setNeutralHokezelesIdo, neutralFolyositasIdo, setNeutralFolyositasIdo, neutralDextHo, setNeutralDextHo,
        neutralCukrositasHo, setNeutralCukrositasHo,
        results, setResults, params, setParams, 
        sulySzazalek, setSulySzazalek, szakadas, setSzakadas, szazalekEredmeny, setSzazalekEredmeny, energiaFelhasznalas, setEnergiaFelhasznalas, energiaHatekonysag, setEnergiaHatekonysag,
        jsonList, setJsonList, pdfList, setPdfList, foundPdfList, setFoundPdfList, pdfListNeutral, setPdfListNeutral, jsonListNeutral, setJsonListNeutral, jsonTextListNeutral, setJsonTextListNeutral,
        popup, setPopup, popupVisibility, setPopupVisibility, popupMessage, setPopupMessage, jsonTextList, setJsonTextList
     ] = useContext(appContext)

    const handleLogout = (value) => {
        fire.auth().signOut().then(function() {
          }, function(error) {
            console.error('Sign Out Error', error);
          });
        const { history } = props;
        history.push('home');
      }

      const handleHome = (value) => {
        const { history } = props;
        history.push('choose');
      }

    const clickMaltoBuza = () => {
      setMaltoBuza(true)
      if(actualUser.role != undefined){
        if(actualUser.role != 'admin' && actualUser.role != 'Jóváhagyott'){
          callPopup()
        }else{
          setPopup(false)
          const { history } = props;
          history.push('maltoBuza');
        }
      }else{
        message = 'Kérem jelentkezzen be!'
        setPopup(true)
      }

    }

    const clickMaltoKuk = () => {
      setMaltoBuza(false)
      if(actualUser.role != undefined){
        if(actualUser.role != 'admin' && actualUser.role != 'Jóváhagyott'){
          message = 'Az adminisztrátor még nem hagyta jóvá a regisztrációját'
          setPopup(true)
        }else{
          setPopup(false)
          const { history } = props;
          history.push('maltoBuza');
        }
      }else{
        message = 'Kérem jelentkezzen be!'
        setPopup(true)
      }
    }

    const clickNeutral = () => {
      if(actualUser.role != undefined){
        if(actualUser.role != 'admin' && actualUser.role != 'Jóváhagyott'){
          message = 'Az adminisztrátor még nem hagyta jóvá a regisztrációját'
          setPopup(true)
        }else{
          setPopup(false)
          const { history } = props;
          history.push('neutral');
        }
      }else{
        message = 'Kérem jelentkezzen be!'
        setPopup(true)
      }
    }

    const callPopup = () => {
      setPopupMessage('Az adminisztrátor még nem hagyta jóvá a regisztrációját. A jóváhagyásról e-mailt fog kapni.')
      setPopup('popupShow')
      setPopupVisibility(true)
    }

    const hidePopup = (e) => {
      e.preventDefault()
      console.log('hidePopup ')
      setPopup(false)
    }

    return(
      <div >
        {console.log('choose actualuserrole ', actualUser.role)}
        {actualName == undefined ? '' : <Header handleLogout = {handleLogout} handleHome = {handleHome} userName = {actualName}></Header>}
        <div className = 'chooseButtons'>
          <ButtonMaltoBuza clickMaltoBuza = {clickMaltoBuza} ></ButtonMaltoBuza>
          <ButtonNeutral clickNeutral = {clickNeutral}></ButtonNeutral>
          <ButtonMaltoKuk clickMaltoKuk = {clickMaltoKuk}></ButtonMaltoKuk>
        </div>
          <img className = 'appContainer'  src={choose} alt="choose"></img>
        </div>
    )
}

export default ChooseScreen