import React, { useEffect, useRef, useContext  } from 'react';
import '../App.css';
import { appContext } from '../App'
import PDFGenerator from '../util/PDFGenerator'

import PDFGeneratorNeutral from '../util/PDFGeneratorNeutral'


let chooser

function PdfScreen(props){

    //appContext
    const [ 
        appContactList, setAppContactList, actualUser, setActualUser, actualName, setActualName, updaterId, setUpdaterId, eredmenyData, setEredmenyData, submitted, setSubmitted, maltoBuza, setMaltoBuza,
        kemenyitoSzarazanyag, setKemenyitoSzarazanyag, kemenyitoHo, setKemenyitoHo, hokezelesHo, setHokezelesHo, hokezelesIdo, setHokezelesIdo,
        folyositasHo, setFolyositasHo, folyositasIdo, setFolyositasIdo, dextHo, setDextHo, dextIdo, setDextIdo,
        kukKemenyitoSzarazanyag, setKukKemenyitoSzarazanyag, kukKemenyitoHo, setKukKemenyitoHo, kukHokezelesHo, setKukHokezelesHo, kukHokezelesIdo, setKukHokezelesIdo,
        kukFolyositasHo, setKukFolyositasHo, kukFolyositasIdo, setKukFolyositasIdo, kukDextHo, setKukDextHo, kukDextIdo, setKukDextIdo,
        neutralKemenyitoSzarazanyag, setNeutralKemenyitoSzarazanyag, neutralKemenyitoHo, setNeutralKemenyitoHo, neutralHokezelesHo, setNeutralHokezelesHo,
        neutralHokezelesIdo, setNeutralHokezelesIdo, neutralFolyositasIdo, setNeutralFolyositasIdo, neutralDextHo, setNeutralDextHo,
        neutralCukrositasHo, setNeutralCukrositasHo,
        results, setResults
     ] = useContext(appContext)

    
    return(
        <div >
            {(eredmenyData[0].name == 'kemenyitoSzarazanyag' || eredmenyData[0].name == 'kukKemenyitoSzarazanyag' ) ? chooser = true : chooser = false}
            {chooser ? <PDFGenerator></PDFGenerator> : <PDFGeneratorNeutral ></PDFGeneratorNeutral>}
        </div>
    )
}

export default PdfScreen