import React, { useState, useEffect, useContext } from 'react'
import '../../App.css';
import buttonMaltoKuk from '../../assets/chooseMaltoKuk.png'


function ButtonMaltoKuk(props) {

  return (
    <div >
      <img id = 'buttonBe' src = {buttonMaltoKuk} alt = 'MaltoBuza' onClick={(e) => props.clickMaltoKuk()} ></img>
    </div>
    );
  }

export default ButtonMaltoKuk;
