import React, { useEffect, useState, useContext, Fragment  } from 'react';
import tudasbazis from '../assets/tudasbazis.jpg'
import pdfIcon from '../assets/pdf.png'

import '../App.css';
import Header from '../components/Header'
import fire from '../database/Firebase'
import { appContext } from '../App'
import Uploader from '../util/Uploader'
import UploaderJson from '../util/UploaderJson'
require("dotenv").config()

function MaltoTudasbazis(props){

    //appContext
    const [ 
      appContactList, setAppContactList, actualUser, setActualUser, actualName, setActualName, updaterId, setUpdaterId, eredmenyData, setEredmenyData, submitted, setSubmitted, maltoBuza, setMaltoBuza,
      kemenyitoSzarazanyag, setKemenyitoSzarazanyag, kemenyitoHo, setKemenyitoHo, hokezelesHo, setHokezelesHo, hokezelesIdo, setHokezelesIdo,
      folyositasHo, setFolyositasHo, folyositasIdo, setFolyositasIdo, dextHo, setDextHo, dextIdo, setDextIdo,
      kukKemenyitoSzarazanyag, setKukKemenyitoSzarazanyag, kukKemenyitoHo, setKukKemenyitoHo, kukHokezelesHo, setKukHokezelesHo, kukHokezelesIdo, setKukHokezelesIdo,
      kukFolyositasHo, setKukFolyositasHo, kukFolyositasIdo, setKukFolyositasIdo, kukDextHo, setKukDextHo, kukDextIdo, setKukDextIdo,
      neutralKemenyitoSzarazanyag, setNeutralKemenyitoSzarazanyag, neutralKemenyitoHo, setNeutralKemenyitoHo, neutralHokezelesHo, setNeutralHokezelesHo,
      neutralHokezelesIdo, setNeutralHokezelesIdo, neutralFolyositasIdo, setNeutralFolyositasIdo, neutralDextHo, setNeutralDextHo,
      neutralCukrositasHo, setNeutralCukrositasHo,
      results, setResults, params, setParams, 
      sulySzazalek, setSulySzazalek, szakadas, setSzakadas, szazalekEredmeny, setSzazalekEredmeny, energiaFelhasznalas, setEnergiaFelhasznalas, energiaHatekonysag, setEnergiaHatekonysag,
      jsonList, setJsonList, pdfList, setPdfList, foundPdfList, setFoundPdfList, pdfListNeutral, setPdfListNeutral, jsonListNeutral, setJsonListNeutral, jsonTextListNeutral, setJsonTextListNeutral,
      popup, setPopup, popupVisibility, setPopupVisibility, popupMessage, setPopupMessage, jsonTextList, setJsonTextList
     ] = useContext(appContext)


    const { history } = props;
    const pdfBucketName = process.env.REACT_APP_MALTOPDFBUCKETNAME
    const jsonBucketName = process.env.REACT_APP_MALTOJSONBUCKETNAME


    const [ searchWord, setSearchWord ] = useState('')

    const handleLogout = (value) => {
      fire.auth().signOut().then(function() {
        }, function(error) {
          console.error('Sign Out Error', error);
        });
      const { history } = props;
      history.push('home');
    }

    const handleHome = (value) => { const { history } = props; history.push('choose'); }

    const clickPdf = (pdfName, pdfLink) => {
      console.log('maltotudasa actualName ', actualName)
      {Object.keys(actualName).length === 0 ? callPopup() : (window.open('https://homaly.herokuapp.com/pdf/showPdf?url=' + pdfLink, '_blank'))}
      
    }

    const callPopup = () => {
      setPopupMessage('Kérem jelentkezzen be!')
      setPopup('popupShow')
      setPopupVisibility(true)
    }

    const handleChangeSearch = (e) => { 
      setSearchWord(e.target.value)

  }
  
    const handleSearch = (e) => {
        setSearchWord('')
        search(searchWord)
      
    }

    const search = (searchWord) => {
      setFoundPdfList([])
      Object.keys(jsonTextList).map(id =>{
        if(jsonTextList[id].text[0].toString().toLowerCase().includes(searchWord)){
          setFoundPdfList(foundPdfList => [...foundPdfList, pdfList[id]])
        }
 
      })
  }



    return(
        <div className = 'tudasbazisContainer'>
          {console.log('maltotudas actualName ', actualName)}
          {actualName == undefined ? '' : <Header handleLogout = {handleLogout} handleHome = {handleHome} userName = {actualName}></Header>}
          {Object.keys(actualName).length === 0 ? callPopup() : (
            <>
              <div className = 'uploadContainer' >
                  <Uploader history = {history} pdfBucketName = {pdfBucketName}></Uploader>
                  {actualUser.email === 'szempontstudio@gmail.com' ? (
                    <UploaderJson history = {history} jsonBucketName = {jsonBucketName} ></UploaderJson>
                  ) : ''}
              </div>
              <div className = 'pdfListContainer'>
                <h1>Tudásbázis rekordok</h1>
                <div className = 'contentTable'>
                  {Object.keys(pdfList).map(id =>{
                    return <div key = {id} className = 'contentRow'>
                      <tr key = {id}>
                        <td id = 'contentPic'><img id = 'contentPicture' src = {pdfIcon}></img></td>
                        <td ><h2 onClick={(e) => clickPdf(pdfList[id].name, pdfList[id].link)} >{pdfList[id].name}</h2></td>
                      </tr>
                    </div>
                  })}
                </div>
              </div>
              <div className = 'searchContainer'>
                <h3>Keresés</h3>
                <div className = 'searchBar'>
                  <Fragment>
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="inputGroup-sizing-default" onClick={(e) => handleSearch()}>Keresés</span>
                      </div>
                      <input type="text" class="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" onChange = {handleChangeSearch}></input>
                      </div>
                    </Fragment>
                  </div>
                <h1>Kereső-kifejezést tartalmazó tudásbázis rekordok</h1>
                <div className = 'contentTable'>
                  {foundPdfList.length > 0 ? (
                    <div>
                      {Object.keys(foundPdfList).map(id =>{
                        return <div key = {id} className = 'contentRow'>
                          <tr key = {id}>
                            <td id = 'contentPic'><img id = 'contentPicture' src = {pdfIcon}></img></td>
                            <td ><h2 onClick={(e) => clickPdf(foundPdfList[id].name, foundPdfList[id].link)} >{foundPdfList[id].name}</h2></td>
                          </tr>
                        </div>
                      })}
                    </div>  
                  ) : ''}
                </div>
              
              </div>
              <div className = 'imgContainer' >
              <img   src={tudasbazis} alt="home1"></img>
          </div>
            </>
          )}
        </div>
    )
}

export default MaltoTudasbazis