import React, { useEffect, useState, useContext, createRef } from 'react';
import './MaltoBuza.css';
import { appContext } from '../../App'
import { motion, AnimatePresence } from 'framer-motion'
import folyamatabra2 from '../../assets/folyamatabra2.jpg'
import folyamatabraUres from '../../assets/folyamatabraUres.jpg'
import fMaltoBuza1 from '../../assets/fMaltoBuza1.png'
import fMaltoBuza2 from '../../assets/fMaltoBuza2.png'
import fMaltoBuza3 from '../../assets/fMaltoBuza3.png'
import fMaltoBuza4 from '../../assets/fMaltoBuza4.png'
import fMaltoBuza5 from '../../assets/fMaltoBuza5.png'
import fMaltoBuza6 from '../../assets/fMaltoBuza6.png'
import fMaltoBuza7 from '../../assets/fMaltoBuza7.png'
import fMaltoBuza8 from '../../assets/fMaltoBuza8.png'
import fMaltoBuza9 from '../../assets/fMaltoBuza9.png'
import fMaltoBuza10 from '../../assets/fMaltoBuza10.png'
import fMaltoBuza11 from '../../assets/fMaltoBuza11.png'
import fMaltoBuza12 from '../../assets/fMaltoBuza12.png'
import fMaltoBuza13 from '../../assets/fMaltoBuza13.png'
import fMaltoBuza14 from '../../assets/fMaltoBuza14.png'
import fMaltoBuza15 from '../../assets/fMaltoBuza15.png'
import fMaltoBuza16 from '../../assets/fMaltoBuza16.png'
import fMaltoBuza17 from '../../assets/fMaltoBuza17.png'
import fMaltoBuza18 from '../../assets/fMaltoBuza18.png'
import fMaltoBuza19 from '../../assets/fMaltoBuza19.png'
import fMaltoBuza20 from '../../assets/fMaltoBuza20.png'
import fMaltoBuza21 from '../../assets/fMaltoBuza21.png'
import fMaltoBuza22 from '../../assets/fMaltoBuza22.png'
import fMaltoBuza23 from '../../assets/fMaltoBuza23.png'
import fMaltoBuza24 from '../../assets/fMaltoBuza24.png'
import fMaltoBuza25 from '../../assets/fMaltoBuza25.png'
import fMaltoBuza26 from '../../assets/fMaltoBuza26.png'
import fMaltoBuza27 from '../../assets/fMaltoBuza27.png'
import fMaltoBuza28 from '../../assets/fMaltoBuza28.png'
import fMaltoBuza29 from '../../assets/fMaltoBuza29.png'
import fMaltoBuza30 from '../../assets/fMaltoBuza30.png'
import fMaltoBuza31 from '../../assets/fMaltoBuza31.png'
import fMaltoBuza32 from '../../assets/fMaltoBuza32.png'
import fMaltoBuza33 from '../../assets/fMaltoBuza33.png'
import fMaltoBuza34 from '../../assets/fMaltoBuza34.png'
import fMaltoBuza35 from '../../assets/fMaltoBuza35.png'
import fMaltoBuza36 from '../../assets/fMaltoBuza36.png'
import fMaltoBuza37 from '../../assets/fMaltoBuza37.png'
import fMaltoBuza38 from '../../assets/fMaltoBuza38.png'
import fMaltoBuza39 from '../../assets/fMaltoBuza39.png'
import fMaltoBuza40 from '../../assets/fMaltoBuza40.png'
import fMaltoBuza41 from '../../assets/fMaltoBuza41.png'
import fMaltoBuza42 from '../../assets/fMaltoBuza42.png'
import fMaltoBuza43 from '../../assets/fMaltoBuza43.png'
import rightArrow from '../../assets/rightArrow.png'
import rightArrowHover from '../../assets/rightArrowHover.png'
import leftArrow from '../../assets/leftArrow.png'
import leftArrowHover from '../../assets/leftArrowHover.png'
import downArrow from '../../assets/downArrow.png'
import downArrowHover from '../../assets/downArrowHover.png'
import upArrow from '../../assets/upArrow.png'
import upArrowHover from '../../assets/upArrowHover.png'
import swapArrow from '../../assets/swapArrow.png'
import swapArrowHover from '../../assets/swapArrowHover.png'
import tudasbazis from '../../assets/tudasbazis.png'
import { maltoBuzaText } from './MaltoBuzaText'


let title

const MaltoBuzaFolyamat2 = (props) => {

        //Context
    const [ 
        appContactList, setAppContactList, actualUser, setActualUser, actualName, setActualName, updaterId, setUpdaterId, eredmenyData, setEredmenyData, submitted, setSubmitted, maltoBuza, setMaltoBuza,
        kemenyitoSzarazanyag, setKemenyitoSzarazanyag, kemenyitoHo, setKemenyitoHo, hokezelesHo, setHokezelesHo, hokezelesIdo, setHokezelesIdo,
        folyositasHo, setFolyositasHo, folyositasIdo, setFolyositasIdo, dextHo, setDextHo, dextIdo, setDextIdo,
        kukKemenyitoSzarazanyag, setKukKemenyitoSzarazanyag, kukKemenyitoHo, setKukKemenyitoHo, kukHokezelesHo, setKukHokezelesHo, kukHokezelesIdo, setKukHokezelesIdo,
        kukFolyositasHo, setKukFolyositasHo, kukFolyositasIdo, setKukFolyositasIdo, kukDextHo, setKukDextHo, kukDextIdo, setKukDextIdo,
        neutralKemenyitoSzarazanyag, setNeutralKemenyitoSzarazanyag, neutralKemenyitoHo, setNeutralKemenyitoHo, neutralHokezelesHo, setNeutralHokezelesHo,
        neutralHokezelesIdo, setNeutralHokezelesIdo, neutralFolyositasIdo, setNeutralFolyositasIdo, neutralDextHo, setNeutralDextHo,
        neutralCukrositasHo, setNeutralCukrositasHo,
        results, setResults, params, setParams, 
        sulySzazalek, setSulySzazalek, szakadas, setSzakadas, szazalekEredmeny, setSzazalekEredmeny, energiaFelhasznalas, setEnergiaFelhasznalas, energiaHatekonysag, setEnergiaHatekonysag,
        jsonList, setJsonList, pdfList, setPdfList, foundPdfList, setFoundPdfList, pdfListNeutral, setPdfListNeutral, jsonListNeutral, setJsonListNeutral, jsonTextListNeutral, setJsonTextListNeutral,
        popup, setPopup, popupVisibility, setPopupVisibility, popupMessage, setPopupMessage, jsonTextList, setJsonTextList
     ] = useContext(appContext)


     const anim1 = { hidden: { scale: 3, x: '200rem', y: '8.5rem' }, visible: { x: '-6rem', y: '8.5rem', scale: .45,  transition: {type: "spring", stiffness: 70,  delay: 0.5, duration: 1 } }, hover: { scale: 0.75} }
    
    const anim2 = { hidden: { scale: 3, x: '200rem', y: '-1rem' }, visible: { x: '-6rem', y: '-1rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 0.6, duration: 1 } }, hover: { scale: 0.75} }

    const anim3 = { hidden: { scale: 3, x: '200rem', y: '8.5rem' }, visible: { x: '7rem', y: '8.5rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 0.7, duration: 1 } }, hover: { scale: 0.75} }
    
    const anim4 = { hidden: { scale: 3, x: '200rem', y: '-1rem' }, visible: { x: '7rem', y: '-1rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 0.8, duration: 1 } }, hover: { scale: 0.75} }
    
    const anim5 = { hidden: { scale: 3, x: '200rem', y: '-1rem' }, visible: { x: '20rem', y: '-1rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 0.9, duration: 1 } }, hover: { scale: 0.75} }
    
    const anim6 = { hidden: { scale: 3, x: '200rem', y: '18rem' }, visible: { x: '7rem', y: '18rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 1.0, duration: 1 } }, hover: { scale: 0.75} }

    const anim7 = { hidden: { scale: 3, x: '200rem', y: '18rem' }, visible: { x: '-6rem', y: '18rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 1.1, duration: 1 } }, hover: { scale: 0.75} }

    const anim8 = { hidden: { scale: 3, x: '200rem', y: '8.5rem' }, visible: { x: '20rem', y: '8.5rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 1.2, duration: 1 } }, hover: { scale: 0.75} }
      
    const anim9= { hidden: { scale: 3, x: '200rem', y: '18rem' }, visible: { x: '20rem', y: '18rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 1.3, duration: 1 } }, hover: { scale: 0.75} }
      
    const anim10= { hidden: { scale: 3, x: '200rem', y: '8.5rem' }, visible: { x: '33rem', y: '8.5rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 1.4, duration: 1 } }, hover: { scale: 0.75} }
      
    const anim11= { hidden: { scale: 3, x: '200rem', y: '8.5rem' }, visible: { x: '46rem', y: '8.5rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 1.5, duration: 1 } }, hover: { scale: 0.75} }
     
    const anim12= { hidden: { scale: 3, x: '200rem', y: '8.5rem' }, visible: { x: '59rem', y: '8.5rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 1.6, duration: 1 } }, hover: { scale: 0.75} }
     
    const anim13= { hidden: { scale: 3, x: '200rem', y: '-1rem' }, visible: { x: '59rem', y: '-1rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 1.7, duration: 1 } }, hover: { scale: 0.75} }
     
    const anim14= { hidden: { scale: 3, x: '200rem', y: '8.5rem' }, visible: { x: '72rem', y: '8.5rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 1.8, duration: 1 } }, hover: { scale: 0.75} }
     
    const anim15= { hidden: { scale: 3, x: '200rem', y: '-1rem' }, visible: { x: '72rem', y: '-1rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 1.9, duration: 1 } }, hover: { scale: 0.75} }
     
    const anim16= { hidden: { scale: 3, x: '200rem', y: '8.5rem' }, visible: { x: '85rem', y: '8.5rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 2.0, duration: 1 } }, hover: { scale: 0.75} }
     
    const anim17= { hidden: { scale: 3, x: '200rem', y: '-1rem' }, visible: { x: '85rem', y: '-1rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 2.1, duration: 1 } }, hover: { scale: 0.75} }
     
    const anim18= { hidden: { scale: 3, x: '200rem', y: '-1rem' }, visible: { x: '98rem', y: '-1rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 2.2, duration: 1 } }, hover: { scale: 0.75} }
     
    const anim19= { hidden: { scale: 3, x: '200rem', y: '8.5rem' }, visible: { x: '98rem', y: '8.5rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 2.3, duration: 1 } }, hover: { scale: 0.75} }
     
    const anim20= { hidden: { scale: 3, x: '200rem', y: '8.5rem' }, visible: { x: '111rem', y: '8.5rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 2.4, duration: 1 } }, hover: { scale: 0.75} }
     
    const anim21= { hidden: { scale: 3, x: '200rem', y: '8.5rem' }, visible: { x: '124rem', y: '8.5rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 2.5, duration: 1 } }, hover: { scale: 0.75} }
     
    const anim22= { hidden: { scale: 3, x: '200rem', y: '-1rem' }, visible: { x: '124rem', y: '-1rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 2.6, duration: 1 } }, hover: { scale: 0.75} }
     
    const anim23= { hidden: { scale: 3, x: '200rem', y: '-1rem' }, visible: { x: '137rem', y: '-1rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 2.7, duration: 1 } }, hover: { scale: 0.75} }
    
    const anim24= { hidden: { scale: 3, x: '200rem', y: '8.5rem' }, visible: { x: '138rem', y: '8.5rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 2.8, duration: 1 } }, hover: { scale: 0.75} }
    
    const anim25= { hidden: { scale: 3, x: '200rem', y: '31rem' }, visible: { x: '-6rem', y: '31rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 2.9, duration: 1 } }, hover: { scale: 0.75} }
    
    const anim26= { hidden: { scale: 3, x: '200rem', y: '40rem' }, visible: { x: '-6rem', y: '40rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 3.0, duration: 1 } }, hover: { scale: 0.75} }
    
    const anim27= { hidden: { scale: 3, x: '200rem', y: '31rem' }, visible: { x: '7rem', y: '31rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 3.1, duration: 1 } }, hover: { scale: 0.75} }
    
    const anim28= { hidden: { scale: 3, x: '200rem', y: '31rem' }, visible: { x: '20rem', y: '31rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 3.2, duration: 1 } }, hover: { scale: 0.75} }
    
    const anim29= { hidden: { scale: 3, x: '200rem', y: '31rem' }, visible: { x: '33rem', y: '31rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 3.3, duration: 1 } }, hover: { scale: 0.75} }
    
    const anim30= { hidden: { scale: 3, x: '200rem', y: '22rem' }, visible: { x: '33rem', y: '22rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 3.4, duration: 1 } }, hover: { scale: 0.75} }
    
    const anim31= { hidden: { scale: 3, x: '200rem', y: '31rem' }, visible: { x: '46rem', y: '31rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 3.5, duration: 1 } }, hover: { scale: 0.75} }
    
    const anim32= { hidden: { scale: 3, x: '200rem', y: '31rem' }, visible: { x: '59rem', y: '31rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 3.6, duration: 1 } }, hover: { scale: 0.75} }
    
    const anim33= { hidden: { scale: 3, x: '200rem', y: '31rem' }, visible: { x: '72rem', y: '31rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 3.7, duration: 1 } }, hover: { scale: 0.75} }
    
    const anim34= { hidden: { scale: 3, x: '200rem', y: '22rem' }, visible: { x: '72rem', y: '22rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 3.8, duration: 1 } }, hover: { scale: 0.75} }
    
    const anim35= { hidden: { scale: 3, x: '200rem', y: '22rem' }, visible: { x: '85rem', y: '22rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 3.9, duration: 1 } }, hover: { scale: 0.75} }
    
    const anim36= { hidden: { scale: 3, x: '200rem', y: '40rem' }, visible: { x: '72rem', y: '40rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 4.0, duration: 1 } }, hover: { scale: 0.75} }
    
    const anim37= { hidden: { scale: 3, x: '200rem', y: '31rem' }, visible: { x: '85rem', y: '31rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 4.1, duration: 1 } }, hover: { scale: 0.75} }
    
    const anim38= { hidden: { scale: 3, x: '200rem', y: '31rem' }, visible: { x: '98rem', y: '31rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 4.2, duration: 1 } }, hover: { scale: 0.75} }
    
    const anim39= { hidden: { scale: 3, x: '200rem', y: '31rem' }, visible: { x: '111rem', y: '31rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 4.3, duration: 1 } }, hover: { scale: 0.75} }
    
    const anim40= { hidden: { scale: 3, x: '200rem', y: '31rem' }, visible: { x: '124rem', y: '31rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 4.4, duration: 1 } }, hover: { scale: 0.75} }
    
    const anim41= { hidden: { scale: 3, x: '200rem', y: '31rem' }, visible: { x: '137rem', y: '31rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 4.5, duration: 1 } }, hover: { scale: 0.75} }
    
    const anim42= { hidden: { scale: 3, x: '200rem', y: '22rem' }, visible: { x: '137rem', y: '22rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 4.6, duration: 1 } }, hover: { scale: 0.75} }
    
    const anim43= { hidden: { scale: 3, x: '200rem', y: '40rem' }, visible: { x: '137rem', y: '40rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 4.7, duration: 1 } }, hover: { scale: 0.75} }
    

    {/*-------------------------------------------------------------------NYILAK---------------------------------------------------------------------------------------------------*/}

    const anim101= { hidden: { x: '-50px', y: '0px', opacity: 0, scale: .8 }, visible: { x: '-20px', y: '0px', opacity: 1, scale: .8, transition: {type: "spring", stiffness: 70, delay: 6.0, duration: 1 } }, hover: { scale: 1} }
      
    const anim102= { hidden: { x: '-190px', y: '-80px', opacity: 0, scale: 1 }, visible: { x: '-200px', y: '140px', opacity: 1, scale: 1, transition: {type: "spring", stiffness: 70, delay: 6.0, duration: 1 } }, hover: { scale: 1.2} }
     
    const anim103= { hidden: { x: '-250px', y: '0px', opacity: 0, scale: .8 }, visible: { x: '-290px', y: '0px', opacity: 1, scale: .8, transition: {type: "spring", stiffness: 70, delay: 6.0, duration: 1 } }, hover: { scale: 1} }
      
    const anim104= { hidden: { x: '-190px', y: '-80px', opacity: 0, scale: 1 }, visible: { x: '-200px', y: '-50px', opacity: 1, scale: 1, transition: {type: "spring", stiffness: 70, delay: 6.0, duration: 1 } }, hover: { scale: 1.2} }
     
    const anim105= { hidden: { x: '-190px', y: '-80px', opacity: 0, scale: 1.3 }, visible: { x: '-220px', y: '150px', opacity: 1, scale: 1.3, transition: {type: "spring", stiffness: 70, delay: 6.0, duration: 1 } }, hover: { scale: 1.5} }
     
    const anim106= { hidden: { x: '-190px', y: '-80px', opacity: 0, scale: 1.3 }, visible: { x: '-220px', y: '-40px', opacity: 1, scale: 1.3, transition: {type: "spring", stiffness: 70, delay: 6.0, duration: 1 } }, hover: { scale: 1.5} }
     
    const anim107= { hidden: { x: '-190px', y: '-80px', opacity: 0, scale: 1.1 }, visible: { x: '-200px', y: '150px', opacity: 1, scale: 1.1, transition: {type: "spring", stiffness: 70, delay: 6.0, duration: 1 } }, hover: { scale: 1.3} }
     
    const anim108= { hidden: { x: '-190px', y: '-80px', opacity: 0, scale: 1 }, visible: { x: '-200px', y: '-50px', opacity: 1, scale: 1, transition: {type: "spring", stiffness: 70, delay: 6.0, duration: 1 } }, hover: { scale: 1.2} }
 
    const animTudasbazis= { hidden: { scale: 3, x: '200rem', y: '40rem' }, visible: { x: '72rem', y: '50rem', scale: .65,  transition: {type: "spring", stiffness: 70, delay: 4.0, duration: 1 } }, hover: { scale: 0.75} }
    


    const elementClick = (value) => {
        console.log('maltobuza elementclcick')
        setPopupMessage(maltoBuzaText[value])
        setPopup('popupShow')
        setPopupVisibility(true)
    }

    const tudasbazisClick = (value) => {
        console.log('tudasbazis')
        const { history } = props;
        history.push('maltoTudasbazis');
     }

     const callPopup = () => {
        setPopupMessage('Kérem jelentkezzen be!')
        setPopup('popupShow')
        setPopupVisibility(true)
      }

    const hidePopup = (e) => {
        e.preventDefault()
        console.log('hidePopup ')
        setPopup(false)
      }
    
      const createTitle = () => {
        if(maltoBuza){
          title = 'A búzából előállított maltodextrin enzimes gyártási technológiájának folyamatábrája'
        }else{
          title = 'A kukoricából előállított maltodextrin enzimes gyártási technológiájának folyamatábrája'
        }
       }

   
  return (
    <div >
         {createTitle()}
      <div className = 'title'>
        <h1>{title}</h1>
      </div>
         {Object.keys(actualName).length === 0 ? callPopup() : (
        <>
            <div className = 'folyamatSize'>

        <motion.div  id = 'step1' variants = {animTudasbazis} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={tudasbazis} alt="home1" onClick={(e) => tudasbazisClick()} ></img> 
                </div>
                <div >

                </div>
            </div>
        </motion.div>
        <motion.div  id = 'step1' variants = {anim41} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fMaltoBuza41} alt="home1" onClick={(e) => elementClick(40)} ></img> 
                </div>
                <div >

                </div>
            </div>
        </motion.div>
        <motion.div  id = 'step1' variants = {anim40} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fMaltoBuza40} alt="home1" onClick={(e) => elementClick(39)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim101} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={rightArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={rightArrow} alt="home1" ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
        <motion.div  id = 'step1' variants = {anim39} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fMaltoBuza39} alt="home1" onClick={(e) => elementClick(38)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim101} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={rightArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={rightArrow} alt="home1"  ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
        <motion.div  id = 'step1' variants = {anim38} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fMaltoBuza38} alt="home1" onClick={(e) => elementClick(37)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim101} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={rightArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={rightArrow} alt="home1"  ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
        <motion.div  id = 'step1' variants = {anim37} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fMaltoBuza37} alt="home1" onClick={(e) => elementClick(36)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim101} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={rightArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={rightArrow} alt="home1"  ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>



        <motion.div  id = 'step1' variants = {anim33} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fMaltoBuza33} alt="home1" onClick={(e) => elementClick(32)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim101} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={rightArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={rightArrow} alt="home1" ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
        <motion.div  id = 'step1' variants = {anim32} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fMaltoBuza32} alt="home1" onClick={(e) => elementClick(31)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim101} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={rightArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={rightArrow} alt="home1"  ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
        <motion.div  id = 'step1' variants = {anim31} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fMaltoBuza31} alt="home1" onClick={(e) => elementClick(30)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim101} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={rightArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={rightArrow} alt="home1"  ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>

        <motion.div  id = 'step1' variants = {anim29} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fMaltoBuza29} alt="home1" onClick={(e) => elementClick(28)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim101} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={rightArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={rightArrow} alt="home1"  ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
        <motion.div  id = 'step1' variants = {anim28} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fMaltoBuza28} alt="home1" onClick={(e) => elementClick(27)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim101} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={rightArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={rightArrow} alt="home1"  ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
        <motion.div  id = 'step1' variants = {anim27} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fMaltoBuza27} alt="home1" onClick={(e) => elementClick(26)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim101} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={rightArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={rightArrow} alt="home1"  ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
        <motion.div  id = 'step1' variants = {anim43} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fMaltoBuza43} alt="home1" onClick={(e) => elementClick(42)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim104} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={upArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={upArrow} alt="home1"  ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
        <motion.div  id = 'step1' variants = {anim42} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fMaltoBuza42} alt="home1" onClick={(e) => elementClick(41)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim102} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={downArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={downArrow} alt="home1"  ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
        <motion.div  id = 'step1' variants = {anim36} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fMaltoBuza36} alt="home1" onClick={(e) => elementClick(35)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim108} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={downArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={downArrow} alt="home1" ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
        <motion.div  id = 'step1' variants = {anim34} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fMaltoBuza34} alt="home1" onClick={(e) => elementClick(33)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim102} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={downArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={downArrow} alt="home1" ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
        <motion.div  id = 'step1' variants = {anim35} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fMaltoBuza35} alt="home1" onClick={(e) => elementClick(34)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim103} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={leftArrowHover} alt="home1" ></img> 
                    <img  className = 'motionArrow' src={leftArrow} alt="home1"  ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
        <motion.div  id = 'step1' variants = {anim30} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fMaltoBuza30} alt="home1" onClick={(e) => elementClick(29)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim107} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={upArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={upArrow} alt="home1"  ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
        <motion.div  id = 'step1' variants = {anim25} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fMaltoBuza25} alt="home1" onClick={(e) => elementClick(24)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim101} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={rightArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={rightArrow} alt="home1"  ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>

        <motion.div  id = 'step1' variants = {anim26} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fMaltoBuza26} alt="home1" onClick={(e) => elementClick(25)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim106} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={swapArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={swapArrow} alt="home1"  ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
        <motion.div  id = 'step1' variants = {anim24} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fMaltoBuza24} alt="home1" onClick={(e) => elementClick(23)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim101} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={rightArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={rightArrow} alt="home1"  ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>

        <motion.div  id = 'step1' variants = {anim21} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fMaltoBuza21} alt="home1" onClick={(e) => elementClick(20)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim101} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={rightArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={rightArrow} alt="home1"  ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
        <motion.div  id = 'step1' variants = {anim20} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fMaltoBuza20} alt="home1" onClick={(e) => elementClick(19)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim101} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={rightArrowHover} alt="home1" ></img> 
                    <img  className = 'motionArrow' src={rightArrow} alt="home1"  ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
        <motion.div  id = 'step1' variants = {anim19} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fMaltoBuza19} alt="home1" onClick={(e) => elementClick(18)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim101} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={rightArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={rightArrow} alt="home1"  ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>

        <motion.div  id = 'step1' variants = {anim16} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fMaltoBuza16} alt="home1" onClick={(e) => elementClick(15)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim101} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={rightArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={rightArrow} alt="home1"  ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>

        <motion.div  id = 'step1' variants = {anim14} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fMaltoBuza14} alt="home1" onClick={(e) => elementClick(13)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim101} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={rightArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={rightArrow} alt="home1"  ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>

        <motion.div  id = 'step1' variants = {anim12} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fMaltoBuza12} alt="home1" onClick={(e) => elementClick(11)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim101} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={rightArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={rightArrow} alt="home1"  ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
        <motion.div  id = 'step1' variants = {anim13} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fMaltoBuza13} alt="home1" onClick={(e) => elementClick(12)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim105} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={swapArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={swapArrow} alt="home1"  ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
        <motion.div  id = 'step1' variants = {anim15} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fMaltoBuza15} alt="home1" onClick={(e) => elementClick(14)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim102} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={downArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={downArrow} alt="home1"  ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>

        <motion.div  id = 'step1' variants = {anim22} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fMaltoBuza22} alt="home1" onClick={(e) => elementClick(21)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim102} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={downArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={downArrow} alt="home1"  ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
        <motion.div  id = 'step1' variants = {anim23} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fMaltoBuza23} alt="home1" onClick={(e) => elementClick(22)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim103} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={leftArrowHover} alt="home1" ></img> 
                    <img  className = 'motionArrow' src={leftArrow} alt="home1"  ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
        <motion.div  id = 'step1' variants = {anim17} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fMaltoBuza17} alt="home1" onClick={(e) => elementClick(16)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim102} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={downArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={downArrow} alt="home1"  ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
        <motion.div  id = 'step1' variants = {anim18} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fMaltoBuza18} alt="home1" onClick={(e) => elementClick(17)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim103} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={leftArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={leftArrow} alt="home1"  ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
        <motion.div  id = 'step1' variants = {anim11} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fMaltoBuza11} alt="home1" onClick={(e) => elementClick(10)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim101} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={rightArrowHover} alt="home1" ></img> 
                    <img  className = 'motionArrow' src={rightArrow} alt="home1" ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
        <motion.div  id = 'step1' variants = {anim10} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fMaltoBuza10} alt="home1" onClick={(e) => elementClick(9)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim101} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={rightArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={rightArrow} alt="home1" ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>

        <motion.div  id = 'step1' variants = {anim8} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fMaltoBuza8} alt="home1" onClick={(e) => elementClick(7)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim101} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={rightArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={rightArrow} alt="home1" ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
        <motion.div  id = 'step1' variants = {anim3} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fMaltoBuza3} alt="home1" onClick={(e) => elementClick(2)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim101} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={rightArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={rightArrow} alt="home1" ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
        <motion.div  id = 'step1' variants = {anim9} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fMaltoBuza9} alt="home1" onClick={(e) => elementClick(8)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim104} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={upArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={upArrow} alt="home1"  ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
        <motion.div  id = 'step1' variants = {anim6} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fMaltoBuza6} alt="home1" onClick={(e) => elementClick(5)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim104} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={upArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={upArrow} alt="home1" ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
        <motion.div  id = 'step1' variants = {anim7} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fMaltoBuza7} alt="home1" onClick={(e) => elementClick(6)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim101} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={rightArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={rightArrow} alt="home1" ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>



        <motion.div  id = 'step1' variants = {anim1} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fMaltoBuza1} alt="home1" onClick={(e) => elementClick(0)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim101} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={rightArrowHover} alt="home1" ></img> 
                    <img  className = 'motionArrow' src={rightArrow} alt="home1"  ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
        <motion.div  id = 'step1' variants = {anim4} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fMaltoBuza4} alt="home1" onClick={(e) => elementClick(3)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim102} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={downArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={downArrow} alt="home1"  ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
        <motion.div  id = 'step1' variants = {anim5} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fMaltoBuza5} alt="home1" onClick={(e) => elementClick(4)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim103} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={leftArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={leftArrow} alt="home1"  ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
        <motion.div  id = 'step1' variants = {anim2} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fMaltoBuza2} alt="home1" onClick={(e) => elementClick(1)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim102} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={downArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={downArrow} alt="home1" ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>

        <img  src={folyamatabraUres} alt="home1"></img>
        </div>
        </>
        )}
    </div>
  );
  
}

export default MaltoBuzaFolyamat2;
