import React from 'react'
import logofeher from '../assets/logofeher.png'
import '../App.css';



function Header(props){

    

    return(
        <div className = 'w-100 p-3'>
        <div className = 'header'>
            <img className = 'headerLogo' src={logofeher} alt="logofeher"></img>
            <button className = 'btn btn-lg btn-outline-light' onClick={(e) => props.handleHome()}>home</button>
            {props.userName.length == undefined ? '' : <h1 className = 'headerText'>Bejelentkezve: {props.userName}</h1>}
            <button className = 'btn btn-lg btn-outline-light' onClick={(e) => props.handleLogout()}>logout</button>
            
        </div>
        </div>
    )
}

export default Header