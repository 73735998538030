

export const neutralText = [
    'Az etanolt keményítőből vagy cukorból élesztővel (Saccharomyces cerevisiae) termeltetik anaerob fermentációval, ami röviden a következő egyenlettel foglalható össze: C6H12O6 — 2 CH3CH2OH + 2 C02 Az erjedés egy tökéletlen égés, mert például az alkoholos erjedésnél egy mol hexózból 2 mol etanol és két mol CO2 képződik. Az energianyereség kb. 20 kJ/mol, szemben a tökéletes égésnek megfelelő aerob folyamattal, ahol 1 mol hexózból 6 mol CO2 képződik, és az energianyereség 507 kJ/mol.',
    'Szuszpenzió előkészítés (keverés)',
    'Ipari úton az etanolt etilén és víz sav-katalizált reakciójával állítják elő:H2C=CH2 + H2O --> CH3CH2OH',
    'Hőbehatással igen rövid ideig, kb. 15 másodpercig, 140—145 °C-on feltárjuk a keményítőt.',
    'A kizárólag savkatalizált hidrolízissel előállított maltodextrinek lineáris láncokban gazdagok, amelyek könnyen visszahúzódnak. A savas katalízis, majd az amiláz által katalizált hidrolízis kombinációja alacsony higroszkópos és magas vízoldékonyságú maltodextrineket eredményez. A savas hidrolízist így felváltotta az enzimes bontás, amelynél három vagy négy különböző enzimet alkalmaznak. Az első lépés a vízoldható rövid szénláncú dextrinek elfolyósítása. A 30-35% szárazanyag-tartalmú szuszpenzió pH-ját 6-ra állítják be, ezután hozzáadják az α-amilázt. ',
    'Ha keményítő alapanyagból dolgozunk, az ebben található cukrot az élesztő számára hozzáférhetővé kell tenni. A keményítőt alfa-amilázzal kezelik. Az exoenzim számos mikroorganizmussal megtermeltethető, ilyen például a Bacterium licheniformis, Bacterium subtilis, Aspergillus niger. Hozzáadjuk az összes enzimmennyiség egy-hatodát és megkezdődik a keményítő bontása. folyósító enzim: endo-amiláz, a keményítő láncok belsejében, véletlenszerűen az α(1-4)-glikozidkötéseket hasítja, a terméke amilóz szubsztrát esetén maltotrióz és maltóz, amilopektin esetén dextrin, maltóz és glükóz. A folyósító nevet azért kapta, mert hatására a nagyon viszkózus elcsirizesített keményítő elfolyósodik, a bontástól a viszkozitás drámaian lecsökken. A keményítőlánc tetszőleges pontját képes megtámadni, ezért gyorsabban bont a csak láncvégen dolgozó enzimeknél. ',
    'Szuszpenzió szállítás szivattyúval',
    'A közvetlen gőzbevezetésű reaktorban 90 percig 95-105 °C-on tartják a szuszpenziót, 100 °C feletti hőmérsékleten jó hatásfokkal távolítható el a keményítő-lipid komplex. ',
    'Hőbehatással igen rövid ideig, kb. 15 másodpercig, 140—145 °C-on feltárjuk a keményítőt.',
    'Hőkezelt szuszpenzió szállítása',
    'Ahhoz, hogy az amiláz elvégezhesse a dolgát, a hidrofób keményítőt elő kell készíteni. Ezt úgynevezett Henze-edényben végzik, ahol forró gőzt fúvatnak a keményítőre zárt térben, majd felfőzik. Az eredmény: ragadós, csiriz-szerű massza, amit a Henze-edény alján egy nyitott, rozsdamentes acélból készült cefrekádba engednek, kihűtik, és hozzáadják az amilázt. Az enzim elvégzi a keményítő hidrolízisét, amitől a csiriz elfolyósodik. Az így kapott keverék monomer, dimer cukrok vizes oldata. Itt 85—88 °C-on adagoljuk hozzá az alfa-amiláz harmadik részletét, s fejezzük be a kívánt DE-értéknél a szörpelőállítást. Ezután inaktiváljuk az enzimet, majd megkezdjük a színtelenítési, tisztítási műveleteket, amelyeket a besűrítés követ.',
    'A metalloproteinek közé tartozó enzimcsalád, amelynek tagjai csak kalciumion jelenlétében működőképesek. A keményítő α(1-4)-glikozidkötéseit hasítják, a végtermék amilóz esetén maltotrióz és maltóz, amilopektin esetén dextrin, maltóz és glükóz. A keményítőlánc tetszőleges pontját képes megtámadni, ezért gyorsabban bont a csak láncvégen dolgozó enzimeknél. Itt adagoljuk az enzim második részét, mintegy 42%-át, majd 20—30 perc tartózkodási idő után expanziós hűtőn keresztül az elfolyósító tartályba jut az anyag.',
    'Az erjedés vagy erjesztés, idegen szóval fermentálás vagy fermentáció olyan kémiai folyamat, amelyben valamilyen szerves anyagot egy enzim hatásának teszünk ki. A fermentáció olyan technológiai folyamat, amelynek során a jelen levő élő mikroorganizmusok szaporodásának, életfolyamataik és enzimjeik hatására bonyolult biokémiai változások mennek végbe az alapanyagokban. E folyamatot jellemzően baktériumok és/vagy gombák – kivételes esetekben algák, növényi eukarióta sejtek esetleg emlőssejtek - használatával végzik, és szénhidrátok lebontása során primer, illetve szekunder metabolitokat (anyagcseretermékeket) állítanak elő, vagy biokonverzióval értékes anyagokat alakítanak át. Tipikus fermentációs termékek az aminosavak, enzimek, vitaminok, antibiotikumok(pl. penicillinek, sztreptomicinek, tetraciklinek, stb.), konvertált szteroidok (biotranszformáció) és rekombináns fehérjék (pl. r-inzulin, r-kalcitonin, stb.).',
    'Technológiai víz adagolása szivattyúval áramlásmérőn keresztül',
    'Hagyományos módon az etanolt cukortartalmú oldatok élesztőkkel való erjesztésével nyerik. (Az alkoholos italokat ma is kizárólag így készítik.)',
    'Habzásgátló beoldás',
    'Folyékony, glicerinmentes, speciális enzimkészítmény, mely rövid idő alatt drasztikusan csökkenti a viszkozitást. A nagy aktivitású enzim a lehető legrövidebb idő alatt elvégzi az oldatban található pektinek hidrolízisét.',
    'Ezzel a módszerrel legfeljebb 15-18% (V/V) etanoltartalmú oldatot lehet előállítani, az ennél töményebb alkoholt desztillálással készítik (kihasználva azt, hogy az etanol forráspontja (78°C) alacsonyabb a vízénél (100°C). Az így előállítható legtöményebb etil-alkoholos oldat egyszerű desztillálással tovább nem tisztítható azeotrópos elegyet képez, és „csak” 96 (V/V)%-os (tiszta szesz), ugyanis a maradék 4% víz együtt forr az alkohollal. Töményebb, ún. abszolút alkoholt (100%) csak vízelvonószerek használatával nyerhetünk (víztelenítésére kálium- vagy nátrium-karbonátot használnak). Az enzimek ipari felhasználása, mint például az alfa-amiláz, glükoamiláz, glükóz/xilóz izomeráz enzimhármas, amelyek segítségével keményítôbôl izocukorszörp állítható elő. ',
    'Levegőadagolás kompresszorral',
    'Ezt a vizes oldatot teszik a fermentorba, ahol az élesztő etanolt állít elő a cukrokból alkoholos erjedéssel. A fermentorban maximum 14-16%-os alkohololdat nyerhető, mivel az élesztőgomba ennél töményebb alkohol oldatban már nem él meg. Az alkoholt innen desztillációval nyerik ki. Ez ma már szintén rozsdamentes acélból készült berendezésben történik. Az alkohololdat ekkor 82-87%-os.',
    'Az amilázok a keményítő cukrokká bontását végző enzimcsalád. Mivel lebontja a poliszacharidokat, a glikozidázok közé sorolják, és mivel hidrolizál, a hidrolázok közé tartozik. Fehérjeszerkezetük alapján az amilázok a glikozil hidrolázok 13-as, 14-es és 15-ös osztályába tartoznak. Elvégzi a keményítő bontását. Összetevő: alfa-amiláz, glüköamiláz. A GA 500 keményítő bontó enzim tartalmaz glüköamiláz (más néven: amiloglükozidáz, ß-D-glükóz, glükoprotein, glucoamylase) enzimet is!',
    'Az alkohol gyártása nem más mint szakaszos desztillációs művelet! A lepárlás két meghatározó jellemzője, hogy a cefrefőzés és az alszeszfinomítás külön főzések alkalmával történik, és hogy mindegyik lepárlás szakaszos üzemű, tehát az üstbe előre betöltött mennyiséget párolják le. Az erősítő feltétes vagy más néven finomító oszlopos, ill. tornyos lepárlás során a párát a főzőüstből egy erősítő (finomító) oszlopba vezetik, amely csak a magas alkoholtartalmú, aromákban gazdag gőzöket engedi tovább a hűtőspirál felé. Az eljárás lényege, hogy ennél a teljes lepárlási folyamat egy szakaszban megtörténik, azaz a cefre egyszeri felmelegítésével eljutunk az alkoholig. ',
    'A másik fontos lépés az előpárlat elkülönítése. Az előpárlat tartalmazza a fermentáció során kis mennyiségben keletkező acetaldehid és kozmaolajok (nagyobb szénatomszámú alkoholok) nagy részét. Az acetaldehid forráspontja 20°C, az illékonysága pedig nagy, így már hamar kiválasztódik az etanolhoz képest. Az előpárlat a teljes desztillátum kb. 1-2%-a.',
    'A kozmaolaj leválasztás lényege, hogy a kifolyó alszeszt föl kell higítani hideg vízzel (legalábbis a töményebb elejét) - a híg, hideg alkohol-víz elegyben a kozmaolaj rosszul oldódik, részben felúszik az alszesz tetejére, részben kis cseppeket képez, amik az opálosságot adják. A felúszó kozmaolajat ezért el kell eltávolítani.',
    'A folyadékelegyek szétválasztásának egyik leggyakrabban alkalmazott módszere a gőzfolyadék egyensúlyon alapuló desztilláció ill. az ismételt desztilláció: a rektifikálás. Mindkét művelet a szétválasztandó komponensek illékonyságának a különbségén alapszik. A folyadékkal érintkező, vele termodinamikai egyensúlyban lévő gőzfázisban a két, vagy több, eltérő illékonyságú vegyületet tartalmazó rendszer esetén a nagyobb tenziójú (alacsonyabb forráspontú) komponens(ek) koncentrációja nagyobb, mint a folyadékban. Ezt a dúsulási lehetőséget hasznosítjuk a desztilláció (rektifikáció) művelete során, laboratóriumi és ipari méretekben egyaránt.',
    'Nem lekondenzált alkohol páraelvezetés kondenzátoron keresztül',
    'Az alkohol közvetlen desztillálással maximum 95,6% tisztaságúra desztillálható, mert a vízzel azeotróp elegyet képez. Nagyobb töménység eléréséhez a desztillálás benzol vagy ciklohexán hozzáadásával folytatható, mert ez a két anyag az alkohollal és a vízzel egy hármas azeotróp elegyet képez, aminek jóval alacsonyabb a forráspontja az alkohol-víz azeotrópnál. Így a víz a desztillálás során eltávozik és nagy töménységű alkohol marad vissza. Az így kapott >99% tisztaságú alkohol azonban már emberi fogyasztásra nem alkalmas, az alkoholban nyomokban található mérgező benzol miatt.',
    'A dekantálás keverékek szétválasztására szolgáló folyamat. Ezt egy oldat egy edényből való óvatos kiöntése által valósíthatjuk meg, így az üledék az eredeti edény alján marad. Általában egy kevés oldatnak kell maradnia az edényben, és óvatosnak kell lenni, hogy ne áramoljon ki egy kis üledék az oldattal együtt. Az etanol, ami a jellegzetes alkoholillatot adja, az oxigénnel találkozva gyorsan távozik, akárcsak a záptojás szagú szulfitok. A szeszmoslék nem más mint lepárolt cefre. Mivel a koncentrált oldatban a cukor nem erjed maradéktalanul alkohollá, ezért a cefrét szükség szerint vízzel higítják. Ezért van az, hogy a friss szeszmoslék kevés, legfeljebb 6-8 % szárazanyagot tartalmaz. A szeszmoslékok közül a gabonaalapúak kedvezőbb étrendi hatásúak, mint más nyersanyagból (burgonya, cukorrépa, gyümölcs, stb.) származók. A kukorica szeszipari feldolgozása során keletkezett híg moslék 25-30 % szárazanyag-tartalomra besűrítve sűrű kukoricamoslék néven kerül forgalomba.',
    
    'Ha keményítő alapanyagból dolgozunk, az ebben található cukrot az élesztő számára hozzáférhetővé kell tenni. A keményítőt alfa-amilázzal kezelik. Az exoenzim számos mikroorganizmussal megtermeltethető, ilyen például a Bacterium licheniformis, Bacterium subtilis, Aspergillus niger. Hozzáadjuk az összes enzimmennyiség egy-hatodát és megkezdődik a keményítő bontása. folyósító enzim: endo-amiláz, a keményítő láncok belsejében, véletlenszerűen az α(1-4)-glikozidkötéseket hasítja, a terméke amilóz szubsztrát esetén maltotrióz és maltóz, amilopektin esetén dextrin, maltóz és glükóz. A folyósító nevet azért kapta, mert hatására a nagyon viszkózus elcsirizesített keményítő elfolyósodik, a bontástól a viszkozitás drámaian lecsökken. A keményítőlánc tetszőleges pontját képes megtámadni, ezért gyorsabban bont a csak láncvégen dolgozó enzimeknél. ',

   

]

