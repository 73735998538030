import React, { useEffect, useState, useContext  } from 'react';
import home1 from '../assets/home1.jpg'
import '../App.css';
import Header from '../components/Header'
import ButtonBelepes from '../components/buttons/ButtonBelepes'
import { appContext } from '../App'



function HomeScreen(props){

    //appContext
    const [ appContactList, setAppContactList, actualUser, setActualUser ] = useContext(appContext)

    const loginClick = (value) => {
        const { history } = props;
        history.push('login');
      }

    return(
        <div >
            <ButtonBelepes loginClick = {loginClick}></ButtonBelepes>
            <img className = 'appContainer'  src={home1} alt="home1"></img>
        </div>
    )
}

export default HomeScreen