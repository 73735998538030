import React, { useEffect, useState, useContext  } from 'react';
import '../App.css';
import { appContext } from '../App'
import PDFGenerator from '../util/PDFGenerator'
import PDFGeneratorNeutral from '../util/PDFGeneratorNeutral'
import fire from '../database/Firebase'
import DOMPurify from "dompurify";
let chooser

function ShowPdf(props){

    

const myHTML = `<embed src = "oklev.pdf" type= " application/pdf width = "60%" height = "200px"></embed>`;
//const myHTML = `<h1>John Doe</h1>`;
const mySafeHTML = DOMPurify.sanitize(myHTML);



 
    return(
        <div >
            <div dangerouslySetInnerHTML={{ __html: mySafeHTML }} ></div>
        </div>
    )
}

export default ShowPdf