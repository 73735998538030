    export const parser = async (jsonName, jsonLink) => {
        let jsonObject = {}
        jsonObject.name = jsonName
        jsonObject.text = await jsonParse(jsonLink)
        return jsonObject
    }

    const jsonParse = async (jsonLink) => { 
      let response = await fetch(jsonLink)
      let data = await response.json()
      return data
    }