import React, { useEffect, useState, useContext } from 'react';
import './maltoBuza/MaltoBuza.css';
import { appContext } from '../App'
import ResultGenerator from '../util/ResultGenerator'
require("dotenv").config()


let title = ''

const Eredmeny = (props) => {

    //Context
    const [ 
      appContactList, setAppContactList, actualUser, setActualUser, actualName, setActualName, updaterId, setUpdaterId, eredmenyData, setEredmenyData, submitted, setSubmitted, maltoBuza, setMaltoBuza,
      kemenyitoSzarazanyag, setKemenyitoSzarazanyag, kemenyitoHo, setKemenyitoHo, hokezelesHo, setHokezelesHo, hokezelesIdo, setHokezelesIdo,
      folyositasHo, setFolyositasHo, folyositasIdo, setFolyositasIdo, dextHo, setDextHo, dextIdo, setDextIdo,
      kukKemenyitoSzarazanyag, setKukKemenyitoSzarazanyag, kukKemenyitoHo, setKukKemenyitoHo, kukHokezelesHo, setKukHokezelesHo, kukHokezelesIdo, setKukHokezelesIdo,
      kukFolyositasHo, setKukFolyositasHo, kukFolyositasIdo, setKukFolyositasIdo, kukDextHo, setKukDextHo, kukDextIdo, setKukDextIdo,
      neutralKemenyitoSzarazanyag, setNeutralKemenyitoSzarazanyag, neutralKemenyitoHo, setNeutralKemenyitoHo, neutralHokezelesHo, setNeutralHokezelesHo,
      neutralHokezelesIdo, setNeutralHokezelesIdo, neutralFolyositasIdo, setNeutralFolyositasIdo, neutralDextHo, setNeutralDextHo,
      neutralCukrositasHo, setNeutralCukrositasHo,
      results, setResults, params, setParams, 
      sulySzazalek, setSulySzazalek, szakadas, setSzakadas, szazalekEredmeny, setSzazalekEredmeny, energiaFelhasznalas, setEnergiaFelhasznalas, energiaHatekonysag, setEnergiaHatekonysag
     ] = useContext(appContext)

   
      const createTitle = () => {
        
        if(eredmenyData.length > 0){
          let first = eredmenyData[0].name
          switch(first){
            case 'neutralKemenyitoSzarazanyag' :
              title = 'A neutrális alkohol gyártási szimulációjának eredményei'
              break
            case 'kemenyitoSzarazanyag' :
              title = 'A búzából előállított maltodextrin enzimes gyártási szimulációjának eredményei'
              break
          }
        } 
      }

      const validateSulySzazalek = (result) => {
        console.log('result suly% ', result)
        if(result > -1 && result < 1){
          setSzazalekEredmeny('A folyamat lezajlik, eredménye az ideálishoz közeli')
          setSzakadas('IGEN')
        }else if(result > -2 && result < 2){
          setSzazalekEredmeny('A folyamat lezajlik, a beltartalmi értékek és a vizuális megjelenés megfelelő')
          setSzakadas('IGEN')
        }else if(result > -4 && result < 4){
          setSzazalekEredmeny('A folyamat lezajlik, a beltartalmi értékek megfelelőek, a végtermék vizuális megjelenése eltérhet az ideálistól')
          setSzakadas('IGEN')
        }else if(result > -7 && result < 7){
          setSzazalekEredmeny('A folyamat lezajlik, a beltartalmi értékek és a vizuális megjelenés eltérhetnek az optimálistól')
          setSzakadas('IGEN')
        }else if(result > -10 && result < 10){
          setSzazalekEredmeny('A folyamat nagy valószínűséggel lezajlik, a végeredmény meghatározásához kísérletek lefolytatására van szükség')
          setSzakadas('FELTÉTELESEN IGEN')
        }else if(result > -15 && result < 15){
          setSzazalekEredmeny('A folyamat nagy valószínűséggel nem zajlik le, a végeredmény meghatározásához kísérletek lefolytatására van szükség')
          setSzakadas('FELTÉTELESEN NEM')
        }else{
          setSzazalekEredmeny('A folyamat nem zajlik le, a szimuláció ad értékelhető eredményt')
          setSzakadas('NEM')
        }
        
      }

      const validateEnergia = (eredmeny) => {
        let NEG = 0
        let POS = 0
        let NULL = 0
        //i = 1, mert a lista első eleme nem releváns
        for(let i = 1; i < eredmeny.length; i++){
        
          if(eredmeny[i].absolute < 0){
            NEG++
          }else if(eredmeny[i].absolute > 0){
            POS++
          }else{
            NULL++
          }
        }

        if(NEG === 7){
          setEnergiaFelhasznalas('IGEN')
          setEnergiaHatekonysag('NÖVELHETŐ')
        }else if(NULL === 7){
          setEnergiaFelhasznalas('FELTÉTELESEN IGEN')
          setEnergiaHatekonysag('FELTÉTELESEN NÖVELHETŐ')
        }else if(POS < NEG){
          setEnergiaFelhasznalas('FELTÉTELESEN IGEN')
          setEnergiaHatekonysag('FELTÉTELESEN NÖVELHETŐ')
        }else if(POS === NEG){
          setEnergiaFelhasznalas('FELTÉTELESEN NEM')
          setEnergiaHatekonysag('NEM NÖVELHETŐ')
        }else{
          setEnergiaFelhasznalas('NEM')
          setEnergiaHatekonysag('NEM NÖVELHETŐ')
        }
      }

  return (
    <div>
      {createTitle()}
      {submitted ? 
      <div>
        <ResultGenerator eredmenyData = {eredmenyData} params = {params} validateSulySzazalek = {validateSulySzazalek} setSulySzazalek = {setSulySzazalek} validateEnergia = {validateEnergia}></ResultGenerator>
        <div className = 'eredmenyContainer'>
          <div className = 'params'>
            <div className = 'title'>
              <h1>{eredmenyData.length > 0 ? title : ''}</h1>
            </div>
              {results.length > 0 ? 
                <div className = 'eredmenyParam'>
                  <div className = 'eredmenyElement'>
                    <div className = 'eredmenyOneElement'><h1>Paraméter megnevezése</h1></div>
                    <div className = 'eredmenyOneElementSmall'><h1>Paraméter értéke</h1></div>
                    <div className = 'eredmenyOneElementSmall'><h1>Abszolút eltérés az optimálistól</h1></div>
                    <div className = 'eredmenyOneElementSmall'><h1>Relatív eltérés az optimálistól</h1></div>
                  </div>
                  {results.map((item, index) => 
                    <div key = {index} className = 'eredmenyElement' >
                        <div className = 'eredmenyOneElement'> <h1>{item.subScription} </h1></div>
                        <div className = 'eredmenyOneElementSmall'> <h1>{item.param} </h1></div>
                        <div className = 'eredmenyOneElementSmall'><h1> {(item.absolute).toFixed(2)}</h1></div>
                        <div className = 'eredmenyOneElementSmall'><h1> {(item.relative).toFixed(2)} %</h1></div>
                     </div>
                  )}
                  <div className = 'eredmenyElementMegallapit'>
                    <h2>Megállapítás: {szazalekEredmeny}</h2>
                  </div>
                  <div className = 'eredmenyElementMegallapit'>
                    <h2>A folyamat szakadásmentes-e:    {szakadas}</h2>
                  </div>
                  <div className = 'eredmenyElementMegallapit'>
                    <h2>Az energiafelhasználás csökkenthető-e:    {energiaFelhasznalas}</h2>
                  </div>
                  <div className = 'eredmenyElementMegallapit'>
                    <h2>Az energiahatékonyság növelhető-e:    {energiaHatekonysag}</h2>
                  </div>
                </div>
              : ''}
            </div>

          </div>
          <div className = 'buttons'>
            <button className = 'btn btn-success btn-lg' onClick={(e) => props.handlePDF(e)}>Szimulációs jegyzőkönyv megtekintése</button>
            
        </div>
      </div>
      : ''
      }
    </div>
  );
  
}

export default Eredmeny;
