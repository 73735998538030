import firebase from 'firebase'
import 'firebase/storage'
import 'firebase/functions';

require("dotenv").config()

var serviceAccount = require("../tridevinnovacio-49350-firebase-adminsdk-qnv5c-8811329b15.json");


const fire = firebase.initializeApp({
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,

    
    
})



const storage = firebase.storage()


export { storage,  fire as default }