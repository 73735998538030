let maltoText = []

export const maltoBuzaText = [
    'A keményítő lebontása folyamán először kisebb molekulatömegű poliszacharidokra esik szét, ezek az ún. dextrinek. A lebomlási folyamat a jódreakcióval jól követhető, a kék szín helyett a hidrolízis előrehaladtával ibolyás, majd halványvörös átmenet észlelhető. Ha a dextrinek tovább bomlanak maltózra, majd szőlőcukorra, a színreakció végül teljesen elmarad',
    'Technológiai víz hozzáadása',
    'A metalloproteinek közé tartozó enzimcsalád, amelynek tagjai csak kalciumion jelenlétében működőképesek. A keményítő α(1-4)-glikozidkötéseit hasítják, a végtermék amilóz esetén maltotrióz és maltóz, amilopektin esetén dextrin, maltóz és glükóz. A keményítőlánc tetszőleges pontját képes megtámadni, ezért gyorsabban bont a csak láncvégen dolgozó enzimeknél. Itt adagoljuk az enzim második részét, mintegy 42%-át, majd 20—30 perc tartózkodási idő után expanziós hűtőn keresztül az elfolyósító tartályba jut az anyag. ',
    'Adagolás szivattyúval',
    'A kizárólag savkatalizált hidrolízissel előállított maltodextrinek lineáris láncokban gazdagok, amelyek könnyen visszahúzódnak. A savas katalízis, majd az amiláz által katalizált hidrolízis kombinációja alacsony higroszkópos és magas vízoldékonyságú maltodextrineket eredményez. A savas hidrolízist így felváltotta az enzimes bontás, amelynél három vagy négy különböző enzimet alkalmaznak. Az első lépés a vízoldható rövid szénláncú dextrinek elfolyósítása. A 30-35% szárazanyag-tartalmú szuszpenzió pH-ját 6-ra állítják be, ezután hozzáadják az α-amilázt. ',
    'Ahhoz, hogy az amiláz elvégezhesse a dolgát, a hidrofób keményítőt elő kell készíteni. Ezt úgynevezett Henze-edényben végzik, ahol forró gőzt fúvatnak a keményítőre zárt térben, majd felfőzik. Az eredmény: ragadós, csiriz-szerű massza, amit a Henze-edény alján egy nyitott, rozsdamentes acélból készült cefrekádba engednek, kihűtik, és hozzáadják az amilázt. Az enzim elvégzi a keményítő hidrolízisét, amitől a csiriz elfolyósodik. Az így kapott keverék monomer, dimer cukrok vizes oldata. Itt 85—88 °C-on adagoljuk hozzá az alfa-amiláz harmadik részletét, s fejezzük be a kívánt DE-értéknél a szörpelőállítást. Ezután inaktiváljuk az enzimet, majd megkezdjük a színtelenítési, tisztítási műveleteket, amelyeket a besűrítés követ.',
    'A keményítőt alfa-amilázzal kezelik. Hozzáadjuk az összes enzimmennyiség egy-hatodát és megkezdődik a keményítő bontása. folyósító enzim: endo-amiláz, a keményítő láncok belsejében, véletlenszerűen az α(1-4)-glikozidkötéseket hasítja, a terméke amilóz szubsztrát esetén maltotrióz és maltóz, amilopektin esetén dextrin, maltóz és glükóz. A folyósító nevet azért kapta, mert hatására a nagyon viszkózus elcsirizesített keményítő elfolyósodik, a bontástól a viszkozitás drámaian lecsökken. A keményítőlánc tetszőleges pontját képes megtámadni, ezért gyorsabban bont a csak láncvégen dolgozó enzimeknél.',
    'A közvetlen gőzbevezetésű reaktorban 90 percig 95-105 °C-on tartják a szuszpenziót, 100 °C feletti hőmérsékleten jó hatásfokkal távolítható el a keményítő-lipid komplex. A folyósításhoz használt ún. gőz injektor (Jet cooker) az ábrán látható.',
    'Hőbehatással igen rövid ideig, kb. 15 másodpercig, 140—145 °C-on feltárjuk a keményítőt. A melegítés helyett közvetlen gőzbevezetést alkalmaztak, és nyomás alatt, folytonos keverés közben melegítették fel a szuszpenziót 100-175 °C-ra. Ezzel a módszerrel a szuszpenzió néhány másodperc alatt elérte a kívánt hőmérsékletet, az enzimes bontásra a hidrolizáló reaktorban került sor. A közvetlen gőzbevezetés elősegítette a keményítőszemcsék jobb feltárását, és ezáltal könnyebbé vált a diszpergálás. A nem hőérzékeny enzimet már felmelegítés előtt bekeverték a szuszpenzióba.',
    'A keményítőtej termikus feltárása után függőleges helyzetű, hengeres, keverős tartályba expandál és kb. 100 °C-ra hűl le. Itt adagoljuk az enzim második részét, mintegy 42%-át, majd 20—30 perc tartózkodási idő után expanziós hűtőn keresztül az elfolyósító tartályba jut az anyag. ',
    'Az exoamilázok, az amilóz vagy amilopektin kizárólag láncvégi glükózát bontják, ennek következtében a keletkező végtermék vagy glükóz (glükoamiláz és a-glükozidáz), vagy maltóz és p-dextrin (P-amiláz). A P-amiláz és a glükoamiláz ezen kívül a bontáskor keletkező a-konfigurációjú maltózt p-konfigurációjúvá konvertálja.',
    'Hőcsere',
    'Hűtővíz',
    'A melegítés helyett közvetlen gőzbevezetést alkalmaztak, és nyomás alatt, folytonos keverés közben melegítették fel a szuszpenziót 100-175 °C-ra. Ezzel a módszerrel a szuszpenzió néhány másodperc alatt elérte a kívánt hőmérsékletet, az enzimes bontásra a hidrolizáló reaktorban került sor. A közvetlen gőzbevezetés elősegítette a keményítőszemcsék jobb feltárását, és ezáltal könnyebbé vált a diszpergálás. A nem hőérzékeny enzimet már felmelegítés előtt bekeverték a szuszpenzióba.',
    'A hidrolízist savas oldat (általában HCl) vagy enzim (α-amiláz) katalizálhatja. Egyes hidrolízis folyamat eljárások mind savval, mind enzimmel katalizált hidrolízist alkalmaznak. A savkatalizált hidrolízissel előállított maltodextrinek erősen hajlamosak a retrográdra, azaz a köd képződésére. A lineáris keményítőtöredékek az alacsony DE-tartalmú savhidrolizátumokban elég nagyok ahhoz, hogy oldhatatlan aggregátumok képződjenek. Ezek a retrográd részecskék a maltodextrin oldatok homályossá válását eredményezik, ez az állapot egyes alkalmazásokban azonban nem kívánatos. A keményítő savazásához sósavat vagy salétromsavat használnak. A savmennyiség a gyártandó dextrin minőségétől függ. A savat erősen felhígítják vízzel és keverés közben sűrített levegővel a keményítőre adagolják. A savazott keményítőt (pH=2, Béo=20) 24 óráig tárolják, hogy a sav jobban behatoljon a keményítőszemcsékbe és utána a savazott keményítőt előszárítják lehetőleg 1%-ra. Ezt a műveletet vakumdobszítákban, ill. szárítókban végzik és általában 1,5-2 óra alatt csökken a keményítő nedvességtartalma a kívánt értékre.',
    'Ph beállító tartály',
    'Finizym adagolás szivattyúval áramlásmérőn keresztül',
    'A Finizym® W 2X egy lizofoszfolipáz. Javítja a lizofoszfolipideket tartalmazó szirupok szűrési tulajdonságait. A Finizym® ​​W 2X magasabb szűrési sebességet, tisztább szűrleteket biztosít, és nem befolyásolja a szirupok szőlőcukorszintjét.',
    'A keményítőből keverőkádban kb. 20 °Bé sűrűségű keményítőtejet állítanak elő. A keményítőszuszpenziót 0,6—1,0% mennyiségű, légszáraz keményítőre számított H2SO4 jelenlétében 2,5 bar nyomáson gőzzel hidrolizálják. Az elcukrosítás mértékét jódpróbával ellenőrzik. Ismeretes, hogy a keményítő jóddal kék színreakciót ad. A cukrosítás tehát akkor megfelelő, ha a jód a keményítő hatására sárgáspiros színű marad.',
    'Szállytás szivattyúval',
    'Szüredék tartály',
    'A klasszikus, szakaszos eljárás szerint a szörp színanyagainak megkötésére aktív szenet használnak, ez adszorbeálja a színezőanyagokat, és így tisztítja a szörplevet. A gyártás során több részletben adagolják a léhez az aktív szenet. Először a semlegesítőkádban. Szűrés előtt pedig lassú keverés közben pihentetik a szörplevet az aktív szénnel. A szűrés során gipsszel együtt az aktív szén is eltávolítható.',
    'Az aktív szén (vagy aktivált szén) nagy felületű, porózus szerkezetű szén a különböző káros anyagok megkötésére.',
    'Az elcukrosított, semlegesített és aktív szénnel kezelt szörplevet az ismert keretes szűröpréseken leszűrik. A leszűrt szörplé vákuumos bepárlókészülékbe kerül. A bepárlás és a szűrés több fokozatú. A nyert kész szörp szárazanyag-tartalma kb. 80%.',
    'Hőcserélő (hűtés)',
    'Hűtővíz',
    'Az oldatban levő szennyeződéseket ioncserélő ágyakon távolítjuk el. A tisztításra kation- és anioncserélő gyantákat alkalmazunk. Kationcserélő gyantaként erősen savas szulfonsav-csoportokat tartalmazó, anioncserélőként enyhén bázikus műgyantákat használunk. Az ioncserélő gyanták megkötik a szervetlen kationokat és anionokat, az aminosavakat, a hidroxi-metil-furfurolt és a színező anyagokat. A kation- és anioncserélő ágyakon átvezetett szörp már nem tartalmaz idegen anyagokat, színtelen és kellemes ízű. Az ioncserés szörplé tisztítás egyszerűsíti a folyamatot, kiküszöbölve a többlépcsős derítést és szűrési műveleteket, lehetővé téve, hogy jó minőségű keményítőszörp nemcsak burgonyakeményítőből, hanem kukorica-, búza- vagy más keményítőféleségből is előállítható legyen.',
    'Finomító tartály (keverős)',
    'A bepárlás: más néven sűrítés, sűrítmény előállítása. A bepárlás (sűrítés) olyan kalorikus művelet, ahol híg leveket forralnak koncentráció növelés céljából. A hőközlés, forralás eredményeképpen csökken az oldat oldószertartalma, így a visszamaradó anyag töményebb-, nagyobb koncentrációjú sűrítmény lesz. A bepárlás során az előkészített híg levet meghatározott ütemben és mennyiségben az anyagtérbe vezetik. Ezzel egyidőben megkezdik a gőz bevezetését is a fűtőtérbe. A fűtőgőz a híg levet tartósan forralja. Forralással párák (gőzök) szabadulnak fel a léből, így az egyre töményebb lesz. A forralást a kívánt koncentráció (töménység) eléréséig folytatják.',
    'Kondenz',
    'Előmelegítés lemezes hőcserélőn',
    'Zsákos szűrő (2 db)',
    'Szárítókamrába juttatás porlasztófejekkel (4 db)',
    'Defrost hőcserélő (elektromos)',
    'Környezeti levegő',
    'Kilépő levegő',
    'Perforált lemezre folyamatosan betáplált szemcsés, nedves anyag a lemez alá befúvott forró levegőáramban szárad.  A berendezés légrugókon ritmikusan mozog, és az anyagot a kimenet felé lökdösi. Az így kialakuló úgynevezett fél-fluid ágyban az anyag- és hőátadás intenzív, a száradás gyors, miközben a termék egyenletesen halad végig a készüléken. A perforált lemez alatti tér két részre osztásával, a második részbe hideg levegő fújható, és ebben a szekcióban a termék csomagolásra alkalmas hőmérsékletre hűthető. A tartózkodási idő jellemzően 10 perc körüli, de akár több órás szárítási idő is beállítható nehezen eltávolítható kötött nedvesség esetén. A levegő hőmérséklete  –15ºC  és +600ºC között, a gáz sebessége 0,2 – 3 m/s tartományban változtatható.',
    'Perforált lemezre folyamatosan betáplált szemcsés, nedves anyag a lemez alá befúvott forró levegőáramban szárad.  A berendezés légrugókon ritmikusan mozog, és az anyagot a kimenet felé lökdösi. Az így kialakuló úgynevezett fél-fluid ágyban az anyag- és hőátadás intenzív, a száradás gyors, miközben a termék egyenletesen halad végig a készüléken. A perforált lemez alatti tér két részre osztásával, a második részbe hideg levegő fújható, és ebben a szekcióban a termék csomagolásra alkalmas hőmérsékletre hűthető. A tartózkodási idő jellemzően 10 perc körüli, de akár több órás szárítási idő is beállítható nehezen eltávolítható kötött nedvesség esetén. A levegő hőmérséklete  –15ºC  és +600ºC között, a gáz sebessége 0,2 – 3 m/s tartományban változtatható.',
    'Vibrációs sziták szitafenék gyors vibrálása miatt a szitálandó anyagból a kívánt szemcseméretű részek átesnek a szita nyílásán. A mindenkori optimális szitálási viszonyok a szitán beállíthatók. A lejtős felületű sziták mellett beváltak a dupla hajtóművel ellátott vízszintes elrendezésű sziták. Ezek kis építési magasság mellett nagy hatásfokkal szitálnak. A sziták anyaga, hajtóművei, rugózási viszonyai megfelelnek a hozzá csatlakozó vibrációs szállítóberendezéseknek. A szitafelületek hasznos felülete 0,16 – 10 m2 között lehetnek.',
    'A pörkölés körforgó gőzzel fűtött pörkölődobban folyik. Az első hűtött anyagot szitálják és a nedvesítőbe vezetik. A feldúsított dextrint szitálják, utána zsákolják. A légárammal és a párákkal felszívott anyagot tömlős szűrőkben választják le.',
    'A feldúsított dextrint szitálják, utána zsákolják. A légárammal és a párákkal felszívott anyagot tömlős szűrőkben választják le. Az előírt nedvességtartalomra beállított dextrint megszitáljuk, és papírzsákba csomagolva forgalmazzuk.',
    'Műszer levegő',
    'Zsák automata adagolása'


   

]

