import React, { useEffect, useState, useContext } from 'react';
import './MaltoBuza.css';
import { appContext } from '../../App'
import Header from '../../components/Header'
import fire from '../../database/Firebase'
import choose from '../../assets/choose.jpg'
import MaltoBuzaFolyamat2 from './MaltoBuzaFolyamat2'
import MaltoBuzaParams from './MaltoBuzaParams'
import Eredmeny from '../Eredmeny'
import 'firebase/functions';

export const maltoBuzaContext = React.createContext([{}, () => {}])

let resultBase = []

const MaltoBuzaContainer = (props) => {

    //appContext
    const [ 
      appContactList, setAppContactList, actualUser, setActualUser, actualName, setActualName, updaterId, setUpdaterId, eredmenyData, setEredmenyData, submitted, setSubmitted, maltoBuza, setMaltoBuza,
      kemenyitoSzarazanyag, setKemenyitoSzarazanyag, kemenyitoHo, setKemenyitoHo, hokezelesHo, setHokezelesHo, hokezelesIdo, setHokezelesIdo,
      folyositasHo, setFolyositasHo, folyositasIdo, setFolyositasIdo, dextHo, setDextHo, dextIdo, setDextIdo,
      kukKemenyitoSzarazanyag, setKukKemenyitoSzarazanyag, kukKemenyitoHo, setKukKemenyitoHo, kukHokezelesHo, setKukHokezelesHo, kukHokezelesIdo, setKukHokezelesIdo,
      kukFolyositasHo, setKukFolyositasHo, kukFolyositasIdo, setKukFolyositasIdo, kukDextHo, setKukDextHo, kukDextIdo, setKukDextIdo,
      neutralKemenyitoSzarazanyag, setNeutralKemenyitoSzarazanyag, neutralKemenyitoHo, setNeutralKemenyitoHo, neutralHokezelesHo, setNeutralHokezelesHo,
      neutralHokezelesIdo, setNeutralHokezelesIdo, neutralFolyositasIdo, setNeutralFolyositasIdo, neutralDextHo, setNeutralDextHo,
      neutralCukrositasHo, setNeutralCukrositasHo,
      results, setResults
     ] = useContext(appContext)

      const sendMail = fire.functions().httpsCallable('sendEmail')
      const { history } = props;

    const handleLogout = (value) => {
        fire.auth().signOut().then(function() {
          }, function(error) {
            console.error('Sign Out Error', error);
          });
        const { history } = props;
        history.push('home');
      }

      const handleHome = (value) => {
        const { history } = props;
        history.push('choose');
      }

    const handlePDF = (e) => {
        e.preventDefault()
        
        const { history } = props;
        history.push('pdf');
      }

      const resetBase = () => {
        resultBase.length = 0
        console.log('resetBase ', resultBase)
      }
    
  return (
    <div >
      {actualName == undefined ? '' : <Header handleLogout = {handleLogout} handleHome = {handleHome} userName = {actualName}></Header>}
      <div className = 'folyamatContainer'>
        
        <div className = 'folyamat'>
          <MaltoBuzaFolyamat2 history = {history}></MaltoBuzaFolyamat2>
        </div>
        <div className = 'paramContainer'>
          <MaltoBuzaParams resetBase = {resetBase}></MaltoBuzaParams>
        </div>
        <div className = 'eredmenyContainer'>
          <Eredmeny handlePDF = {handlePDF} resultBase = {resultBase} ></Eredmeny>
        </div>
      </div>
    </div>
  );
  
}

export default MaltoBuzaContainer;
