import React, { useEffect, useState, useContext, Fragment  } from 'react';
import '../App.css';
import { appContext } from '../App'
import { Link } from 'react-router-dom';


function Popup(props){

  //appContext
  const [ 
    appContactList, setAppContactList, actualUser, setActualUser, actualName, setActualName, updaterId, setUpdaterId, eredmenyData, setEredmenyData, submitted, setSubmitted, maltoBuza, setMaltoBuza,
    kemenyitoSzarazanyag, setKemenyitoSzarazanyag, kemenyitoHo, setKemenyitoHo, hokezelesHo, setHokezelesHo, hokezelesIdo, setHokezelesIdo,
    folyositasHo, setFolyositasHo, folyositasIdo, setFolyositasIdo, dextHo, setDextHo, dextIdo, setDextIdo,
    kukKemenyitoSzarazanyag, setKukKemenyitoSzarazanyag, kukKemenyitoHo, setKukKemenyitoHo, kukHokezelesHo, setKukHokezelesHo, kukHokezelesIdo, setKukHokezelesIdo,
    kukFolyositasHo, setKukFolyositasHo, kukFolyositasIdo, setKukFolyositasIdo, kukDextHo, setKukDextHo, kukDextIdo, setKukDextIdo,
    neutralKemenyitoSzarazanyag, setNeutralKemenyitoSzarazanyag, neutralKemenyitoHo, setNeutralKemenyitoHo, neutralHokezelesHo, setNeutralHokezelesHo,
    neutralHokezelesIdo, setNeutralHokezelesIdo, neutralFolyositasIdo, setNeutralFolyositasIdo, neutralDextHo, setNeutralDextHo,
    neutralCukrositasHo, setNeutralCukrositasHo,
    results, setResults, params, setParams, 
    sulySzazalek, setSulySzazalek, szakadas, setSzakadas, szazalekEredmeny, setSzazalekEredmeny, energiaFelhasznalas, setEnergiaFelhasznalas, energiaHatekonysag, setEnergiaHatekonysag,
    jsonList, setJsonList, pdfList, setPdfList, foundPdfList, setFoundPdfList, pdfListNeutral, setPdfListNeutral, jsonListNeutral, setJsonListNeutral, jsonTextListNeutral, setJsonTextListNeutral,
    popup, setPopup, popupVisibility, setPopupVisibility, popupMessage, setPopupMessage, jsonTextList, setJsonTextList
  ] = useContext(appContext)

  useEffect(() => {

  }, [])

  return(
      <div className = 'popup' id = {popup}>  
        <div className = 'inPopup' >  
            <button className="btn-lg btn-outline-light" id = 'popupButton' onClick={(e) => props.popupHide()}>OK</button>
            <h1>{popupMessage}</h1>
            <div className = 'popupLoginButton'>
              {Object.keys(actualName).length === 0 ? <Link to="/">Bejelentkezés</Link> : ''}
            </div>
          </div>
          <div className = 'popupImg' >
        </div>
      </div>
    
    )
}

export default Popup