import React, { useEffect, useState, useContext  } from 'react';
import { Link } from 'react-router-dom';
import { appContext } from '../App'
import '../App.css';
import fire from '../database/Firebase'
import login from '../assets/login.jpg'
import { loadContacts, findContact } from '../database/DatabaseOp'

let ref = fire.database().ref('contacts')
let role = ''

const LoginScreen = (props) => {

    //appContext
    const [ 
      appContactList, setAppContactList, actualUser, setActualUser, actualName, setActualName, updaterId, setUpdaterId, eredmenyData, setEredmenyData, submitted, setSubmitted, maltoBuza, setMaltoBuza,
      kemenyitoSzarazanyag, setKemenyitoSzarazanyag, kemenyitoHo, setKemenyitoHo, hokezelesHo, setHokezelesHo, hokezelesIdo, setHokezelesIdo,
      folyositasHo, setFolyositasHo, folyositasIdo, setFolyositasIdo, dextHo, setDextHo, dextIdo, setDextIdo,
      kukKemenyitoSzarazanyag, setKukKemenyitoSzarazanyag, kukKemenyitoHo, setKukKemenyitoHo, kukHokezelesHo, setKukHokezelesHo, kukHokezelesIdo, setKukHokezelesIdo,
      kukFolyositasHo, setKukFolyositasHo, kukFolyositasIdo, setKukFolyositasIdo, kukDextHo, setKukDextHo, kukDextIdo, setKukDextIdo,
      neutralKemenyitoSzarazanyag, setNeutralKemenyitoSzarazanyag, neutralKemenyitoHo, setNeutralKemenyitoHo, neutralHokezelesHo, setNeutralHokezelesHo,
      neutralHokezelesIdo, setNeutralHokezelesIdo, neutralFolyositasIdo, setNeutralFolyositasIdo, neutralDextHo, setNeutralDextHo,
      neutralCukrositasHo, setNeutralCukrositasHo,
      results, setResults
     ] = useContext(appContext)


    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
  
  

    const clearErrors = () => {
      setEmailError('')
      setPasswordError('')
    }

    const clearInputs = () => {
      setEmail('')
      setPassword('')
    }




    const setAct = (loginUser) => {
      
      fire.auth().onAuthStateChanged((user) => {
        if(user){
          clearInputs()
          findContact(ref, loginUser)
            .then((value) => {
              if(value == null){
                console.log('user NOT found ', loginUser)
              }else{
                //a value egy jsonObject, ennek a keys-edik indexén lévő value-t keresem     
                var keys = Object.keys(value);
                setActualUser(value[keys])
                setActualName(value[keys].name)
                role = value[keys].role

                //Admin jogosultság ellenőrzése
                if(role == 'admin'){
                  const { history } = props;
                  history.push('admin');
                }else{
                  const { history } = props;
                  history.push('choose');
                }
              }
            })
            .catch((error) => {
            console.log('firstSearch error ', error)
             });
        }else{
          setActualUser('')
        }
      })
    }

     const handleLogin = (e) => {
        e.preventDefault()
       clearErrors()
       let loginUser = {}
       loginUser.email = email

      fire
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then((value) => {
         //a usernek az adatbázisban vannak paraméterei, ki kell keresni és beállítani actualusernek, Admin jogosultság ellenőrzése
         setAct(loginUser)
        })
        .catch((err) => {
          console.log('login error ', err)
          switch(err.code){
            case 'auht/invalid-email' : 
            case 'auth/user-disabled' :
            case 'auth/user-not-found' :
              setEmailError('Nem találjuk a fiókját... regisztrált már?')
               break
            case 'auth/wrong-password' :
              setPasswordError('Nem megfelelő jelszó')
              break
          }
        })   

    }

    const handleLogout = () => {
      fire.auth.signOut()
    }

    return(
      <div>
        <div className ='loginForm'>
        <form onSubmit={handleLogin} >
        <ul className="form-container">
          <li>
            <h2>Jelentkezzen be!</h2>
          </li>
          <li>
            <label htmlFor="email">
              Email
            </label>
            <input type="email" name="email" id="email" required onChange={(e) => setEmail(e.target.value)}>
            </input>
            <p className = 'errorMsg'>{emailError}</p>
          </li>
          <li>
            <label htmlFor="password">Password</label>
            <input type="password" id="password" name="password" required onChange={(e) => setPassword(e.target.value)}>
            </input>
            <p className = 'errorMsg'>{passwordError}</p>
          </li>
          <li>
            <button type="submit" className="button primary">Bejelentkezés</button>
          </li>
          <li>
            Még nincs fiókja?
          </li>
          <li>
            <Link to= "register"  className="button secondary text-center" >Regisztráljon!</Link>
          </li>
        </ul>
      </form>
      </div>
      <img className = 'appContainer'  src={login} alt="home1"></img>
    </div>
    )
}

export default LoginScreen