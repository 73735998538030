import React, { useEffect, useState, useContext } from 'react';
import { appContext } from '../App'
import '../App.css';
import pdfAlap from '../assets/pdfAlap.jpg'
import Pdf from 'react-to-pdf'

const ref = React.createRef()
let today = new Date().toISOString().slice(0, 16)

const PDFGeneratorNeutral = (props) => {

//appContext
const [ 
    appContactList, setAppContactList, actualUser, setActualUser, actualName, setActualName, updaterId, setUpdaterId, eredmenyData, setEredmenyData, submitted, setSubmitted, maltoBuza, setMaltoBuza,
    kemenyitoSzarazanyag, setKemenyitoSzarazanyag, kemenyitoHo, setKemenyitoHo, hokezelesHo, setHokezelesHo, hokezelesIdo, setHokezelesIdo,
    folyositasHo, setFolyositasHo, folyositasIdo, setFolyositasIdo, dextHo, setDextHo, dextIdo, setDextIdo,
    kukKemenyitoSzarazanyag, setKukKemenyitoSzarazanyag, kukKemenyitoHo, setKukKemenyitoHo, kukHokezelesHo, setKukHokezelesHo, kukHokezelesIdo, setKukHokezelesIdo,
    kukFolyositasHo, setKukFolyositasHo, kukFolyositasIdo, setKukFolyositasIdo, kukDextHo, setKukDextHo, kukDextIdo, setKukDextIdo,
    neutralKemenyitoSzarazanyag, setNeutralKemenyitoSzarazanyag, neutralKemenyitoHo, setNeutralKemenyitoHo, neutralHokezelesHo, setNeutralHokezelesHo,
    neutralHokezelesIdo, setNeutralHokezelesIdo, neutralFolyositasIdo, setNeutralFolyositasIdo, neutralDextHo, setNeutralDextHo,
    neutralCukrositasHo, setNeutralCukrositasHo,
    results, setResults, params, setParams, 
    sulySzazalek, setSulySzazalek, szakadas, setSzakadas, szazalekEredmeny, setSzazalekEredmeny, energiaFelhasznalas, setEnergiaFelhasznalas, energiaHatekonysag, setEnergiaHatekonysag
 ] = useContext(appContext)

    const options = {
        orientation: 'landscape',
        unit: 'in',
        format: [840,580],
        scale: 0.5,

    };

    let pdfName = 'TRDVSIM_' + today

   return (
       <div>
            <div className = 'pdfSize' ref = {ref}>
                <div className = 'pdfContext'>
                <Pdf targetRef = {ref} filename = {pdfName} options={options}>
                    {({ toPdf }) => <button classname = 'btn btn-success btn-lg' id = 'pdfButton' onClick = {toPdf} >Szimulációs jegyzőkönyv mentése</button>}
                </Pdf>
                <div className = 'pdfTable'>
                    <div className = 'headFull'>
                        <div className = 'row100'><h1>Neutrális alkohol előállításának szimulációs jegyzőkönyve</h1></div>
                        <div className = 'row100'><h1>Pályázati azonosító: GINOP-2.2.1-15-2017-00048</h1><h2>Innovatív fejlesztések a gabonaalapú élelmiszeripari és ipari kutatások területén</h2></div>
                        <div className = 'columnWrapper'>
                            <div className = 'row50Title'><h3>Felhasználó azonosítója</h3></div>
                            <div className = 'row50Data'><h4>{actualUser.email}</h4></div>
                        </div>
                        <div className = 'columnWrapper'>
                            <div className = 'row50Title'><h3>Szimuláció időpontja</h3></div>
                            <div className = 'row50Data'><h4>{today}</h4></div>
                        </div>
                        <div className = 'headWrapper'>
                            <div className = 'row70Title'><h2>Paraméterek</h2></div>
                            <div className = 'row30Title'><h2>bevitt értékek</h2></div>
                        </div>
                        <div className = 'columnWrapper'>
                            <div className = 'row70Title'><h3>{eredmenyData[0].title}</h3></div>
                            <div className = 'row30Title'><h4>{eredmenyData[0].data}</h4></div>
                        </div>
                        <div className = 'columnWrapper'>
                            <div className = 'row70Title'><h3>{eredmenyData[1].title}</h3></div>
                            <div className = 'row30Title'><h4>{eredmenyData[1].data}</h4></div>
                        </div>
                        <div className = 'columnWrapper'>
                            <div className = 'row70Title'><h3>{eredmenyData[2].title}</h3></div>
                            <div className = 'row30Title'><h4>{eredmenyData[2].data}</h4></div>
                        </div>
                        <div className = 'columnWrapper'>
                            <div className = 'row70Title'><h3>{eredmenyData[3].title}</h3></div>
                            <div className = 'row30Title'><h4>{eredmenyData[3].data}</h4></div>
                        </div>
                        <div className = 'columnWrapper'>
                            <div className = 'row70Title'><h3>{eredmenyData[4].title}</h3></div>
                            <div className = 'row30Title'><h4>{eredmenyData[4].data}</h4></div>
                        </div>
                        <div className = 'columnWrapper'>
                            <div className = 'row70Title'><h3>{eredmenyData[5].title}</h3></div>
                            <div className = 'row30Title'><h4>{eredmenyData[5].data}</h4></div>
                        </div>
                        <div className = 'columnWrapper'>
                            <div className = 'row70Title'><h3>{eredmenyData[6].title}</h3></div>
                            <div className = 'row30Title'><h4>{eredmenyData[6].data}</h4></div>
                        </div>
                         <div className = 'headWrapper'>
                            <div className = 'row70Title'><h2>A szimuláció eredményei</h2></div>
                            <div className = 'row15Title'><h2>abszolút eltérés</h2></div>
                            <div className = 'row15Title'><h2>relatív eltérés [%]</h2></div>
                        </div>
                        <div className = 'columnWrapper'>
                            <div className = 'row70Title'><h3>Keményítő szuszpenzió készítése – Csirizesítés(szárazanyag tartalom érték [%])</h3></div>
                            <div className = 'row15Data'><h4>{(results[0].absolute).toFixed(2)}</h4></div>
                            <div className = 'row15Data'><h4>{(results[0].relative).toFixed(2)}</h4></div>
                        </div>
                        <div className = 'columnWrapper'>
                            <div className = 'row70Title'><h3>Keményítő szuszpenzió készítése – Csirizesítés(Hőmérséklet [˚C])</h3></div>
                            <div className = 'row15Data'><h4>{(results[1].absolute).toFixed(2)}</h4></div>
                            <div className = 'row15Data'><h4>{(results[1].relative).toFixed(2)}</h4></div>
                        </div>
                        <div className = 'columnWrapper'>
                            <div className = 'row70Title'><h3>Hőkezelés/hőfeltárás gőzzel (Hőmérséklet [˚C])</h3></div>
                            <div className = 'row15Data'><h4>{(results[2].absolute).toFixed(2)}</h4></div>
                            <div className = 'row15Data'><h4>{(results[2].relative).toFixed(2)}</h4></div>
                        </div>
                        <div className = 'columnWrapper'>
                            <div className = 'row70Title'><h3>Hőkezelés/hőfeltárás gőzzel (Időtartam [s])</h3></div>
                            <div className = 'row15Data'><h4>{(results[3].absolute).toFixed(2)}</h4></div>
                            <div className = 'row15Data'><h4>{(results[3].relative).toFixed(2)}</h4></div>
                        </div>
                        <div className = 'columnWrapper'>
                            <div className = 'row70Title'><h3>Folyósítás (magas hőmérsékletű hőkezelés) folytonos technológia (Hőmérséklet [˚C])</h3></div>
                            <div className = 'row15Data'><h4>{(results[4].absolute).toFixed(2)}</h4></div>
                            <div className = 'row15Data'><h4>{(results[4].relative).toFixed(2)}</h4></div>
                        </div>
                        <div className = 'columnWrapper'>
                            <div className = 'row70Title'><h3>Dextrinizálás/Hidrolizálás (Hőmérséklet [˚C])</h3></div>
                            <div className = 'row15Data'><h4>{(results[5].absolute).toFixed(2)}</h4></div>
                            <div className = 'row15Data'><h4>{(results[5].relative).toFixed(2)}</h4></div>
                        </div>
                        <div className = 'columnWrapper'>
                            <div className = 'row70Title'><h3>Cukrosítás/szacharifikáció (szakaszos) Hőmérséklet [˚C])</h3></div>
                            <div className = 'row15Data'><h4>{(results[6].absolute).toFixed(2)}</h4></div>
                            <div className = 'row15Data'><h4>{(results[6].relative).toFixed(2)}</h4></div>
                        </div>
                        <div className = 'headWrapper'>
                            <div className = 'row100'><h2>Megállapítások</h2></div>
                        </div>
                        <div className = 'columnWrapper'>
                            <div className = 'row70Title'><h3>A szimuláció eredménye</h3></div>
                            <div className = 'row30Title'><h4>{szazalekEredmeny}</h4></div>
                        </div>
                        <div className = 'columnWrapper'>
                            <div className = 'row70Title'><h3>A szimuláció alapján a folyamat szakadásmentes-e</h3></div>
                            <div className = 'row30Title'><h4>{szakadas}</h4></div>
                        </div>
                        <div className = 'columnWrapper'>
                            <div className = 'row70Title'><h3>A paraméterek alapján az energiafelhasználás csökkenthető-e</h3></div>
                            <div className = 'row30Title'><h4>{energiaFelhasznalas}</h4></div>
                        </div>
                        <div className = 'columnWrapper'>
                            <div className = 'row70Title'><h3>A szimuláció alapján a folyamat energiahatékonysága növelhető-e</h3></div>
                            <div className = 'row30Title'><h4>{energiaHatekonysag}</h4></div>
                        </div>
                    </div>

                </div>
                     
             
            </div>
            <img src = {pdfAlap} className = 'pdfImage'></img>
            </div>
        </div>
    )
 
  
}

export default PDFGeneratorNeutral;
