import React, { useEffect, useState, useContext, createRef } from 'react';
import './Neutral.css';
import { appContext } from '../../App'
import { motion, AnimatePresence } from 'framer-motion'
import folyamatabraUres from '../../assets/folyamatabraUres.jpg'
import fNeutral1 from '../../assets/fNeutral1.png'
import fNeutral2 from '../../assets/fNeutral2.png'
import fNeutral3 from '../../assets/fNeutral3.png'
import fNeutral4 from '../../assets/fNeutral4.png'
import fNeutral5 from '../../assets/fNeutral5.png'
import fNeutral6 from '../../assets/fNeutral6.png'
import fNeutral7 from '../../assets/fNeutral7.png'
import fNeutral8 from '../../assets/fNeutral8.png'
import fNeutral9 from '../../assets/fNeutral9.png'
import fNeutral10 from '../../assets/fNeutral10.png'
import fNeutral11 from '../../assets/fNeutral11.png'
import fNeutral12 from '../../assets/fNeutral12.png'
import fNeutral13 from '../../assets/fNeutral13.png'
import fNeutral14 from '../../assets/fNeutral14.png'
import fNeutral15 from '../../assets/fNeutral15.png'
import fNeutral16 from '../../assets/fNeutral16.png'
import fNeutral17 from '../../assets/fNeutral17.png'
import fNeutral18 from '../../assets/fNeutral18.png'
import fNeutral19 from '../../assets/fNeutral19.png'
import fNeutral20 from '../../assets/fNeutral20.png'
import fNeutral21 from '../../assets/fNeutral21.png'
import fNeutral22 from '../../assets/fNeutral22.png'
import fNeutral23 from '../../assets/fNeutral23.png'
import fNeutral24 from '../../assets/fNeutral24.png'
import fNeutral25 from '../../assets/fNeutral25.png'
import fNeutral26 from '../../assets/fNeutral26.png'
import fNeutral27 from '../../assets/fNeutral27.png'
import fNeutral28 from '../../assets/fNeutral28.png'
import rightArrow from '../../assets/rightArrow.png'
import rightArrowHover from '../../assets/rightArrowHover.png'
import leftArrow from '../../assets/leftArrow.png'
import leftArrowHover from '../../assets/leftArrowHover.png'
import downArrow from '../../assets/downArrow.png'
import downArrowHover from '../../assets/downArrowHover.png'
import upArrow from '../../assets/upArrow.png'
import upArrowHover from '../../assets/upArrowHover.png'
import downRightArrow from '../../assets/downRightArrow.png'
import downRightArrowHover from '../../assets/downRightArrowHover.png'
import downLeftArrow from '../../assets/downLeftArrow.png'
import downLeftArrowHover from '../../assets/downLeftArrowHover.png'
import upRightArrow from '../../assets/upRightArrow.png'
import upRightArrowHover from '../../assets/upRightArrowHover.png'
import upLeftArrow from '../../assets/upLeftArrow.png'
import upLeftArrowHover from '../../assets/upLeftArrowHover.png'
import { neutralText } from './NeutralText'
import tudasbazis from '../../assets/tudasbazis.png'

let message = ''

const NeutralFolyamat = (props) => {

            //Context
            const [ 
                appContactList, setAppContactList, actualUser, setActualUser, actualName, setActualName, updaterId, setUpdaterId, eredmenyData, setEredmenyData, submitted, setSubmitted, maltoBuza, setMaltoBuza,
                kemenyitoSzarazanyag, setKemenyitoSzarazanyag, kemenyitoHo, setKemenyitoHo, hokezelesHo, setHokezelesHo, hokezelesIdo, setHokezelesIdo,
                folyositasHo, setFolyositasHo, folyositasIdo, setFolyositasIdo, dextHo, setDextHo, dextIdo, setDextIdo,
                kukKemenyitoSzarazanyag, setKukKemenyitoSzarazanyag, kukKemenyitoHo, setKukKemenyitoHo, kukHokezelesHo, setKukHokezelesHo, kukHokezelesIdo, setKukHokezelesIdo,
                kukFolyositasHo, setKukFolyositasHo, kukFolyositasIdo, setKukFolyositasIdo, kukDextHo, setKukDextHo, kukDextIdo, setKukDextIdo,
                neutralKemenyitoSzarazanyag, setNeutralKemenyitoSzarazanyag, neutralKemenyitoHo, setNeutralKemenyitoHo, neutralHokezelesHo, setNeutralHokezelesHo,
                neutralHokezelesIdo, setNeutralHokezelesIdo, neutralFolyositasIdo, setNeutralFolyositasIdo, neutralDextHo, setNeutralDextHo,
                neutralCukrositasHo, setNeutralCukrositasHo,
                results, setResults, params, setParams, 
                sulySzazalek, setSulySzazalek, szakadas, setSzakadas, szazalekEredmeny, setSzazalekEredmeny, energiaFelhasznalas, setEnergiaFelhasznalas, energiaHatekonysag, setEnergiaHatekonysag,
                jsonList, setJsonList, pdfList, setPdfList, foundPdfList, setFoundPdfList, pdfListNeutral, setPdfListNeutral, jsonListNeutral, setJsonListNeutral, jsonTextListNeutral, setJsonTextListNeutral,
                popup, setPopup, popupVisibility, setPopupVisibility, popupMessage, setPopupMessage, jsonTextList, setJsonTextList
             ] = useContext(appContext)

    const anim1 = { hidden: { scale: 3, x: '200rem', y: '8.5rem' }, visible: { x: '-6rem', y: '8.5rem', scale: .45,  transition: {type: "spring", stiffness: 70,  delay: 0.5, duration: 1 } }, hover: { scale: 0.75} }
   
    const anim2 = { hidden: { scale: 3, x: '200rem', y: '8.5rem' }, visible: { x: '7rem', y: '8.5rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 0.7, duration: 1 } }, hover: { scale: 0.75} }
    
    const anim3 = { hidden: { scale: 3, x: '200rem', y: '-1rem' }, visible: { x: '-6rem', y: '-1rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 0.6, duration: 1 } }, hover: { scale: 0.75} }
  
    const anim4 = { hidden: { scale: 3, x: '200rem', y: '-1rem' }, visible: { x: '7rem', y: '-1rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 0.8, duration: 1 } }, hover: { scale: 0.75} }
    
    const anim5 = { hidden: { scale: 3, x: '200rem', y: '18rem' }, visible: { x: '-6rem', y: '18rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 0.9, duration: 1 } }, hover: { scale: 0.75} }
    
    const anim6 = { hidden: { scale: 3, x: '200rem', y: '18rem' }, visible: { x: '7rem', y: '18rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 1.0, duration: 1 } }, hover: { scale: 0.75} }

    const anim7 = { hidden: { scale: 3, x: '200rem', y: '8.5rem' }, visible: { x: '20rem', y: '8.5rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 1.1, duration: 1 } }, hover: { scale: 0.75} }

    const anim8 = { hidden: { scale: 3, x: '200rem', y: '8.5rem' }, visible: { x: '33rem', y: '8.5rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 1.2, duration: 1 } }, hover: { scale: 0.75} }
      
    const anim9= { hidden: { scale: 3, x: '200rem', y: '18rem' }, visible: { x: '33rem', y: '18rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 1.3, duration: 1 } }, hover: { scale: 0.75} }
      
    const anim10= { hidden: { scale: 3, x: '200rem', y: '8.5rem' }, visible: { x: '46rem', y: '8.5rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 1.4, duration: 1 } }, hover: { scale: 0.75} }
      
    const anim11= { hidden: { scale: 3, x: '200rem', y: '8.5rem' }, visible: { x: '59rem', y: '8.5rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 1.5, duration: 1 } }, hover: { scale: 0.75} }
     
    const anim12= { hidden: { scale: 3, x: '200rem', y: '-1rem' }, visible: { x: '59rem', y: '-1rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 1.6, duration: 1 } }, hover: { scale: 0.75} }
     
    const anim13= { hidden: { scale: 3, x: '200rem', y: '8.5rem' }, visible: { x: '85rem', y: '8.5rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 1.7, duration: 1 } }, hover: { scale: 0.75} }
     
    const anim14= { hidden: { scale: 3, x: '200rem', y: '-1rem' }, visible: { x: '72rem', y: '-1rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 1.8, duration: 1 } }, hover: { scale: 0.75} }
     
    const anim15= { hidden: { scale: 3, x: '200rem', y: '-1rem' }, visible: { x: '85rem', y: '-1rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 1.9, duration: 1 } }, hover: { scale: 0.75} }
     
    const anim16= { hidden: { scale: 3, x: '200rem', y: '-1rem' }, visible: { x: '98rem', y: '-1rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 2.0, duration: 1 } }, hover: { scale: 0.75} }
     
    const anim17= { hidden: { scale: 3, x: '200rem', y: '18rem' }, visible: { x: '72rem', y: '18rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 2.1, duration: 1 } }, hover: { scale: 0.75} }
     
    const anim18= { hidden: { scale: 3, x: '200rem', y: '18rem' }, visible: { x: '85rem', y: '18rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 2.2, duration: 1 } }, hover: { scale: 0.75} }
     
    const anim19= { hidden: { scale: 3, x: '200rem', y: '18rem' }, visible: { x: '98rem', y: '18rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 2.3, duration: 1 } }, hover: { scale: 0.75} }
     
    const anim20= { hidden: { scale: 3, x: '200rem', y: '8.5rem' }, visible: { x: '111rem', y: '8.5rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 2.4, duration: 1 } }, hover: { scale: 0.75} }
     
    const anim21= { hidden: { scale: 3, x: '200rem', y: '-1rem' }, visible: { x: '111rem', y: '-1rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 2.5, duration: 1 } }, hover: { scale: 0.75} }
     
    const anim22= { hidden: { scale: 3, x: '200rem', y: '8.5rem' }, visible: { x: '124rem', y: '8.51rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 2.6, duration: 1 } }, hover: { scale: 0.75} }
     
    const anim23= { hidden: { scale: 3, x: '200rem', y: '-1rem' }, visible: { x: '124rem', y: '-1rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 2.7, duration: 1 } }, hover: { scale: 0.75} }
    
    const anim24= { hidden: { scale: 3, x: '200rem', y: '-1rem' }, visible: { x: '138rem', y: '-1rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 2.8, duration: 1 } }, hover: { scale: 0.75} }
    
    const anim25= { hidden: { scale: 3, x: '200rem', y: '18rem' }, visible: { x: '124rem', y: '18rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 2.9, duration: 1 } }, hover: { scale: 0.75} }
    
    const anim26= { hidden: { scale: 3, x: '200rem', y: '18rem' }, visible: { x: '111rem', y: '18rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 3.0, duration: 1 } }, hover: { scale: 0.75} }
    
    const anim27= { hidden: { scale: 3, x: '200rem', y: '18rem' }, visible: { x: '138rem', y: '18rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 3.1, duration: 1 } }, hover: { scale: 0.75} }
    
    const anim28= { hidden: { scale: 3, x: '200rem', y: '8.5rem' }, visible: { x: '138rem', y: '8.5rem', scale: .45,  transition: {type: "spring", stiffness: 70, delay: 3.2, duration: 1 } }, hover: { scale: 0.75} }
    
    const animTudasbazis= { hidden: { scale: 3, x: '200rem', y: '40rem' }, visible: { x: '59rem', y: '36rem', scale: .65,  transition: {type: "spring", stiffness: 70, delay: 4.0, duration: 1 } }, hover: { scale: 0.75} }
    
    
    {/*-------------------------------------------------------------------NYILAK---------------------------------------------------------------------------------------------------*/}

    const anim101= { hidden: { x: '-50px', y: '0px', opacity: 0, scale: .8 }, visible: { x: '-20px', y: '0px', opacity: 1, scale: .8, transition: {type: "spring", stiffness: 70, delay: 4.0, duration: 1 } }, hover: { scale: 1} }
      
    const anim102= { hidden: { x: '-190px', y: '-80px', opacity: 0, scale: 1 }, visible: { x: '-200px', y: '140px', opacity: 1, scale: 1, transition: {type: "spring", stiffness: 70, delay: 4.0, duration: 1 } }, hover: { scale: 1.2} }
     
    const anim104= { hidden: { x: '-190px', y: '-80px', opacity: 0, scale: 1 }, visible: { x: '-200px', y: '-50px', opacity: 1, scale: 1, transition: {type: "spring", stiffness: 70, delay: 4.0, duration: 1 } }, hover: { scale: 1.2} }
     
    const anim105= { hidden: { x: '-190px', y: '-80px', opacity: 0, scale: .8 }, visible: { x: '-110px', y: '80px', opacity: 1, scale: .8, transition: {type: "spring", stiffness: 70, delay: 4.0, duration: 1 } }, hover: { scale: 1} }
     
    const anim106= { hidden: { x: '-190px', y: '-80px', opacity: 0, scale: .8 }, visible: { x: '-310px', y: '80px', opacity: 1, scale: .8, transition: {type: "spring", stiffness: 70, delay: 4.0, duration: 1 } }, hover: { scale: 1} }
     
    const anim107= { hidden: { x: '-190px', y: '-80px', opacity: 0, scale: .8 }, visible: { x: '-110px', y: '-90px', opacity: 1, scale: .8, transition: {type: "spring", stiffness: 70, delay: 4.0, duration: 1 } }, hover: { scale: 1} }
     
    const anim109= { hidden: { x: '-190px', y: '-80px', opacity: 0, scale: .8 }, visible: { x: '-310px', y: '-90px', opacity: 1, scale: .8, transition: {type: "spring", stiffness: 70, delay: 4.0, duration: 1 } }, hover: { scale: 1} }
     
    const anim110= { hidden: { x: '-250px', y: '0px', opacity: 0, scale: .8 }, visible: { x: '-290px', y: '0px', opacity: 1, scale: .8, transition: {type: "spring", stiffness: 70, delay: 4.0, duration: 1 } }, hover: { scale: 1} }
      
    const { history } = props;

    const elementClick = (value) => {
        setPopupMessage(neutralText[value])
        setPopup('popupShow')
        setPopupVisibility(true)
    }

    const tudasbazisClick = (value) => {
        console.log('tudasbazis')
        
        history.push('neutralTudasbazis');
     }

     const callPopup = () => {
        setPopupMessage('Kérem jelentkezzen be!')
        setPopup('popupShow')
        setPopupVisibility(true)
      }

    const hidePopup = (e) => {
        e.preventDefault()
        console.log('hidePopup ')
        setPopup(false)
      }
    
   
   
  return (
    <div >
      <div className = 'title'>
        <h1>A neutrális alkohol gyártási technológiájának folyamatábrája</h1>
      </div>
      {Object.keys(actualName).length === 0 ? callPopup() : (
        <>
            <div className = 'folyamatSize'>

      <motion.div  id = 'step1' variants = {animTudasbazis} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={tudasbazis} alt="home1" onClick={(e) => tudasbazisClick()} ></img> 
                </div>
                <div >

                </div>
            </div>
        </motion.div>

      <motion.div  id = 'step1' variants = {anim28} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fNeutral28} alt="home1" onClick={(e) => elementClick(27)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim101} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={rightArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={rightArrow} alt="home1"  ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>

      <motion.div  id = 'step1' variants = {anim27} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fNeutral27} alt="home1" onClick={(e) => elementClick(26)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim101} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={rightArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={rightArrow} alt="home1"  ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
      <motion.div  id = 'step1' variants = {anim26} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fNeutral26} alt="home1" onClick={(e) => elementClick(25)} ></img> 
                </div>
                <div >

                </div>
            </div>
        </motion.div>
      <motion.div  id = 'step1' variants = {anim25} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fNeutral25} alt="home1" onClick={(e) => elementClick(24)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim101} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={rightArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={rightArrow} alt="home1" ></img> 
                </motion.div>
                <motion.div  id = 'step1' variants = {anim110} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={leftArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={leftArrow} alt="home1"  ></img> 
                </motion.div>

                </div>
            </div>
        </motion.div>
      <motion.div  id = 'step1' variants = {anim24} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fNeutral24} alt="home1" onClick={(e) => elementClick(23)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim110} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={leftArrowHover} alt="home1" ></img> 
                    <img  className = 'motionArrow' src={leftArrow} alt="home1"  ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
      <motion.div  id = 'step1' variants = {anim23} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fNeutral23} alt="home1" onClick={(e) => elementClick(22)} ></img> 
                </div>
                <div >

                </div>
            </div>
        </motion.div>
      <motion.div  id = 'step1' variants = {anim22} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fNeutral22} alt="home1" onClick={(e) => elementClick(21)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim101} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={rightArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={rightArrow} alt="home1"  ></img> 
                </motion.div>
                <motion.div  id = 'step1' variants = {anim102} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={downArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={downArrow} alt="home1"  ></img> 
                </motion.div>
                <motion.div  id = 'step1' variants = {anim104} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={upArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={upArrow} alt="home1"  ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
      <motion.div  id = 'step1' variants = {anim20} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fNeutral20} alt="home1" onClick={(e) => elementClick(19)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim101} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={rightArrowHover} alt="home1" ></img> 
                    <img  className = 'motionArrow' src={rightArrow} alt="home1" ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
      <motion.div  id = 'step1' variants = {anim13} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fNeutral13} alt="home1" onClick={(e) => elementClick(12)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim101} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={rightArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={rightArrow} alt="home1" ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>

        <motion.div  id = 'step1' variants = {anim21} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fNeutral21} alt="home1" onClick={(e) => elementClick(20)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim102} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={downArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={downArrow} alt="home1"  ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
        <motion.div  id = 'step1' variants = {anim14} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fNeutral14} alt="home1" onClick={(e) => elementClick(13)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim105} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={downRightArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={downRightArrow} alt="home1"  ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
        <motion.div  id = 'step1' variants = {anim15} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fNeutral15} alt="home1" onClick={(e) => elementClick(14)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim102} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={downArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={downArrow} alt="home1"  ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
        <motion.div  id = 'step1' variants = {anim16} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fNeutral16} alt="home1" onClick={(e) => elementClick(15)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim106} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={downLeftArrowHover} alt="home1" ></img> 
                    <img  className = 'motionArrow' src={downLeftArrow} alt="home1" ></img> 
                </motion.div>
                <motion.div  id = 'step1' variants = {anim105} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={downRightArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={downRightArrow} alt="home1"  ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
        <motion.div  id = 'step1' variants = {anim17} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fNeutral17} alt="home1" onClick={(e) => elementClick(16)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim107} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={upRightArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={upRightArrow} alt="home1"  ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
        <motion.div  id = 'step1' variants = {anim18} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fNeutral18} alt="home1" onClick={(e) => elementClick(17)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim104} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={upArrowHover} alt="home1" ></img> 
                    <img  className = 'motionArrow' src={upArrow} alt="home1"  ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
        <motion.div  id = 'step1' variants = {anim19} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fNeutral19} alt="home1" onClick={(e) => elementClick(18)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim109} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={upLeftArrowHover} alt="home1" ></img> 
                    <img  className = 'motionArrow' src={upLeftArrow} alt="home1" ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>


      <motion.div  id = 'step1' variants = {anim11} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fNeutral11} alt="home1" onClick={(e) => elementClick(10)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim101} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={rightArrowHover} alt="home1" ></img> 
                    <img  className = 'motionArrow' src={rightArrow} alt="home1" ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
      <motion.div  id = 'step1' variants = {anim10} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fNeutral10} alt="home1" onClick={(e) => elementClick(9)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim101} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={rightArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={rightArrow} alt="home1" ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
      <motion.div  id = 'step1' variants = {anim8} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fNeutral8} alt="home1" onClick={(e) => elementClick(7)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim101} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={rightArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={rightArrow} alt="home1"  ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
      <motion.div  id = 'step1' variants = {anim7} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fNeutral7} alt="home1" onClick={(e) => elementClick(6)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim101} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={rightArrowHover} alt="home1" ></img> 
                    <img  className = 'motionArrow' src={rightArrow} alt="home1"  ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
  

      <motion.div  id = 'step1' variants = {anim2} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fNeutral2} alt="home1" onClick={(e) => elementClick(1)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim101} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={rightArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={rightArrow} alt="home1"  ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
      <motion.div  id = 'step1' variants = {anim1} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fNeutral1} alt="home1" onClick={(e) => elementClick(0)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim101} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={rightArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={rightArrow} alt="home1"  ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
        <motion.div  id = 'step1' variants = {anim12} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fNeutral12} alt="home1" onClick={(e) => elementClick(11)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim102} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={downArrowHover} alt="home1" ></img> 
                    <img  className = 'motionArrow' src={downArrow} alt="home1"  ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
        <motion.div  id = 'step1' variants = {anim9} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fNeutral9} alt="home1" onClick={(e) => elementClick(8)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim104} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={upArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={upArrow} alt="home1"  ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
      <motion.div  id = 'step1' variants = {anim6} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fNeutral6} alt="home1" onClick={(e) => elementClick(5)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim104} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={upArrowHover} alt="home1" ></img> 
                    <img  className = 'motionArrow' src={upArrow} alt="home1"  ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
      <motion.div  id = 'step1' variants = {anim5} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fNeutral5} alt="home1" onClick={(e) => elementClick(4)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim104} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={upArrowHover} alt="home1"  ></img> 
                    <img  className = 'motionArrow' src={upArrow} alt="home1"  ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
        <motion.div  id = 'step1' variants = {anim4} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fNeutral4} alt="home1" onClick={(e) => elementClick(3)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim102} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={downArrowHover} alt="home1" ></img> 
                    <img  className = 'motionArrow' src={downArrow} alt="home1" ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>
      <motion.div  id = 'step1' variants = {anim3} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
            <div className = 'motionWrapper'>
                <div className = 'motionPicture'>
                    <img className = 'abra' src={fNeutral3} alt="home1" onClick={(e) => elementClick(2)} ></img> 
                </div>
                <div >
                <motion.div  id = 'step1' variants = {anim102} initial = 'hidden' animate = 'visible' whileHover = 'hover'>
                    <img  className = 'motionArrowTop' src={downArrowHover} alt="home1" ></img> 
                    <img  className = 'motionArrow' src={downArrow} alt="home1" ></img> 
                </motion.div>
                </div>
            </div>
        </motion.div>

        <img className = 'abra' src={folyamatabraUres} alt="home1"></img>
        </div>
        </>
        )}
    </div>
  );
  
}

export default NeutralFolyamat;
