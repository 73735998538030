import React, { useState, useEffect, useContext } from 'react'
import '../../App.css';
import buttonNeutral from '../../assets/chooseNeutral.png'


function ButtonNeutral(props) {

  return (
    <div >
      <img id = 'buttonBe' src = {buttonNeutral} alt = 'MaltoBuza' onClick={(e) => props.clickNeutral()} ></img>
    </div>
    );
  }

export default ButtonNeutral;
